import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GenericModal from '../../../components/modal/GenericModal';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import AsyncSelectInputGET from '../../../components/Select/AsyncSelectInputGET';
import { getColaboradorById, getColaboradoresCasoCovid, registrarColaboradorProgramaPruebaAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';

const BuscarColaborador = ({ showModal, setIdEmplead, showSecondModal }) => {
  const [empleado, setEmpleado] = useState({});
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState({});
  const [agregarDeTodasFormas, setAgregarDeTodasFormas] = useState(false);
  const sectionName = "modal-registrar-usuario";

  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [executePostRequest] = usePostRequest();

  const [message, setMessage] = useState("");

  useEffect(() => {
    init()
  }, []);

  const agregarColaborador = async () => {
    setIdEmplead(empleadoSeleccionado?.value);
    showModal();
    showSecondModal();
  };

  const init = async () => {
  };


  const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
    clearAllInputValidationMessages(["IdEmpleado"], sectionName);
    setEmpleadoSeleccionado(jsonValue);
  }

  return <>
    <GenericModal showModal={showModal} width={"850px"} titulo={"Añadir colaborador"}>
      <div>
        <div>
          <form className="blue-standard-color">
            <div className="form-group row form-input-modal-contactanos">
              <label className="col-sm-12 col-form-label modal-label">Seleccionar al colaborador<span className="required">*</span></label>
            </div>

            <div className="col-sm-12 black-color">
              <AsyncSelectInputGET api={getColaboradoresCasoCovid} placeholder="Buscar por nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal} />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleado-validation-message`}></p>
            </div>
          </form>

          {message !== "" ? (
            <div className="alert alert-warning" role="alert">
              {message}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div className="modal-footer mt-2">
          <div className="message font-gothan-light">
            <strong>
              <p>* Campos obligatorios</p>
            </strong>
          </div>
          <div className="button">
            <button className="btn btn-primary generic-button-ficha-modal" onClick={agregarColaborador}>
              {message !== "" ? "Sí, " : null}
              Añadir
            </button>
          </div>
        </div>
      </div>
    </GenericModal>
  </>;
};

export default BuscarColaborador;
