import React, {useEffect, useContext, useState} from 'react';
import styled from 'styled-components';

import imgbeneficio from '../../image/beneficios.png';
import ico_cake from '../../image/ico_cake.svg';
import ModalTerms from '../../components/modalTerms/ModalTerms';
import {downloadFile} from '../../consumers/backendConsumer';
import Cookies from '../../components/cookies/Cookies';
import {AuthContext} from '../../hooks/context/authContext';
import Progress from '../../components/atoms/Progress';

import EditIcon from '../../components/icons/EditIcon';
import NewItemIcon from '../../components/icons/NewItemIcon';
import {openGmail, openGmailWithSubject} from "../../components/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import EditarCardModal from "./EditarCardModal";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {aceptarCookiesAPI, aceptarTerminosAPI, getConsultarEncuestaSaludPendienteAPI, getDatosDeTableroParaEmpleadoAPI, getNuevosIngresosMisActividadesAPI, getShowPopupAPI, mostrarModalesEnTableroAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import InformativoVacunaModal from "../../components/modalVacunas/InformativoVacunaModal";
import RegistroVacunaModal from "../../components/modalVacunas/RegistroVacunaModal";
import RegistroSinVacunacion from "../../components/modalVacunas/RegistroSinVacunacion";
import VacunaGraph from "../../components/VacunaGadget/VacunaGraph";
import RegistroEncuestaSaludModal from '../../components/encuestaSaludModal/RegistroEncuestaSaludModal';
import {Spinner} from "react-bootstrap";
import GenericTooltip from "../../components/GenericTooltip/GenericTooltip";
import FichaSintomatologicaModal from '../gestionFichaSintomatologica/modales/FichaSintomatologicaModal';
import RecordatorioVacunacion from "./modales/RecordatorioVacunacion";
import ModalReferidos from './modales/ModalReferidos';
import ModalSiteDirectores from './modales/ModalSiteDirectores';
import ModalHorariosSedes from './modales/ModalHorariosSedes';
import ModalBoletin from './modales/ModalBoletin';
import ModalHostigamiento from './modales/ModalHostigamiento';
import ModalBeneficios from './modales/ModalBeneficios';
import ModalBecas from './modales/ModalBecas';
import ModalConcursoInnovacion from './modales/ModalConcursoInnovacion';
import { getSiMostrarModalReferidosAPI, getSiMostrarModalSiteDirectoresAPI, getSiMostrarModalHorariosSedesAPI, 
    getSiMostrarModalBoletinAPI, getSiMostrarModalHostigamientoAPI, getSiMostrarModalBeneficiosAPI, 
    getIdEncuentaSaludAPI, getSiMostrarModalBecasAPI, getSiMostrarModalConcursoInnovacionAPI } from '../../consumers/backendApisUrls';
import PageHeader from "../../components/pageHeader/PageHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ESTADOS_VACACIONES, DISEÑO_X_COLORES_ICON, VACACIONES_Y_LICENCIAS_ICON, CUPCAKE_ICON, ETIQUETA_NEW_CARDS } from '../../constants/hardCodedConstants';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import ModalPopup from './modales/ModalPopup';

const Wrapper = styled.div`
  .first {
    height: 218px;
  }

  .second {
    height: 190px;
  }

  @media (max-width: 992px) {
    .first {
      height: 200px;
    }

    .directorio-img {
      height: 130px;
    }

    .aaa {
      padding: 0 15px;
    }
  }
`;

export const ContainerIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 7px;
  cursor: pointer;
  @media (max-width: 992px) {
    padding: 5px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex: 0 0 20%;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: auto;

  @media (max-width: 420px){
    height: 60px;
  }

  @media (max-width: 320px){
    display: none;
  }
`;

const Principal = () => {
    const {state, dispatch} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [tablero, setTablero] = useState({});
    const [date, setDate] = useState('');
    const [idTarjetaTableroAEditar, setIdTarjetaTableroAEditar] = useState(0);
    const [hideEditCardModal, setHideEditCardModal] = useState(true);
    const [executeGetRequest] = useGetRequest();
    const [executeManageGet] = useManageGetRequest();
    const [executePostRequest] = usePostRequest();
    const [hideInformativoVacunaModal, setHideInformativoVacunaModal] = useState(true);
    const [hideRegistroVacunaModal, setHideRegistroVacunaModal] = useState(true);
    const [hideEncuestaSaludModal, setHideEncuestaSaludModal] = useState(true);
    const [hideRegistroSinVacunacion, setHideRegistroSinVacunacion] = useState(true);
    const [hideFichaSintomatologicaModal, setHideFichaSintomatologicaModal] = useState(true);
    const [hideRecordatorioVacunacion, setHideRecordatorioVacunacion] = useState(true);
    const [modalEncuestaSaludTitle, setModalEncuestaSaludTitle] = useState("");
    const [hideModalReferidos, setHideModalReferidos] = useState(true);
    const [hideModalSiteDirectores, setHideModalSiteDirectores] = useState(true);
    const [hideModalHorariosSedes, setHideModalHorariosSedes] = useState(true);
    const [hideModalModalBoletin, setHideModalBoletin] = useState(true);
    const [hideModalHostigamiento, setHideModalHostigamiento] = useState(true);
    const [hideModalBeneficios, setHideModalBeneficios] = useState(true);
    const [hideModalBecas, setHideModalBecas] = useState(true);
    const [hideModalConcursoInnovacion, setHideModalConcursoInnovacion] = useState(true);
    const [idEncuentaSaludDetalle, setIdEncuentaSaludDetalle] = useState(0);
    const [modalEnable, setModalEnable] = useState({isEnable: false, component: React.Fragment});
    const [popups, setPopups] = useState([]);

    useEffect(() => {
        state.isInterno && initPaginaPrincipal();

        dispatch({
            type: 'SHOW_BOT_TRUE',
        });
        let d = new Date();
        let n = d.getHours();

        if (n < 12) {
            setDate('Buenos días,');
        } else if (n >= 12 && n < 19) {
            setDate('Buenas tardes,');
        } else {
            setDate('Buenas noches,');
        }
    }, []);

    const initPaginaPrincipal = async () => {
        try {
            setLoading(true);
            const response = await executeGetRequest(getDatosDeTableroParaEmpleadoAPI());

            if (responseCode200(response)) {
                setTablero(response.data.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }

            await manageShowModalEncuestaSalud();
            await manageModalesVacunacion();
            await manageMostrarModalConcursoInnovacion(state.user.id_empleado);
            await handleInitPopup();
           
            /*
            await manageMostrarModalBecas(state.user.id_empleado);
            await manageMostrarModalReferidos(state.user.id_empleado);
            await manageMostrarModalSiteDirectores(state.user.id_empleado);
            await manageMostrarModalHorariosSedes(state.user.id_empleado);
            await manageMostrarModalBoletin(state.user.id_empleado);
            await manageMostrarModalHostigamiento(state.user.id_empleado);
            await manageMostrarModalBeneficios(state.user.id_empleado);
            */

            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const handleInitPopup = async () => {
        const idEmpleado = state.user.id_empleado;
        const api = getShowPopupAPI(idEmpleado);
        await executeManageGet(api, handlePopup);
    }

    const handlePopup = ({ data: popups }) => {
        if (!popups.length) return;
        setModalEnable({ isEnable: true, component: ModalPopup, data: { popups } })
    }


    const manageMostrarModalReferidos = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalReferidosAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalReferidos(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageMostrarModalSiteDirectores = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalSiteDirectoresAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalSiteDirectores(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageMostrarModalHorariosSedes = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalHorariosSedesAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalHorariosSedes(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageMostrarModalBoletin = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalBoletinAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalBoletin(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageMostrarModalHostigamiento = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalHostigamientoAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalHostigamiento(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageMostrarModalBeneficios = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalBeneficiosAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalBeneficios(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageModalesVacunacion = async function () {
        const response = await executeGetRequest(mostrarModalesEnTableroAPI());
        if (responseCode200(response)) {
            setHideInformativoVacunaModal(!response.data.MostrarModalCartillaVacunacion);
            setHideRegistroSinVacunacion(!response.data.MostrarModalRegistrarMotivoSinVacunacion);
            setHideRecordatorioVacunacion(!response.data.MostrarModalRecordatorioVacunacion);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const manageShowModalEncuestaSalud = async function () {
        const response = await executeGetRequest(getConsultarEncuestaSaludPendienteAPI());
        if (responseCode200(response)) {
            setHideEncuestaSaludModal(!response.data.EncuestaPendiente);
            setModalEncuestaSaludTitle(response?.data?.NombreEncuesta);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const manageMostrarModalBecas = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalBecasAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalBecas(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageMostrarModalConcursoInnovacion = async function (empleadoIddd) {
        const response = await executeGetRequest(getSiMostrarModalConcursoInnovacionAPI(empleadoIddd));

        if (responseCode200(response)) {
            setHideModalConcursoInnovacion(!response.data.Result);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const sendTerms = async () => {
        try {
            const response = await executePostRequest(aceptarTerminosAPI(state.user.id_usuario), {});
            if (response.data) {
                return await dispatch({
                    type: 'TERMS',
                    payload: {terms: false},
                });
            }
        } catch (e) {
            console.error('error', e);
        }
    };

    const sendCookies = async () => {
        try {
            const response = await executePostRequest(aceptarCookiesAPI(state.user.id_usuario), {});
            if (response.data) {
                return await dispatch({
                    type: 'COOKIES',
                    payload: {cookies: false},
                });
            }
        } catch (e) {
            console.error('error', e);
        }
    };


    const showEditCardModal = () => {
        let currentState = hideEditCardModal;
        setHideEditCardModal(!currentState);
    };

    const editCard = (idTarjetaTablero) => {
        console.debug("editCard, idTarjetaTablero", idTarjetaTablero);
        setIdTarjetaTableroAEditar(idTarjetaTablero);
        showEditCardModal();
    }
    const toggleInformativoVacunaModal = async () => {
        setHideInformativoVacunaModal(!hideInformativoVacunaModal);
    };

    const toggleRegistroVacunaModal = () => {
        setHideRegistroVacunaModal(!hideRegistroVacunaModal);
    };

    const toggleEncuestaSaludModal = () => {
        setHideEncuestaSaludModal(!hideEncuestaSaludModal);
    };

    const toggleRegistroSinVacunacionModal = () => {
        setHideRegistroSinVacunacion(!hideRegistroSinVacunacion);
    };

    const toggleFichaSintomatologicaModal = () => {
        setHideFichaSintomatologicaModal(!hideFichaSintomatologicaModal);
    };

    const toggleRecordatorioVacunacionModal = () => {
        setHideRecordatorioVacunacion(!hideRecordatorioVacunacion);
    };

    const dateHasExpired = (fechaFin) =>{
        const today = new Date();
        const ff = new Date(fechaFin);
    
        today.setHours(0,0,0,0);
        ff.setHours(0,0,0,0);
       
        return today.getTime() > ff.getTime();
    }

    const toggleModalReferidos= async () => {
        setHideModalReferidos(!hideModalReferidos);
    };

    const toggleModalSiteDirectores= async () => {
        setHideModalSiteDirectores(!hideModalSiteDirectores);
    };

    const toggleModalHorariosSedes= async () => {
        setHideModalHorariosSedes(!hideModalHorariosSedes);
    };

    const toggleModalBoletin = async () => {
        setHideModalBoletin(!hideModalModalBoletin);
    };

    const toggleModalHostigamiento = async () => {
        setHideModalHostigamiento(!hideModalHostigamiento);
    };

    const toggleModalBeneficios = async () => {
        setHideModalBeneficios(!hideModalBeneficios);
    };

    const toggleModalBecas = async () => {
        setHideModalBecas(!hideModalBecas);
    };

    const toggleModalConcursoInnovacion = async () => {
        setHideModalConcursoInnovacion(!hideModalConcursoInnovacion);
    };
    const openBeneficiosSubCategoriasPage = () => {
        window.open('/politicasYreglamentos/beneficios', '_self');
    }

    const obtenerIdEncuenta  = async () => {
        let response = await executeGetRequest(getIdEncuentaSaludAPI());

        if (responseCode200(response)) {
            setIdEncuentaSaludDetalle(response.data.IdEncuestaSaludDetalle);
        } else {
            setIdEncuentaSaludDetalle(0);
        }
    }

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );
    return (
        <>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalReferidos}>
                        {hideModalReferidos === false &&
                        <ModalReferidos showModal={toggleModalReferidos}/>
                        }
            </div>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalSiteDirectores}>
                        {hideModalSiteDirectores === false &&
                        <ModalSiteDirectores showModal={toggleModalSiteDirectores}/>
                        }
            </div>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalHorariosSedes}>
                        {hideModalHorariosSedes === false &&
                        <ModalHorariosSedes showModal={toggleModalHorariosSedes}/>
                        }
            </div>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalModalBoletin}>
                        {hideModalModalBoletin === false &&
                        <ModalBoletin showModal={toggleModalBoletin}/>
                        }
            </div>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalHostigamiento}>
                        {hideModalHostigamiento === false &&
                        <ModalHostigamiento showModal={toggleModalHostigamiento}/>
                        }
            </div>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalBeneficios}>
                        {hideModalBeneficios=== false &&
                        <ModalBeneficios showModal={toggleModalBeneficios}/>
                        }
            </div>
            <div id="modal-contactanos" className="modal-contactanos modal-response-ficha-background "
                 hidden={hideEditCardModal}>
                {hideEditCardModal === false &&
                <EditarCardModal showEditCardModal={showEditCardModal} idTarjeta={idTarjetaTableroAEditar}
                                 initPaginaPrincipal={initPaginaPrincipal}/>
                }
            </div>
            {hideInformativoVacunaModal === false &&
            <div className="modal-contactanos modal-response-ficha-background">
                <InformativoVacunaModal toggleInformativoVacunaModal={toggleInformativoVacunaModal} toggleRegistroVacunaModal={toggleRegistroVacunaModal}/>
            </div>
            }

            {hideRegistroSinVacunacion === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistroSinVacunacion showModal={toggleRegistroSinVacunacionModal} setHideRegistroVacunaModal={setHideRegistroVacunaModal}/>
                </div>
            }

            {hideEncuestaSaludModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistroEncuestaSaludModal showModal={toggleEncuestaSaludModal} title = {modalEncuestaSaludTitle} obtenerIdEncuenta ={obtenerIdEncuenta}/>
                </div>
            }

            {hideFichaSintomatologicaModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <FichaSintomatologicaModal showModal={toggleFichaSintomatologicaModal} reload={initPaginaPrincipal}/>
                </div>
            }

            {hideRegistroVacunaModal === false &&
            <div className="modal-contactanos modal-response-ficha-background">
                <RegistroVacunaModal showModal={toggleRegistroVacunaModal} reload={initPaginaPrincipal}/>
            </div>
            }

            {hideRecordatorioVacunacion === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RecordatorioVacunacion toggleModal={toggleRecordatorioVacunacionModal} setHideRegistroVacunaModal={setHideRegistroVacunaModal}/>
                </div>
            }

            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalBecas}>
                        {hideModalBecas=== false &&
                        <ModalBecas showModal={toggleModalBecas}/>
                        }
            </div>
            
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideModalConcursoInnovacion}>
                        {hideModalConcursoInnovacion=== false &&
                        <ModalConcursoInnovacion showModal={toggleModalConcursoInnovacion}/>
                        }
            </div>

            <ModalEnabled modalEnabled={modalEnable} setModalEnabled={setModalEnable}/>

            {state.isInterno && 
            <Wrapper>
                <ModalTerms active={state.terms} sendTerms={sendTerms}/>
                <div className="page-header-style justify-content-between">
                    <span className='menuSaludoTexto orange_innova'>Hola, {state.user.name} &#128516;</span>
                    <div className='header-height-size logo-x-header'>
                        <img className='h-100' src={DISEÑO_X_COLORES_ICON}/>
                    </div>
                </div>
                <div className='container-fluid body-content'>
                    <div className='row mt1'>
                        <div className='col-xl-8 col-lg-12'>
                            <div className='row'>
                                <div className='col-md-6 pt-3'>
                                    <div className='card first p-0'>
                                        <div className='card-body p-0'>
                                            <div className='row no-gutters h-100'>
                                                <div className='col-6 card-left-text'>
                                                    <div>
                                                        <h6 className='card-title'>Completa tus datos</h6>
                                                        {tablero?.CumplimientoFichaPorcentaje === 100 &&
                                                        <p className='card-primary-text-inside'>
                                                            ¡Felicitaciones, completaste tu ficha de datos!
                                                        </p>
                                                        }
                                                        {tablero?.CumplimientoFichaPorcentaje !== 100 &&
                                                        <p className='card-primary-text-inside'>
                                                            Tu ficha de datos necesita ser completada.
                                                        <GenericTooltip text={'Te falta: ' + tablero?.CumplimientoFichaPorcentajeText} id={'card-porcentaje-avance-ficha'}></GenericTooltip>
                                                        </p>
                                                        }
                                                    </div>
                                                    <a href={"/fichadedatos"} className='card-link'>
                                                        Ir a mi ficha de datos
                                                    </a>
                                                </div>
                                                <div className='col-6 h-100 avance-ficha-background'>
                                                    <Progress
                                                        trailStrokeColor='#f0f2f5'
                                                        strokeColor='#8DBA38'
                                                        textColor='#8DBA38'
                                                        percentage={tablero?.CumplimientoFichaPorcentaje}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 pt-3'>
                                    <div className='card first p-0'>
                                        <div className='card-body p-0'>
                                            <div className='row no-gutters h-100'>
                                                <div className='col-6 card-left-text'>
                                                    <div>
                                                        <h6 className='card-title'>Beneficios</h6>
                                                        <p className='card-primary-text-inside'>
                                                        ¡No te pierdas todos los beneficios que tenemos para ti y tu familia!
                                                        </p>
                                                    </div>
                                                    {/*{tablero?.CardDerecha?.MostrarLink ? (*/}
                                                    <a onClick={openBeneficiosSubCategoriasPage} className='card-link hand-pointer'>
                                                        Encuéntralos aquí.
                                                    </a>
                                                    {/* ): null}*/}
                                                </div>
                                                <div className='col-6 mejor-experiencia-background'>
                                                    {/*
                                                    <VacunaGraph
                                                        trailStrokeColor='#fff'
                                                        strokeColor={tablero.CardDerecha.Color}
                                                        percentage={100}
                                                        stateName={tablero.CardDerecha.Status}
                                                    />*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row grid'>
                                <div className='col-md-4 col-sm-6 pt-3'>
                                    <div className='card second secondary-card-body'>
                                        <div className='secondary-card-text'>
                                            <h6 className='card-title text-center'>
                                                {tablero?.DiasDeVacaciones?.Titulo}
                                            </h6>
                                            <p className='card-text text-center'>{tablero?.DiasDeVacaciones?.Text}</p>
                                        </div>
                                        <a className='card-link hand-pointer text-center' onClick={(e) => {
                                            e.preventDefault();
                                            downloadFile(state.token, tablero?.DiasDeVacaciones?.FilePath, tablero?.DiasDeVacaciones?.OriginalFileName);
                                        }}>
                                            {tablero?.DiasDeVacaciones?.LinkText}
                                        </a>
                                        <div className='secondary-card-image'>
                                            <img
                                                src={VACACIONES_Y_LICENCIAS_ICON}
                                                className="secondary-card-image"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {tablero?.Tarjetas?.map((tarjeta, i) => (
                                    <div className='col-md-4 col-sm-6 pt-3' key={i}>
                                        <div className='card second secondary-card-body'>
                                            <div className='secondary-card-text'>
                                                <h6 className='card-title text-center'>
                                                    {tarjeta.Titulo}
                                                </h6>
                                                <h3 className='card-text text-center'>
                                                    {tarjeta.Descripcion}
                                                </h3>
                                            </div>
                                            <a className='card-link hand-pointer text-center' href={tarjeta.UrlEnlace}
                                               target="_blank">
                                                {tarjeta.TextoEnlace}
                                            </a>
                                            <div className='secondary-card-image'>
                                                <img src={tarjeta.UrlImagen}/>
                                            </div>
                                            {tarjeta.EsNuevo && !dateHasExpired(tarjeta.FechaFin) && (
                                                <ContainerIcon className='secondary-card-image-nuevo'>
                                                    <img src={ETIQUETA_NEW_CARDS} alt="Imagen Nuevo" className='h-100' />
                                                </ContainerIcon>
                                            )}
                                            {tablero?.PuedeEditarCards && (
                                                <ContainerIcon>
                                                    <EditIcon onClick={() => editCard(tarjeta.IdTarjetaTablero)}/>
                                                </ContainerIcon>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-12 p-0'>
                            {tablero?.SeccionVacaciones?.MostrarCard && 
                             <div className='row mx-0'>
                                <div className="col-md-12 pt-3 custom-scroll">
                                    <div className='card-header pt-1 pb-1 cardHeaderPrincipal card-secundario-header d-flex justify-content-between blue-background-color'>
                                        <div className='card-header-text-vacaciones'>
                                            Mis vacaciones: {tablero.SeccionVacaciones.CantidadVacaciones > 0 
                                            ? tablero.SeccionVacaciones.CantidadVacaciones > 1 ? `${tablero.SeccionVacaciones.CantidadVacaciones} días disponibles`: `${tablero.SeccionVacaciones.CantidadVacaciones} día disponible` 
                                            //: "No cuentas con días disponibles"}
                                            : `${tablero.SeccionVacaciones.CantidadVacaciones} días`}
                                        </div>
                                        {tablero.SeccionVacaciones?.PuedeSolicitar && 
                                            <p className="light-orange-color hand-pointer mb-0" onClick={()=> window.open("/misVacaciones", "_blank")}>Solicita aquí <FontAwesomeIcon  icon='plus' className="hand-pointer"  size="sm"  /></p>}
                                    </div>
                                    <div className='card-body-height overflow-y-scroll'>
                                        <div className='card border-left-primary py-2'>
                                            {tablero.SeccionVacaciones?.PuedeSolicitar 
                                            ? (tablero.SeccionVacaciones && tablero.SeccionVacaciones?.SolicitudesVacaciones.length === 0) 
                                                ? <p className="text-no-hay-novedades">No hay solicitudes pendientes.</p>
                                                : tablero.SeccionVacaciones?.SolicitudesVacaciones.map((item, i) => (
                                                    <div className='row pt-1 mb-2' key={i}>
                                                        <div className='col-10'>
                                                            <div className='text-nov-titulo card-secundario-header blue_innova_text'>Tu solicitud de vacaciones {item.Fechas}: <strong>{item.Estado}</strong></div>
                                                        </div>
                                                    </div>
                                                    ))
                                            : <p className="text-nov-titulo card-secundario-header blue_innova_text">Recuerda que cuentas con menos de {tablero.SeccionVacaciones?.TiempoMensaje} en Colegios Peruanos S.A., debido a ello la opción de solicitud de vacaciones se encuentra inhabilitada. Si tienes dudas, comunícate a compensaciones@innovaschools.edu.pe</p>                                            
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className='row mx-0'>
                                <div className="col-md-12 pt-3 custom-scroll">
                                    <div className='card-header pt-1 pb-1 cardHeaderPrincipal card-secundario-header light-orange-background-color'>Novedades</div>
                                    <div className='card-body-height overflow-y-scroll novedades'>
                                        <div className='card border-left-primary pt-1 novedades'>
                                            {tablero?.Novedades?.map((nov, i) => (
                                                <div className='row pt-1' key={i}>
                                                    <Flex>
                                                        <img src={nov.Tipo === 'Cumpleaños' && CUPCAKE_ICON} className="h-100" alt="imagen cumpleaños"/>
                                                    </Flex>
                                                    <div className='text-cumpleaños-novedades'>
                                                        <div className='text-nov-titulo blue_innova_text'>{nov.Mensaje}</div>
                                                        {nov.ExtraInformation && (
                                                            <a className='text-enviar-mensaje-cumpleaños' onClick={() => {
                                                                if (nov.Tipo === 'Cumpleaños') {
                                                                    openGmailWithSubject(nov.ExtraInformation, "Feliz cumpleaños");
                                                                } else {
                                                                    openGmail(nov.ExtraInformation);
                                                                }
                                                            }}>
                                                                Envíale un mensaje <strong>aquí</strong>
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {tablero?.Novedades && tablero?.Novedades?.length === 0 &&
                                            <p className="text-no-hay-novedades">Hoy no hay novedades.</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className="col-md-12 pt-3 custom-scroll">
                                    <div className='card-header pt-1 pb-1  cardHeaderPrincipal d-flex justify-content-between card-secundario-header light-orange-background-color'>
                                        <div>
                                            <p className="pb-1 mb-0"> Mis Actividades Pendientes</p>
                                        </div>
                                       <div>
                                           <p className="blue-standard-color hand-pointer mb-0" onClick={()=> window.open("/misActividadesPendientes", "_blank")} >Ver más  <FontAwesomeIcon  icon='plus' className="hand-pointer"  size="sm"  /></p>
                                       </div>
                                    </div>
                                    <div className='card-body-height overflow-y-scroll novedades'>
                                        <div className='card border-left-primary pt-1 pb-1 novedades'>
                                            <table className="table card-novedades-table blue_innova_text">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Desde</th>
                                                    <th scope="col">Actividad</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tablero?.ActividadesPendientes?.map((item, i) => (
                                                    <tr>
                                                        <td> <p className="text-nowrap">{item?.Fecha}</p></td>
                                                        <td>{item?.Paso}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            {tablero?.ActividadesPendientes && tablero?.ActividadesPendientes?.length === 0 &&
                                            <p className="text-no-hay-novedades">No hay Actividades.</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {state.cookies && <Cookies cookies={sendCookies}/>}
                    </div>
                </div>
            </Wrapper>}
        </>
    );
};

export default Principal;
