import React, { useEffect, useState } from 'react'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputDate from '../../../components/modalComponents/InputDate';
import InputText from '../../../components/modalComponents/InputText';
import InputNumber from '../../../components/modalComponents/InputNumber';
import InputSelect from '../../../components/modalComponents/InputSelect';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { initialAllOptionPromises } from '../../../helper/utils';
import { getConvocatoriaTrasladosToEditAPI, getTiposTrasladoAPI, saveEditarConvocatoriaTrasladoAPI } from '../../../consumers/backendApisUrls';

const EditarTraslado = ({ toggleModal, refreshPage, id }) => {

  const SECTION_NAME = "EditarNuevoTraslado";
  const ID_TYPES = {TipoTraslado: "IdTipoTraslado"};
  const KEYS = ["IdTipoTraslado", "FechaInicio", "FechaFin", "FechaCierre", "Nombre", "Descripcion", "Requisitos", "AntiguedadSede", "NotaMinimaEval"];

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [inputProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [executeGet] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();


  const initialPromises = () => {
    return [
      getOptions(getTiposTrasladoAPI()),
    ]
  }

  const init = () => {
    try {
      initialAllOptionPromises(initialPromises, Object.values(ID_TYPES), setAllDropdowns);
      manageSearch()
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getConvocatoriaTrasladosToEditAPI(id);
    await executeGet(api, successSearchCallback);
  }

  const successSearchCallback = (res) => {
    setFormValues(res.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = saveEditarConvocatoriaTrasladoAPI();
    await submitForm(KEYS, api, formValues, SECTION_NAME, successSendCallback)
  }

  const successSendCallback = () => {
    refreshPage();
    toggleModal();
  }

  useEffect(() => {
    init();
  }, [])

  if(isLoading) return <SpinnerLoading />

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Editar convocatoria traslados" label="Editar" width="900px">
      <div className='mt-2'>
        <label className='mb-0'>Datos de Traslado</label>
        <hr className='mt-0'/>
      </div>
      <div className="displayFlexWrap mt-4">
        <div className='col-md-6'><InputSelect label="Tipo" {...selectProps("IdTipoTraslado")} direction="column"/></div>
        <div className='col-md-3'><InputDate label="Inicio Publicación" {...inputProps("FechaInicio")} direction="column"/></div>
        <div className='col-md-3'><InputDate label="Fin Publicación" {...inputProps("FechaFin")} direction="column"/></div>
        <div className='col-md-6'><InputText label="Nombre de convocatoria" {...inputProps("Nombre")} direction="column"/></div>
        <div className='col-md-3'><InputDate label="Cierre Convocatoria" {...inputProps("FechaCierre")} direction="column"/></div>
      </div>
      <div className="displayFlexWrap">
        <div className='col-md-12'><InputTextArea label="Descripción y etapas del proceso" {...inputProps("Descripcion")} direction="column" maxLength="500"/></div>
        <div className='col-md-12'><InputTextArea label="¿Cómo se evaluarán las solicitudes de traslado de sede?" {...inputProps("ComoSeEvaluaSolicitud")} direction="column" maxLength="1000" placeholder="Ingresar opciones de evaluación de solicitudes de traslado"/></div>
        <div className='col-md-12'><InputTextArea label="Requisitos" {...inputProps("Requisitos")} direction="column" maxLength="500"/></div>
      </div>
      <div className='mt-2'>
        <label className='mb-0'>Parámetros</label>
        <hr className='mt-0'/>
      </div>
      <div className="displayFlexWrap mt-4">
        <div className='col-md-6'><InputNumber label="Antiguedad En Sede" {...inputProps("AntiguedadSede")} direction="column"/></div>
        <div className='col-md-6'><InputNumber label="Nota Mínima Evaluación" {...inputProps("NotaMinimaEval")} direction="column"/></div>
      </div>
    </ModalForm>
  )
}

export default EditarTraslado