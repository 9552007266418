import React, { useEffect, useState } from 'react';
import { initialAllOptionPromises } from '../../../helper/utils';
import CompletarCerrarModal from './CompletarCerrarModal';
import MotivoModal from './MotivoModal';
import InputDate from '../../../components/modalComponents/InputDate';
import InputText from '../../../components/modalComponents/InputText';
import ModalForm from '../../../components/modalComponents/ModalForm';
import InputSelect from '../../../components/modalComponents/InputSelect';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import InputSearchAsync from '../../../components/modalComponents/InputSearchAsync';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade';
import InputSelectCascadePost from '../../../components/modalComponents/InputSelectCascadePost';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { CARGOS, FILE_FORMATS, TIPOS_CONTRATO } from '../../../constants/hardCodedConstants';
import { getEditRequerimientoPersonalAPI, buscarColaboradorAPI, getAllCargosAPI, getAllSedesAPI, getAllTiposContratoAPI, getAreasByGerenciaAPI, getDivisionesAPI, getGerenciasByDivisionAPI, getPresupuestosAPI, getResponsablesNuevoIngresoGestionAPI, getTiposVacanteAPI, anularRequerimientoPersonalAPI, standByRequerimientoPersonalAPI, aprobarRequerimientoPersonalAPI, rechazarRequerimientoPersonalAPI, crearPerfilPuestoByRequerimientoAPI, getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI, getRelatedDataTypesAPI, getAllJornadasLaboralesAPI, getAllTiposTrabajadorAPI, abrirRequerimientoPersonalAPI, getEspecialidadSeleccionAPI, getAvanceRequerimientoAPI } from '../../../consumers/backendApisUrls';
import VerDetalle from '../../registroMaternidad/modales/verDetalle/VerDetalle';

const VerRequerimiento = ({ toggleModal, refreshPage, id, idCondicion, puedeVerCondicionRelacionada = false }) => {

  const SECTION_NAME = "crearRequerimientoPersonal";
  const VALID_EXTENSIONS = ["pdf"];
  const KEYS_INIT = { presupuesto: "IdPresupuesto", sedePrincipal: "IdSedePrincipal", sedeSecundaria: "IdSedeSecundaria", unidad: "IdUnidad", cargo: "IdCargo", tipoVacante: "IdTipoVacante", tipoContrato: "IdTipoContrato", Responsable: "IdResponsable", TipoEmpleado: "IdTipoEmpleado", TipoTrabajador: "IdTipoTrabajador", EspecialidadSeleccion: "IdEspecialidadSeleccion", AvanceRequerimiento: "IdAvanceRequerimiento", ArchivoResultadoEvaluacion: "ArchivoResultadoEvaluacion" };
  const KEYS = ['IdPresupuesto', 'IdSedePrincipal', 'IdSedeSecundaria', 'IdUnidad', 'IdGerencia', 'IdArea', 'IdCargo', 'IdTipoVacante', 'IdEmpleadoReemplazo', 'File', 'IdSolicitante', 'Detalle', "IdTipoTrabajador", "IdTipoEmpleado", "IdTipoContrato", "IdRegimenLaboral", 'DiasxSemana', 'FechaInicio', 'FechaFin', 'Sueldo', 'IdResponsable', "IdEspecialidadSeleccion", "IdAvanceRequerimiento", "ArchivoResultadoEvaluacion"];

  const [formValues, setFormValues] = useState({});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();
  const [executePost] = useManagePostRequest();
  const [submitForm] = useSubmitForm();

  const initialPromises = () => {
    return [
      getOptions(getPresupuestosAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getDivisionesAPI()),
      getOptions(getAllCargosAPI()),
      getOptions(getTiposVacanteAPI()),
      getOptions(getAllTiposContratoAPI()),
      getOptions(getResponsablesNuevoIngresoGestionAPI()),
      getOptions(getAllJornadasLaboralesAPI()),
      getOptions(getAllTiposTrabajadorAPI()),
      getOptions(getEspecialidadSeleccionAPI()),
      getOptions(getAvanceRequerimientoAPI()),
    ]
  }

  const init = () => {
    initialAllOptionPromises(initialPromises, Object.values(KEYS_INIT), setAllDropdowns);
    manageSearch();
  }

  const manageSearch = async () => {
    const api = getEditRequerimientoPersonalAPI(id);
    await executeGet(api, (res) => setFormValues(res.data));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  }

  const handleUploadFile = async (res) => {
    const payload = {IdRequerimientoPersonal: id, File: res};
    await executePost(crearPerfilPuestoByRequerimientoAPI(), payload, () => {});
  }

  const handleApprove = async (e) => {
    setIsSending(true);
    e.preventDefault();
    const api = aprobarRequerimientoPersonalAPI(id);
    await submitForm(KEYS, api, {}, SECTION_NAME, () => {toggleModal(); refreshPage();});
    setIsSending(false);
  }

  const getDependetsTipoContrato = () => {
    return {
      IdTipoTrabajador: formValues[KEYS_INIT.TipoTrabajador],
      IdJornadaLaboral: formValues[KEYS_INIT.TipoEmpleado],
    }
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Ver Requerimiento" hasSaveButton={false}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} toggleParent={toggleModal} refreshPage={refreshPage}/>
      <section>
        <div className="displayFlexWrap">
          <DivCol><InputSelect label='Presupuesto' {...commonPropsSelect('IdPresupuesto')} isDisabled/></DivCol>
          <DivCol><InputSelect label='Sede Principal' {...commonPropsSelect('IdSedePrincipal')} isDisabled/></DivCol>
          <DivCol><InputSelect label='Sede Secundaria' {...commonPropsSelect('IdSedeSecundaria')} isDisabled/></DivCol>
          <DivCol><InputSelect label='Unidad' {...commonPropsSelect('IdUnidad', ['IdGerencia', 'IdArea'])} isDisabled/></DivCol>
          <DivCol><InputSelectCascade label='Gerencia' dependendOption={formValues.IdUnidad} api={getGerenciasByDivisionAPI} {...commomProps('IdGerencia', ['IdArea'])} isDisabled/></DivCol>
          <DivCol><InputSelectCascade label='Área' dependendOption={formValues.IdGerencia} api={getAreasByGerenciaAPI} {...commomProps('IdArea')} isDisabled/></DivCol>
          <DivCol><InputSelect label='Cargo' {...commonPropsSelect('IdCargo')} isDisabled/></DivCol>
          <DivCol><InputSelect label='Tipo de Vacante' {...commonPropsSelect('IdTipoVacante', ["IdEspecialidadSeleccion"])} isDisabled/></DivCol>
          <DivCol><InputSearchAsync typeGet label='Reemplazo' api={buscarColaboradorAPI} valueText={formValues.EmpleadoReemplazo} {...commomProps('IdEmpleadoReemplazo')} isDisabled/></DivCol>
          <DivCol><InputSelect label='Avance' {...commonPropsSelect('IdAvanceRequerimiento')} isDisabled/></DivCol>

          {(formValues.IdCargo === CARGOS.PROFESOR || formValues.IdCargo === CARGOS.ASISTENTE_EDUCACION) &&
            <DivCol required><InputSelect label="Especialidad Selección Ingreso" {...commonPropsSelect("IdEspecialidadSeleccion")} isDisabled/></DivCol>
          }
        </div>
      </section>
      <section className='mt-2'>
        <label className='mb-3 col-sm-12'><strong>Detalle del Requerimiento:</strong></label>
        <div className='col-sm-12'>
          <InputUploadFile label='Archivo' uploadFile={formValues.File} setUploadFile={handleUploadFile} id="uploadFilePerfilPuestoByRequerimiento" validExtensions={VALID_EXTENSIONS} name='File' sectionName={SECTION_NAME} colLabel={2} colInput={10}/>
        </div>
        <DivCol>
          <InputSearchAsync typeGet label='Solicitante' api={buscarColaboradorAPI} valueText={formValues.Solicitante} {...commomProps('IdSolicitante')} isDisabled/>
        </DivCol>
        <DivCol>
          <InputUploadFile 
            label="Archivo Resultado Evaluación" 
            name={KEYS_INIT.ArchivoResultadoEvaluacion} 
            uploadFile={formValues[KEYS_INIT.ArchivoResultadoEvaluacion]} 
            setUploadFile={(res) => 
              setFormValues({...formValues, [KEYS_INIT.ArchivoResultadoEvaluacion]: res})} 
            sectionName={SECTION_NAME} 
            setIsUploading={setIsSending} 
            validExtensions={FILE_FORMATS.PDF_FORMAT}
            isDisabled/>
        </DivCol>
        <div className="displayFlexWrap align-items-end">
          <div className={puedeVerCondicionRelacionada ? 'col-sm-8' : 'col-sm-12'}>
            <InputTextArea label='Detalle' {...commomProps('Detalle')} direction='column' rows={3} isDisabled/>
          </div>
          {puedeVerCondicionRelacionada &&
            <div className="col-sm-4 align-self-end text-right">
              <button className='generic-button-buscador mt-auto ml-auto' onClick={() => {
                setModalEnabled({isEnable: true, component: VerDetalle, data: {idCondicion: idCondicion, puedeVerAcciones: false }})
              }}>
                Ver condición
              </button>
            </div>
          }
        </div>
      </section>
      <section className='mt-2'>
        <label className='mb-3 col-sm-12'><strong>Datos de la asignación:</strong></label>
        <div className='displayFlexWrap'>
          <DivCol><InputSelect label="Tipo Trabajador" {...commonPropsSelect("IdTipoTrabajador", ["IdTipoContrato", "IdRegimenLaboral"])} isDisabled/></DivCol>
          <DivCol><InputSelect label="Jornada Laboral" {...commonPropsSelect("IdTipoEmpleado", ["IdTipoContrato", "IdRegimenLaboral"])} isDisabled/></DivCol>
          <DivCol><InputSelectCascadePost label="Tipo Contrato" dependendOption={getDependetsTipoContrato()} api={getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI} {...commonPropsSelect("IdTipoContrato", ["IdRegimenLaboral", "DiasxSemana", "FechaFin"])} isDisabled/></DivCol>
          <DivCol><InputSelectCascade label="Régimen Laboral" dependendOption={formValues["IdTipoContrato"]} api={getRelatedDataTypesAPI} {...commomProps("IdRegimenLaboral")} isDisabled/></DivCol>

          {TIPOS_CONTRATO.PART_TIME.includes(formValues.IdTipoContrato) &&
            <DivCol><InputText label='Días por semana' {...commomProps('DiasxSemana')} isDisabled/></DivCol>
          }

          <DivCol><InputSelect label='Responsable' {...commonPropsSelect('IdResponsable')} isDisabled/></DivCol>
          {formValues[KEYS_INIT.tipoContrato] === TIPOS_CONTRATO.SUPLENCIA &&
            <>
              <DivCol><InputDate label='Fecha Inicio' {...commomProps('FechaInicio')} isDisabled/></DivCol>
              <DivCol><InputDate label='Fecha de Fin' {...commomProps('FechaFin')} isDisabled/></DivCol>
            </>
          }
        </div>
      </section>
      {formValues.MotivoRechazo &&
        <section className='mt-2'>
          <label className='mb-3 col-sm-12'><strong>Motivo Estado Actual:</strong></label>
          <div className='col-sm-12'><InputTextArea label='Motivo' {...commomProps('MotivoRechazo')} direction='column' isDisabled/></div>
        </section>
      }
      <div className="modal-footer justify-content-end">
        <div className="d-flex justify-content-center align-items-center" style={{gap: "10px"}}>
          {formValues.PuedeAprobar && 
            <button type='button' className='btn generic-button-buscador generic-button-hover-verde' disabled={isSending}
              onClick={handleApprove}
            >
              Aprobar
            </button>
          }
          {formValues.PuedeRechazar && 
            <button type='button' className='btn generic-button-buscador generic-button-hover-rojo' disabled={isSending}
              onClick={() => setModalEnabled({isEnable: true, component: MotivoModal, data: { id, title: 'Rechazar Requerimiento', api: rechazarRequerimientoPersonalAPI }})}
            >
              Rechazar
            </button>
          }
          {formValues.PuedeAnular && 
            <button type='button' className='btn generic-button-buscador' 
              onClick={() => setModalEnabled({isEnable: true, component: MotivoModal, data: { id, title: 'Anular Requerimiento', api: anularRequerimientoPersonalAPI }})}
            >
              Anular
            </button>
          }
          {formValues.PuedePausar && 
            <button type='button' className='btn generic-button-buscador'
              onClick={() => setModalEnabled({isEnable: true, component: MotivoModal, data: { id, title: 'Stand By Requerimiento', api: standByRequerimientoPersonalAPI }})}
            >
              Stand By
            </button>
          }
          {formValues.PuedeAbrir && 
            <button type='button' className='btn generic-button-buscador'
              onClick={() => setModalEnabled({isEnable: true, component: ActionGenericModal , data: { title: 'Abrir Requerimiento', actionLabel: "abrir", api: abrirRequerimientoPersonalAPI, id, name: formValues.Solicitante}})}
            >
              Abrir
            </button>
          }
          {formValues.PuedeCompletar &&
            <button type='button' className='btn generic-button-buscador'
              onClick={() => setModalEnabled({isEnable: true, component: CompletarCerrarModal, data: { id }})}
            >
              Completar
            </button>
          }
        </div>
      </div>
    </ModalForm>
  )
}

export default VerRequerimiento;

const DivCol = ({ children }) => (
  <div className='col-sm-6'>
    {children}
  </div>
)

const ModalEnabled = ({
  modalEnabled = { isEnable: false },
  setModalEnabled,
  toggleParent,
  refreshPage,
}) => {
  return (
    modalEnabled.isEnable && (
      <div className="modal-contactanos modal-response-ficha-background">
        <modalEnabled.component
          toggleModal={() => setModalEnabled({ isEnable: false })}
          toggleParent={toggleParent}
          refreshPage={refreshPage}
          {...modalEnabled.data}
        />
      </div>
    )
  );
};