import React from 'react'

const InputDeclaracionJurada = ({ name, value, onChange, sectionName, declaracionJurada }) => (
  <div className='col-md-12'>
    <div className="form-check mb-0">
      <input
        type="checkbox"
        className="form-check-input"
        name={name}
        id={`admin-input-bool-select-${sectionName}-${name}`}
        checked={value ?? false}
        onChange={onChange}
      ></input>
      <label
        className="form-check-label ficha-declaracion-jurada-message"
        htmlFor={`admin-input-bool-select-${sectionName}-${name}`}
      >
        <strong className="mb-0 text-justify">
          {declaracionJurada}
        </strong>
      </label>
      <p
        className="ficha-form-input-validation-message"
        id={`ficha-edit-${sectionName}-${name}-validation-message`}
      ></p>
    </div>
  </div>
)
export default InputDeclaracionJurada