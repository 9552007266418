import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericModal from '../../../../../components/modal/GenericModal'
import InputTextArea from '../../../../../components/modalComponents/InputTextArea';
import InputBool from '../../../../../components/modalComponents/InputBool';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { observarCondicionAPI } from '../../../../../consumers/backendApisUrls';
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile';

const SECTION_NAME = "ObservarMaternidadMaternidad";
const KEYS = {
  mensaje: "Observacion",
  tieneDocPreExistencia: "TieneDocPreExistencia",
  plantillaPreExistencia: "PlantillaPreExistenciaFileViewModel",
  tieneDocDescansoMedicoMayor20Dias: "TieneDocDescansoMedicoMayor20Dias",
  plantillaCartaCompromisoDM: "PlantillaCartaCompromisoDM",
};

const ObservarMaternidad = ({ toggleModal, refreshPage, toggleMainModal, IdCondicion, preExistencia, row ={} }) => {
  const [formValues, setFormValues] = useState({
    [KEYS.tieneDocPreExistencia]: false,
  });
  const [isSending, setIsSending] = useState(false);
  const [submitForm] = useSubmitForm();

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleAction = async () => {
    setIsSending(true);
    const api = observarCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successHandleCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const getPayload = () => {
    return {
      IdCondicion,
      ...formValues
    }
  }

  const successHandleCallback = async () => {
    toggleModal();
    toggleMainModal();
    await refreshPage();
  }

  const preExistenciaGestacion = (
    <>
      {preExistencia && (
        <div className="container-fluid">
          <InputBool
            label="Doc. Pre-Existencia"
            {...commonProps(KEYS.tieneDocPreExistencia, [KEYS.plantillaPreExistencia])}
          />
        </div>
      )}
      {formValues[KEYS.tieneDocPreExistencia] && (
        <div className="container-fluid">
          <InputUploadFile
            label="Plantilla de Pre-Existencia"
            name={KEYS.plantillaPreExistencia}
            uploadFile={formValues[KEYS.plantillaPreExistencia]}
            setUploadFile={(res) => setFormValues({ ...formValues, [KEYS.plantillaPreExistencia]: res })}
            sectionName={SECTION_NAME}
          />
        </div>
      )}
    </>
  );

  return (
    <GenericModal titulo="Observar" showModal={toggleModal} width={"600px"}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          <InputTextArea label="Mensaje" {...commonProps(KEYS.mensaje)} />
        </div>
        {preExistenciaGestacion}
      </div>
      <div className="modal-footer justify-content-end">
        <button
          className="btn btn-outline-primary generic-button-ficha-outlined mr-2"
          disabled={isSending}
          onClick={() => {
            toggleModal();
          }}>
          Cancelar
        </button>
        <button className="btn btn-primary generic-button-ficha" disabled={isSending} onClick={handleAction}>
          Enviar {isSending && <FontAwesomeIcon icon="spinner" transform="left-9" className="spinner ml-3" />}
        </button>
      </div>
    </GenericModal>
  );
}

export default ObservarMaternidad