import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
import FooterModal from "./FooterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalForm = ({ 
  onSubmit,
  showModal,
  width = "1100px",
  titulo,
  label = "Guardar",
  hasSaveButton = true,
  saveButtonDisabled = false,
  isSubmitting = false,
  footerExtraMessage = "",
  children,
  footerExtraButtons = null,
}) => (
  <ModalFormWrapper className="custom-scroll" modalWidth={width}>
    <ModalFormHeader>
      <p className="font-gothan-bold">{titulo}</p>
      <div className="icon">
        <FontAwesomeIcon
          icon="times-circle"
          className="hand-pointer"
          onClick={() => showModal()}
        />
      </div>
    </ModalFormHeader>
    <ModalFormContent>
      <div className="modalBody">
        <form onSubmit={onSubmit}>
          {children}
          {hasSaveButton && (
            <FooterModal
              isDisabled={saveButtonDisabled}
              isSubmitting={isSubmitting}
              label={label}
              extraMessage={footerExtraMessage}>
              {footerExtraButtons}
            </FooterModal>
          )}
        </form>
      </div>
    </ModalFormContent>
  </ModalFormWrapper>
);

ModalForm.propTypes = {
  onSubmit: PropTypes.func,
  showModal: PropTypes.func,
  width: PropTypes.string,
  titulo: PropTypes.string,
  label: PropTypes.string,
  hasSaveButton: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
        .isRequired,
}

export default ModalForm;

export const ModalFormWrapper = styled.div`
  width: ${({ modalWidth }) => modalWidth};
  height: auto;
  position: relative;
  background-color: #f7f8fa;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin: 10px;
`;

export const ModalFormHeader = styled.div`
  background-color: #0064a7;
  width: 100%;
  height: 40px;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  gap: 15px;

  p {
    margin: 0;
    padding: 0 35px;
  }

  .icon {
    padding: 0 15px;
  }
`;

export const ModalFormContent = styled.div`
  padding: 0 15px;
  max-height: 80vh;
  overflow-y: auto;

  .modal-label {
    font-size: 14px;
    text-align: left;
    color: #0063a1;
  }

  .modalBody {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
`;