import React, { useState } from 'react'
import PageWrapper from '../../components/pageComponents/PageWrapper'
import ModalEnabled from '../../components/modalComponents/ModalEnabled'
import PageHeader from '../../components/pageHeader/PageHeader'
import TabsHeader from '../../components/pageComponents/TabsHeader'
import Convocatorias from './tabs/Convocatorias'
import MisPostulaciones from './tabs/MisPostulaciones'

const MisTraslados = () => {

  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const { manageSearchConvocatoria, renderConvocatoria } = Convocatorias({ setModalEnabled });
  const { manageSearchMisPostulaciones, renderMisPostulaciones } = MisPostulaciones({ setModalEnabled });

  const tabsHeaderList = [
    {title: "Convocatorias", id: "datosCampaña", component: renderConvocatoria},
    {title: "Mis Postulaciones", id: "requerimientos", component: renderMisPostulaciones},
  ];
  
  const manageSearch = () => {
    manageSearchConvocatoria();
    manageSearchMisPostulaciones();
  }

  return (
    <PageWrapper>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title='Traslados'/>
      <TabsHeader list={tabsHeaderList}/>
    </PageWrapper>
  )
}

export default MisTraslados