//get

export const getPrimerPasoCapacitacion = (idCursoCapacitacion) => `Capacitacion/GetPrimerPasoCapacitacion?idCursoCapacitacion=${idCursoCapacitacion}`;
export const getSegundoPasoCapacitacion =(idCursoCapacitacion) => `Capacitacion/GetSegundoPasoCapacitacion?idCursoCapacitacion=${idCursoCapacitacion}`;
export const getDescargaFormatoDJAPI = () => `/Capacitacion/DescargarFormato`;
export const getCursosCapacitacionAPI = () => `Datos/GetCapacitaciones`;
export const getDatosTercerPasoAPI = (idCursoCapacitacion) => `Capacitacion/GetTercerPasoCapacitacion?idCursoCapacitacion=${idCursoCapacitacion}`;
export const getDatosIdentificacionAPI = (id) => `Ficha/GetDatosIdentificacion?idEmpleado=${id}`;
export const getDatosNacimientoAPI = (id) => `Ficha/GetDatosNacimiento?idEmpleado=${id}`;
export const getInformacionResidenciaAPI = (id) => `Ficha/GetInformacionResidencia?idEmpleado=${id}`;
export const getContactoDeEmergenciaAPI = (id) => `Ficha/GetContactoEmergencia?idEmpleado=${id}`;
export const getDatosFamiliaresAPI = (id) => `Ficha/GetDatosFamiliares?idEmpleado=${id}`;
export const getDatosEmpresaAPI = (id) => `Ficha/GetDatosEmpresa?idEmpleado=${id}`;
export const getInformacionCeseByIdEmpleadoAPI = (id) => `Ficha/GetInformacionCeseByIdEmpleado/${id}`;
export const getDatosDeTableroParaEmpleadoAPI = () => `Tablero/GetDatosTablero`;
export const getFichaSintomatologica = () => `FichaSintomatologica/GetFichaSintomatologica`;
export const getDetalleFichaSintomatologica = (id) => `FichaSintomatologica/GetDetalleFichaSintomatologica/${id}`;
export const getMenuPrincipalAPI = () => `Usuario/GetMenu`;
export const getReporteGeneralCamposAPI = () => `Datos/GetReporteGeneralCampos`;
export const getAllSolicitudesAPI = () => `SolicitudCambio/GetSolicitudes`;
export const getListaTablasGeneralesAPI = () => `TablasGenerales/GetListaTablas`;
export const getNotificacionesAPI = () => `Notificacion/GetNotificaciones`;
export const getFichaCampoPorSeccionAPI = (id) => `SolicitudCambio/GetFichaCampoPorSeccion?idSeccion=${id}`;
export const getContactanosInfoAPI = () => `Contactanos/GetContactanosInfo`;
export const getRolesEstadosAPI = () => `Datos/GetRolesEstados`;
export const getTiposAccesoAPI = () => `Datos/GetTiposAcceso`;
export const getAccesosRolAPI = (idRol, idTipoAcceso) => `Seguridad/GetAccesoRol?idRol=${idRol}&idTipoAcceso=${idTipoAcceso}`;
export const getMotivosRechazoSolicitudAPI = () => `Datos/GetMotivosRechazoSolicitud`;
export const getDivisionesAPI = () => `Datos/GetUnidades`;
export const getUnidadesForReporteDatosGeneralesAPI = () => `Datos/GetUnidadesForReporteDatosGenerales`;
export const getDivisionesWithTodosOptionAPI = () => `Datos/GetDivisionesWithTodosOption`;
export const getRoleForEditAPI = (idRol) => `Seguridad/GetRoleForEdit?idRol=${idRol}`;
export const getDatoTablasGeneralAPI = (idItem) => `TablasGenerales/GetItemTabla?idItem=${idItem}`;
export const getDatosTablasGeneralesAPI = (tabla, page) => `TablasGenerales/GetTablaGeneralItems?tablaGeneral=${tabla}&page=${page}`;
export const getNotasCeseByIdCeseApiCallAPI = (idCese) => `Cese/GetNotasCeseByIdCese/${idCese}`;
export const puedoVerEstaFichaApiCallAPI = (idEmpleado) => `Ficha/PuedoVerEstaFicha?IdEmpleado=${idEmpleado}`;
export const puedoEditarSeccionFormacionAcademicaCallAPI = (idEmpleado) => `Ficha/PuedoEditarSeccionFormacionAcademica?idEmpleado=${idEmpleado}`;
export const puedoEditarSeccionExperienciaLaboralCallAPI = (idEmpleado) => `Ficha/PuedoEditarSeccionExperienciaLaboral?idEmpleado=${idEmpleado}`;
export const getDetalleValidacionNuevosIngresosAPI = (idActividad) => `NuevosIngresos/GetDetalleValidacion?idActividad=${idActividad}`;
export const buscarMisColaboradoresPorCriterioAPI = (criterio) => `Datos/MisColaboradores/${criterio}`;
export const buscarColaboradorAPI = (criterio) => `Datos/Colaborador/${criterio}`;
export const getEmpleadosToCreateCondicionAPI = (criterio) => `Condicion/GetEmpleadosToCreateCondicion/${criterio}`;
export const getConfiguracionCeseAPI = () => `Cese/GetConfiguracion`;
export const getReportesALosQueTieneAccesoAPI = () => `Reportes/GetReportes`;
export const getConfiguracionDeRolesDeUnUsuarioAPI = (idUsuario) => `Seguridad/GetUsuarioRoles?idUsuario=${idUsuario}`;
export const getRolesActivosAPI = () => `Datos/GetRolesActivos`;
export const getPersonaEstadosAPI = () => `Datos/GetPersonaEstados`;
export const getTarjetaByIdAPI = (idTarjeta) => `Tablero/GetTarjetaById?idTarjeta=${idTarjeta}`;
export const getSiMostrarModalInformativoAPI = (idEmpleado) => `Ficha/MostrarModalInformativo?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalReferidosAPI = (idEmpleado) => `Ficha/MostrarModalReferidos?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalSiteDirectoresAPI = (idEmpleado) => `Ficha/MostrarModalSiteDirectores?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalConcursoInnovacionAPI = (idEmpleado) => `Ficha/MostrarModalConcursoInnovacion?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalHorariosSedesAPI = (idEmpleado) => `Ficha/MostrarModalHorariosSedes?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalBoletinAPI = (idEmpleado) => `Ficha/MostrarModalBoletin?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalHostigamientoAPI = (idEmpleado) => `Ficha/MostrarModalHostigamiento?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalBeneficiosAPI = (idEmpleado) => `Ficha/MostrarModalBeneficios?idEmpleado=${idEmpleado}`;
export const getSiMostrarModalBecasAPI = (idEmpleado) => `Ficha/MostrarModalBecas?idEmpleado=${idEmpleado}`;
export const getDataEmpleadoAPI = (id) => `Directorio/GetDatosEmpleado?IdEmpleado=${id}`;
export const getPaisesAPI = () => `Datos/GetPaises`;
export const getDepartamentosAPI = () => `Datos/GetUbigeoDepartamentos`;
export const getProvinciasPorDepartamentoAPI = (id) => `Datos/GetUbigeoProvinciaByIdDepartamento/${id}`;
export const getDistritosPorProvinciaAPI = (id) => `Datos/GetUbigeoDistritoByIdProvincia/${id}`;
export const getAllSedesAPI = () => `Datos/GetSedes`;
export const getEspecialidadesAPI = () => `Datos/GetEspecialidades`;
export const getGerenciasByDivisionAPI = (idUnidad) => `Datos/GetDepartamentosbyUnidad/${idUnidad}`;
export const getDepartamentosbyUnidadForReporteDatosGeneralesAPI = (idUnidad) => `Datos/GetDepartamentosbyUnidadForReporteDatosGenerales/${idUnidad}`;
export const getAreasByGerenciaAPI = (idGerencia) => `Datos/GetAreasByDepartamento/${idGerencia}`;
export const getDepartamentosVacacionesAPI = () => `Datos/GetDepartamentosVacaciones`;
export const getAreasVacacionesByDepartamentoAPI = (idDepartamento) => `Datos/GetAreasVacacionesByDepartamento/${idDepartamento}`;
export const getDepartamentosEmpActCesAPI = () => `Datos/GetDepartamentosEmpActCes`;
export const getAreasEmpActCesByDepartamentoAPI = (idDepartamento) => `Datos/GetAreasEmpActCesByDepartamento/${idDepartamento}`;
export const getAllCargosAPI = () => `Datos/GetCargos`;
export const getAllCargosWithTodosOptionAPI = () => `Datos/GetCargosWithTodosOption`;
export const getAllProcesoCierresAPI = () => `Datos/GetAllProcesoCierres`;
export const getAllDepartamentosAPI = () => `Datos/GetAllDepartamentos`;
export const getAllDepartamentosRegionesAPI = () => `Datos/GetAllDepartamentosRegion`;
export const getActividadEstadosAPI = () => `Datos/GetActividadEstados`;
export const getMisActividadEstadosAPI = () => `Datos/GetMisActividadEstados`;
export const getFlujosAPI = () => `Datos/GetFlujos`;
export const getActividadesDeFlujo = (id) => `Actividades/GetShowActivitiesLevel/${id}`;
export const getPasosOptionsNuevosIngresosAPI = () => `Datos/GetActividades`;
export const getAllAreasAPI = () => `Datos/GetAllAreas`;
export const getAllNivelesAPI = () => `Datos/GetNivelesEducativos`;
export const getAllColorsAPI = () => `Datos/GetColoresInnova`;
export const getFichaFormsAPI = (idEmpleado) => `Ficha/GetFichaForms?idEmpleado=${idEmpleado}`;
export const getDetalleActividadesAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetDetalleActividades?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getSeccionesByIdFormAPI = (idEmpleado, idForm) => `Ficha/GetSeccionesByIdForm?idEmpleado=${idEmpleado}&idForm=${idForm}`;
export const getActividadesRenunciasAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetActividadesRenuncias?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getConfirmarEstadoRecepcionEquiposAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetConfirmarEstadoRecepcionEquipos?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getEstadosAsignacionAPI = () => `Datos/GetEstadosAsignacion`;
export const getCartilllaVacunacionAPI = (idEmpleado) => `CartillaVacunacion?idEmpleado=${idEmpleado}`
export const geTiposDeVanunasCovidAPI = () => `Datos/GetVacunas`;
export const geTiposDeVanunasCovidTerceraDosisAPI = () => `Datos/GetVacunasTerceraDosis`;
export const getModalidadesDevolucionAPI = () => `Datos/GetModalidadesDevolucion`;
export const getEstadoRecepcionAPI = () => `Datos/GetEstadosRecepcion`;
export const getCondicionesMedicasAPI = () => `Datos/GetCondicionesMedicas`;
export const getConsultarEncuestaSaludPendienteAPI = () => `EncuestaSalud/ConsultarEncuestaPendiente`;
export const getDetailsByIdCeseAPI = (ceseId) => `Cese/GetDetailsByIdCese/${ceseId}`;
export const getDetalleEncuestaAPI = (idEncuesta) => `EncuestaSalud/GetDetalle?idEncuestaSalud=${idEncuesta}`;
export const getDetalleFichaMedicaAPI = (idEncuesta) => `EncuestaSalud/GetDetalleEncuestaSalud?idEncuestaSaludDetalle=${idEncuesta}`;
export const getEstadosFichaSaludAPI = () => `Datos/GetEstadosFichaEncuestaSalud`;
export const getEstadosResultadosEncuestaSaludAPI = () => `Datos/GetEstadosResultadoEncuestaSalud`;
export const getMotivosRenunciaAPI = () => `Datos/GetMotivosRenuncia`;
export const getTiposCeseAPI = () => `Datos/GetTiposCese`;
export const getTiposAdjuntosEncuestaSaludAPI = () => 'Datos/GetTiposAdjuntosEncuestaSalud';
export const getMotivosSinVacunacionAPI = () => 'Datos/GetMotivosSinVacunacion';
export const getGestionFichaDatos = (id) => `Directorio/GetGestionFichaDatos?IdEmpleado=${id}`;
export const getReasignarActividadAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetReasignarActividad/${idEmpleadoFlujoActividad}`;
export const getObservarActividadAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetObservarActividad/${idEmpleadoFlujoActividad}`;
export const getFechaVencimientoCierreByActividadIdAPI = (idActividad, idEntity) => `NuevosIngresos/GetFechaVencimientoCierreByActividadId?idActividad=${idActividad}&idEntity=${idEntity}`;
export const getAllTiposContratoAPI = () => 'Datos/GetTiposContrato';
export const getAllTiposContratoWithTodosOptionAPI = () => 'Datos/GetTiposContratoWithTodosOption';
export const getEditarColaboradorAPI = (idProcesoCierreEmpleado) => `ProcesoCierre/GetEditarColaborador/${idProcesoCierreEmpleado}`;
export const getGrupoAPI = (grupoId) => `ProcesoCierreAdmin/GetEditarGrupo/${grupoId}`;
export const eliminarGrupoAPI = (grupoId) => `ProcesoCierreAdmin/EliminarGrupo/${grupoId}`;
export const getGruposByProcesoAPI = () => `ProcesoCierreAsignar/GetGruposByProceso`;
export const getColaborador = (criteria) => `Datos/Colaborador/${criteria}`;
export const getColaboradorById = (idEmpleado) => `ProcesoCierre/ColaboradorById/${idEmpleado}`;
export const getDropDownColaborador = (criteria) => `Datos/GetDropDownColaborador/${criteria}`;
export const geProcesosAPI = () => `Datos/GetProcesos`;
export const getTiposContrato = () => 'Datos/GetTiposContrato';
export const getEtapasYGruposAPI = () => `ProcesoCierre/GetMonitorProceso`;
export const getDetalleColaboradorEjecutarProcesoAPI = (idProcesoCierreEmpleado) => `ProcesoCierre/GetDetalleColaboradorEjecutarProceso/${idProcesoCierreEmpleado}`;
export const getTiposUbicacionReservaAPI = () => `Datos/GetTiposUbicacionReserva`;
export const getMotivoReservaAPI = () => `Datos/GetMotivoReserva`;
export const getEstadoReservaAPI = () => `Datos/GetEstadoReserva`;
export const getUbicacionReservaAPI = () => `Datos/GetUbicacionReserva`;
export const getUbicacionesDisponiblesAPI = (fechaReserva) => `Reservas/GetUbicacionesDisponibles?fechaReserva=${fechaReserva}`;
export const getAllUbicacionesAPI = () => `Reservas/GetAllUbicaciones`;
export const getReservaAPI = (idReserva) => `Reservas/GetReserva?idReserva=${idReserva}`;
export const getFichaSintomatologicaReservas = () => `Reservas/GetFichaSintomatologicaReserva`;
export const getCreateReservaAPI = () => 'Reservas/GetCreateReserva';
export const getReservaSintomasTriajeAPI = () => 'Datos/GetReservaSintomasTriaje';
export const getCargarResutadosEvaluacion = () => `ProcesoCierre/GetCargarResutadosEvaluacion`;
export const getDetalleContratoEjecutarProcesoAPI = (idProcesoCierreEmpleado) => `ProcesoCierre/GetDetalleContratoEjecutarProceso/${idProcesoCierreEmpleado}`;
export const getCondicionesProcesoCierreAPI = () => `Datos/GetCondicionesProcesoCierre`;
export const getAllCondicionesWithTodosOptionAPI = () => `Datos/GetCondicionesProcesoCierreWithTodosOption`;
export const getAllJornadasLaboralesWithTodosOption = () => `Datos/GetJornadaLaboralWithTodosOption`;
export const getEstadosProcesoCierreAPI = () => `Datos/GetEstadosProcesoCierre`;
export const getEstadosRegistroCierreAPI = () => `Datos/GetEstadosRegistroCierre`;
export const getProcesoCierreTiposContratoAPI = () => 'ProcesoCierre/GetTiposContrato';
export const getNotasCeseByIdProcesoCierreEmpleadoAPI = (IdProcesoCierreEmpleado) => `ProcesoCierre/GetNotasCeseByIdProcesoCierreEmpleado/${IdProcesoCierreEmpleado}`;
export const getDocumentosAdicionalesAPI = () => `Datos/GetDocumentosAdicionales`;
export const getProcesoCierreCondicionesAPI = () => 'Datos/GetCondicionesProcesoCierre';
export const getObservarProcesoAPI = (idProcesoCierreEmpleado) => `ProcesoCierre/GetObservarProceso/${idProcesoCierreEmpleado}`;
export const getAllEstadosAPI = () => `Datos/GetAllEstados`;
export const getTipoSolicitudAPI = () => `Datos/GetTipoSolicitud`;
export const getAllPoliticaCategoriasAPI = () => `Datos/GetPoliticaCategorias`;
export const getCurriculumFileAPI = (idEmpleado) => `Ficha/GetCurriculumFile/${idEmpleado}`;
export const getExperienciaLaboralAPI = (idEmpleado) => `Ficha/GetExperienciaLaboral/${idEmpleado}`;
export const getAllOcurrenciasAPI = () => `Datos/GetTiposOcurrenciaCasoCovid`;
export const procesoCierreConfigGetEditarProcesoCierreAPI = (idProcesoCierre) => `ProcesoCierreConfig/GetEditarProcesoCierre/${idProcesoCierre}`;
export const getGruposActivosProcesoCierreAPI = () => `Datos/GetGruposActivosProcesoCierre`;
export const getEstadoEtapaProcesarYAsignarGruposAPI = () => `ProcesoCierre/GetEstadoEtapaProcesarYAsignarGrupos`;
export const getDatosYearEndAPI = (idEmpleado) => `Ficha/GetDatosYearEnd?idEmpleado=${idEmpleado}`;
export const getArchivosActividadesAPI = (idEmpleado) => `Ficha/GetArchivosActividades?idEmpleado=${idEmpleado}`;
export const getArchivosFichaDatosAPI = (idEmpleado) => `Ficha/GetArchivosFichaDatos/${idEmpleado}`;
export const getProcesosDeCierreListAPI = () => `Datos/GetProcesosDeCierreList`;
export const getAllProcesosDeCierreListAPI = () => `Datos/GetAllProcesosDeCierreList`;
export const getTiposDocumentoAPI = () => `Datos/GetTiposDocumento`;
export const getTiposVinculosAPI = () => `Datos/GetTipoVinculos`;
export const getLiquidacionesGetEditarColaboradorAPI = (idEmpleadoLiquidaciones) => `Liquidaciones/GetEditarColaborador/${idEmpleadoLiquidaciones}`;
export const getAllConfiguracionesAPI = () => `Liquidaciones/GetConfiguracion`;
export const getTiposPlantillaAPI = () => `Datos/GetTiposPlantilla`;
export const getCorreosTemplatesAPI = () => `Datos/GetCorreosTemplates`;
export const getPdfTemplatesAPI = () => `Datos/GetPdfTemplates`;
export const getTemplatesProcesoCierre = () => `Datos/GetTemplatesProcesoCierre`;
export const descargarFormatoResultadoPruebaLaboratorio = () => `PruebasLaboratorio/DescargarFormato`;
export const getDetallePruebaLaboratorio = (idRegistroResultado) => `PruebasLaboratorio/GetEditar/${idRegistroResultado}`;
export const getEstadosResultadoPruebaLaboratorio = () => `Datos/GetEstadosResultadoPruebasLaboratorio`;
export const getEstadosLaboratorioAPI = () => `Datos/GetEstadosPruebasLaboratorio`;
export const getEditarConfiguracionLiquidacion = (idConfiguracionLiquidaciones) => `Liquidaciones/GetEditarConfiguracion/${idConfiguracionLiquidaciones}`;
export const getCargaCartaCompromisoVacunacionAPI = (IdEmpleadoFlujoActividad) => `NuevosIngresos/GetCargaCartaCompromisoVacunacion?IdEmpleadoFlujoActividad=${IdEmpleadoFlujoActividad}`;
export const getFirmaCompromisoLecturaPoliticasAPI = (idEmpleadoFlujoActividad) => `Actividades/DownloadFirmaCompromisoLecturaPoliticas/${idEmpleadoFlujoActividad}`;
export const getValidarCartaCompromisoVacunacionAPI = (IdEmpleadoFlujoActividad) => `NuevosIngresos/GetValidarCartaCompromisoVacunacion?IdEmpleadoFlujoActividad=${IdEmpleadoFlujoActividad}`;
export const downloadCartaCompromisoVacunacionAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaCompromisoVacunacion?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const downloadCartaIncrementoAPI = (idEmpleadoFlujoActividad) => `Actividades/DownloadCartaIncremento/${idEmpleadoFlujoActividad}`;
export const downloadCartaAprobadoLineaCarreraAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaAprobadoLineaCarrera/${idEmpleadoFlujoActividad}`;
export const downloadDocumentoTeletrabajoAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadDocumentoTeletrabajo/${idEmpleadoFlujoActividad}`;
export const downloadCompraVacacionesAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCompraVacaciones/${idEmpleadoFlujoActividad}`;
export const downloadCartaCambioPuesto = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaCambioPuesto/${idEmpleadoFlujoActividad}`;
export const downloadCartaBonoAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaBono/${idEmpleadoFlujoActividad}`;
export const downloadCartaObjetivo = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaObjetivo/${idEmpleadoFlujoActividad}`;
export const downloadCartaObjetivo2022y2023 = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaObjetivo2022y2023/${idEmpleadoFlujoActividad}`;
export const downloadCartaIncremento2023API = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaIncremento2023/${idEmpleadoFlujoActividad}`;
export const downloadConvenioBonoTrasladoAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadConvenioBonoTraslado/${idEmpleadoFlujoActividad}`;
export const downloadCartaIncrementoBO2023API = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaIncrementoBO2023/${idEmpleadoFlujoActividad}`;
export const downloadConvenioCambioFuncionesAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadConvenioCambioFunciones/${idEmpleadoFlujoActividad}`;
export const downloadConvenioCambioPuestoAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadConvenioCambioPuesto/${idEmpleadoFlujoActividad}`;
export const downloadCartaCompromisoDeVacunacionAPI = (IdEmpleadoFlujoActividad) => `Actividades/DownloadCartaCompromisoVacunacion/${IdEmpleadoFlujoActividad}`;
export const downloadCartaIncrementoSede2024API = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadCartaIncrementoSede2024/${idEmpleadoFlujoActividad}`;
export const downloadConvenioMutuoDisensoAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadConvenioMutuoDisenso/${idEmpleadoFlujoActividad}`;
export const downloadPoliticaBonoTotAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadPoliticaBonoTot/${idEmpleadoFlujoActividad}`;
export const downloadDocumentoEMOAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadDocumentoEMO/${idEmpleadoFlujoActividad}`;
export const downloadRecompensaTotalSede2024 = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadRecompensaTotalSede2024/${idEmpleadoFlujoActividad}`;
export const downloadRecompensaTotalBO2024 = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadRecompensaTotalBO2024/${idEmpleadoFlujoActividad}`;
export const downloadDocumentoProrrogaAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadDocumentoProrroga/${idEmpleadoFlujoActividad}`;
export const downloadResultadoEvaluacion2024 =(idEmpleadoFlujoActividad) => `NuevosIngresos/DownloadResultadoEvaluacion2024/${idEmpleadoFlujoActividad}`
export const retornoClasesListar = () => `RetornoClases/Listar`;
export const getModosLecturaAPI = () => `Datos/GetModosLectura`;
export const listarCategoriasDePoliticasQuePuedoVerAPI = () => `PoliticaReglamento/ListarCategoriasDePoliticasQuePuedoVer`;
export const listarAllCategoriasAPI = () => `PoliticaReglamento/ListarTodasLasCategorias`;
export const getDetallePoliticaAPI = (id) => `PoliticaReglamento/GetDetallePolitica/${id}`;
export const getDetalleEditarPoliticaAPI = (id) => `PoliticaReglamento/GetPoliticaToEdit/${id}`;
export const listarBeneficiosAPI = () => `PoliticaReglamento/ListarBeneficios`;
export const getEditarAtributosAcademicosAPI = (idEmpleado) => `SeguimientoColaborador/GetEditarAtributosAcademicos?idEmpleado=${idEmpleado}`;
export const getDetalleSeguimientoCasoCovid = (idRegistro) => `SeguimientoCasosCovid/GetDetalleCasoCovid/${idRegistro}`;
export const getDetallesSintomasCovid = (idRegistro) => `SeguimientoCasosCovid/GetDetalleSintomasCasoCovid/${idRegistro}`; 
export const getDetallesParaCrearCasoCovid = (idEmpleado) => `SeguimientoCasosCovid/GetDetalleCasoCovidToCreate/${idEmpleado}`;
export const getDetalleTabSeguimiento = (idCaso) => `SeguimientoCasosCovid/GetDetalleSeguimientoCasoCovid/${idCaso}`;
export const getComplejidades = () => `Datos/GetComplejidadCasoCovid`;
export const getAllAreasEscolaresAPI = () => `Datos/GetAllAreasEscolares`;
export const getAllGradosEscolaresAPI = () => `Datos/GetAllGradosEscolares`;
export const getActiveModalidadesEscolaresAPI = () => `Datos/GetActiveModalidadesDictado`;
export const getAllModalidadesEscolaresAPI = () => `Datos/GetAllModalidadesDictado`;
export const getDatosContactoAPI = (idEmpleado) => `Directorio/GetDatosContacto/${idEmpleado}`;
export const getSedeByIdAPI = (idSede) => `Directorio/GetSedeById/${idSede}`;
export const getEditarSedeByIdAPI = (idSede) => `Directorio/GetEditarSedeById/${idSede}`;
export const mostrarModalesEnTableroAPI = () => `CartillaVacunacion/MostrarModalesEnTablero`;
export const GetGrupoByIdAPI = (idMonitorProcesoCierre) => `ProcesoCierreAsignar/GetGrupoById/${idMonitorProcesoCierre}`;
export const getAllJornadasLaboralesAPI = () => `Datos/GetJornadasLaborales`;
export const EditarGrupoByProceso = () => `ProcesoCierreAdmin/EditarGrupoByProceso`;
export const getLastCargaEvaluacionApi360API = () => `ProcesoCierre/GetLastCargaEvaluacionApi360`;
export const GetTipoContratoAPI = (idProcesoCierre) => `ProcesoCierreAsignar/GetTipoContrato/${idProcesoCierre}`;
export const getTiposInstruccionAPI = () => `Datos/GetTiposInstruccion`;
export const getAllFormacionProfesionalAPI = (idEmpleado) => `Ficha/GetAllFormacionProfesional/${idEmpleado}`;
export const getEditarFormacionProfesionalAPI = (idEmpleadoFormacionProfesional) => `Ficha/GetEditarFormacionProfesional/${idEmpleadoFormacionProfesional}`;
export const getAreasPuestoAPI = () => `Datos/GetAreasPuesto`;
export const getSectoresEmpresaAPI = () => `Datos/GetSectoresEmpresa`;
export const getSectoresInstitucionAPI = () => `Datos/GetSectoresInstitucion`;
export const getEspecialidadesCarreraAPI = () => `Datos/GetEspecialidadesCarrera`;
export const getNivelesEstudioAPI = () => `Datos/GetNivelesEstudio`;
export const getEstadosFormacionAPI = () => `Datos/GetEstadosFormacion`;
export const getGradosObtenidosAPI = () => `Datos/GetGradosObtenidos`;
export const getIdiomasAPI = () => `Datos/GetIdiomas`;
export const getNivelesIdiomaAPI = () => `Datos/GetNivelesIdioma`;
export const getCertificacionesIdiomaAPI = () => `Datos/GetCertificacionesIdioma`;
export const getTiposInstitucionAPI = () => `Datos/GetTiposInstitucion`;
export const getRelatedDataTypesAPI = (idParent) => `Datos/GetRelatedDataTypes/${idParent}`;
export const getTiposArchivoFichaDatosAPI = () => `Datos/GetTiposArchivoFichaDatos`;
export const getDominiosInnovaAPI = () => `Datos/GetDominiosInnova`;
export const getUnidadesRegistroInvitacionAPI = () => `Datos/GetUnidadesRegistroInvitacion`;
export const getAllTiposTrabajadorAPI = () => `Datos/GetAllTiposTrabajador`;
export const getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI = () => `Datos/GetTiposContratoByIdTipoTrabajadorAndIdJornadaLaboral`;
export const getExperienciaLaboralToEditAPI = (idEmpleadoExperienciaLaboral) => `Ficha/GetExperienciaLaboralToEdit/${idEmpleadoExperienciaLaboral}`;
export const getEmpleadoIdiomasAPI = (idEmpleado) => `Ficha/GetEmpleadoIdiomas/${idEmpleado}`;
export const getEmpleadoIdiomaToEditAPI = (idEmpleadoIdioma) => `Ficha/GetEmpleadoIdiomaToEdit/${idEmpleadoIdioma}`;
export const getLinksInteresAPI = () => `Tablero/GetLinksInteres`;
export const getActividadVideoAPI = (idEmpleadoFlujoActividad) => `Actividades/GetActividadVideo/${idEmpleadoFlujoActividad}`;
export const getActividadFileAPI = (idEmpleadoFlujoActividad) => `Actividades/GetActividadFile/${idEmpleadoFlujoActividad}`;
export const getInfoVacacionesAPI = (idEmpleado) => `Movimientos/GetInfoVacaciones?idEempleado=${idEmpleado}`;
export const getAllConfiguracionesMovimientosAPI = () => `Movimientos/GetAllConfiguraciones`;
export const getTiposMovimientoAPI = () => `Datos/GetTiposMovimiento`;
export const getTiposTemplateAPI = () => `Datos/GetTiposTemplate`;
export const getOrigenDocumentoAPI = () => `Datos/GetOrigenDocumento`;
export const getEditarSolicitudVacacionesAPI = (idSolicitud) => `Movimientos/GetEditarSolicitudVacaciones?idSolicitudMovimiento=${idSolicitud}`;
export const getFormatoPreviewDataAPI = (idSolicitud) => `Movimientos/GetFormatoPreviewData?idSolicitudMovimiento=${idSolicitud}`;
export const getSolicitudVacacionesAPI = (idSolicitudMovimiento) => `Movimientos/GetSolicitudVacaciones?idSolicitudMovimiento=${idSolicitudMovimiento}`;
export const getProcesoCierreInfoVacacionesAPI = (idEempleado, idPlantillaConfig) => `Movimientos/GetProcesoCierreInfoVacaciones?idEempleado=${idEempleado}&idPlantillaConfig=${idPlantillaConfig}`;
export const getEditArchivosFichaDatosAPI = (IdEmpleadoArchivoFichaDatos) => `Ficha/GetEditArchivosFichaDatos?IdEmpleadoArchivoFichaDatos=${IdEmpleadoArchivoFichaDatos}`;
export const getDatosContratosAPI = (idEmpleado) => `Ficha/GetDatosContratos?idEmpleado=${idEmpleado}`;
export const getEditarContratoAPI = (idEmpleadoContrato) => `Ficha/GetEditarContrato?idEmpleadoContrato=${idEmpleadoContrato}`;
export const getArchivosNuevosIngresosFichaAPI = (idEmpleado) => `Ficha/GetArchivosNuevosIngresos?idEmpleado=${idEmpleado}`;
export const getAsignacionFamiliarAPI = (idEmpleado) => `Ficha/GetAsignacionFamiliar?idEmpleado=${idEmpleado}`;
export const getHijosSolicitudByColaboradorAPI = (idEmpleado) => `Ficha/GetHijosSolicitudByColaborador?idEmpleado=${idEmpleado}`;
export const getAllSchedulesAPI = () => `Schedule/Index`;
export const getEditCommandScheduleAPI = (IdCommand) => `Schedule/GetEditCommand/${IdCommand}`;
export const getFrequenciesAPI = () => `Datos/GetFrequencies`;
export const getDiasDeSemanaAPI = () => `Datos/GetDiasDeSemana`;
export const getGenerosAPI = () => `Datos/GetGeneros`;
export const getEstadosCivilesAPI = () => `Datos/GetEstadosCiviles`;
export const getClasificacionesGDHAPI = () => `Datos/GetClasificacionesGDH`;
export const getSeccionesByAreaAPI = (idArea) => `Datos/GetSeccionesByArea/${idArea}`;
export const getGruposOcupacionalesAPI = () => `Datos/GetGruposOcupacionales`;
export const getGradoDesarrolloAPI = () => `Datos/GetGradoDesarrollo`;
export const getTiposViviendaAPI = () => `Datos/GetTiposVivienda`;
export const getTiposViaAPI = () => `Datos/GetTiposVia`;
export const getTiposZonaAPI = () => `Datos/GetTiposZona`;
export const getSistemaPensionesAPI = () => `Datos/GetSistemaPensiones`;
export const getMonedasAPI = () => `Datos/GetMonedas`;
export const getMonedasSDAPI = () => `Datos/GetMonedasSD`;
export const getBancosAPI = () => `Datos/GetBancos`;
export const getBancosValidosAPI = () => `Datos/GetBancosValidos`;
export const getFlujosOnboardingAPI = () => `Datos/GetFlujosOnboarding`;
export const getTiposLicenciaAPI = () => `Datos/GetTiposLicencia`;
export const getPeriodosPruebaAPI = () => `Datos/GetPeriodosPrueba`;
export const getPresupuestosAPI = () => `Datos/GetPresupuestos`;
export const getTiposVacanteAPI = () => `Datos/GetTiposVacante`;
export const getTiposRequerimientoAPI = () => `Datos/GetTiposRequerimiento`;
export const getPrioridadesAPI = () => `Datos/GetPrioridades`;
export const getAvanceRequerimientoAPI = () => `Datos/GetAvanceRequerimiento`;
export const getTestActivityAPI = (idEmpleadoFlujoActividad) => `Actividades/GetTestActivity?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getEncuestaActivityAPI = (idEmpleadoFlujoActividad) => `Actividades/GetEncuestaActivity?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getOnboardingInfoActivityAPI = (idEmpleadoFlujoActividad) => `Actividades/GetOnboardingInfoActivity?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getInduccionVirtualActivityAPI = (idEmpleadoFlujoActividad) => `Actividades/GetInduccionVirtualActivity?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getEditRegistroInvitacionAPI = (idRegistroInvitacion) => `RegistroInvitaciones/GetEditRegistroInvitacion?idRegistroInvitacion=${idRegistroInvitacion}`;
export const getInfoColaboradorNuevoActivityAPI = (idEmpleadoFlujoActividad) => `Actividades/GetInfoColaboradorNuevoActivity?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const exportarHelpDeskNuevosngresosAPI = () => `NuevosIngresos/ExportarHelpDesk`;
export const getConfirmarIngresoActivityAPI = (idEmpleadoFlujoActividad) => `Actividades/GetConfirmarIngresoActivity?idEmpleadoFlujoActividad=${idEmpleadoFlujoActividad}`;
export const getEtiquetasNuevaPlantillaAPI = (idTemplateType) => `Template/GetEtiquetas?idTemplateType=${idTemplateType}`;
export const getDetalleAsignacionFamiliarAPI = (idSolicitudAsignacionFamiliar ) => `AsignacionFamiliarAdmin/GetDetalleAsignacionFamiliar/${idSolicitudAsignacionFamiliar}`;
export const getIdEncuentaSaludAPI = () => `EncuestaSalud/GetIdEncuentaSalud`;
export const getComponentesNuevaPlantillaAPI = () => `Template/GetComponentes`;
export const getEditarTemplateAPI = (plantillaId) => `Template/GetEditarTemplate/${plantillaId}`;
export const getEditarEtiquetaAPI = (etiquetaId) => `Template/GetEditarEtiqueta/${etiquetaId}`;
export const getEditarComponenteAPI = (componenteId) => `Template/GetEditarComponente/${componenteId}`;
export const getDocumentosNuevosIngresosAdminAPI = () => `NuevosIngresosAdmin/GetDocumentos`;
export const getEditarConfiguracionDocumentoAPI = (idNuevosIngresosDocumentos) => `NuevosIngresosAdmin/GetEditarDocumento?idNuevosIngresosDocumentos=${idNuevosIngresosDocumentos}`;
export const getEditarConfiguracionListaDocumentosAPI = (idNuevosIngresosLista) => `NuevosIngresosAdmin/GetEditarLista?idNuevosIngresosLista=${idNuevosIngresosLista}`;
export const getDocumentosByIdListaDocumentosAPI = (idListaDocumentos) => `NuevosIngresosAdmin/GetDocumentosByIdLista/${idListaDocumentos}`;
export const getDocumentosByIdListaAPI = (idListaDocumentos) => `NuevosIngresosAdmin/GetDocumentosByIdLista/${idListaDocumentos}`;
export const getFormasIngresoAPI = () => `Datos/GetFormasIngreso`;
export const getEspecialidadSeleccionAPI = () => `Datos/GetEspecialidadSeleccion`;
export const getNuevosIngresosPrioridadesAPI = () => `Datos/GetNuevosIngresosPrioridades`;
export const getCondicionesAcademicasAPI = () => `Datos/GetCondicionesAcademicas`;
export const getPeriodosPlanillaActivosAPI = () => `Datos/GetPeriodosPlanillaActivos`;
export const getTiposTrasladoAPI = () => `Datos/GetTiposTraslado`;
export const getListaFuentesReclutamientoAPI = () => `Datos/GetListaFuentesReclutamiento`;
export const getTipoDeCondicionAPI = (idEmpleado) => `Datos/GetTipoDeCondicion/${idEmpleado}`;
export const getAllTipoCondicionAPI = () => `Datos/GetAllTipoCondicion`;
export const getMotivosDescansoMedicoAPI = () => `Datos/GetMotivosDescansoMedico`;
export const getInstitucionesDescansoMedicoAPI = () => `Datos/GetInstitucionesDescansoMedico`;
export const getMotivoTerminoMaternidadAPI = () => `Datos/GetMotivoTerminoMaternidad`;
export const getHorariosLactanciaMaternidadAPI = () => `Datos/GetHorariosLactanciaMaternidad`;
export const getListaDocumentosAPI = () => `NuevosIngresosAdmin/GetListaDocumentos`;
export const getDocumentosEmpleadoAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetDocumentosEmpleado/${idNuevosIngresosEmpleado}`;
export const getDatosIdentificacionGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetDatosIdentificacion/${idNuevosIngresosEmpleado}`;
export const getDatosNacimientoGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetDatosNacimiento/${idNuevosIngresosEmpleado}`;
export const getInformacionResidenciaGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetInformacionResidencia/${idNuevosIngresosEmpleado}`;
export const getFormacionAcademicaGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetFormacionAcademica/${idNuevosIngresosEmpleado}`;
export const getDatosEmpresaGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetDatosEmpresa/${idNuevosIngresosEmpleado}`;
export const getInformacionEconomicaGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetInformacionEconomica/${idNuevosIngresosEmpleado}`;
export const getDatosNuevoIngresoGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetDatosNuevoIngreso/${idNuevosIngresosEmpleado}`;
export const getResponsablesNuevoIngresoGestionAPI = () => `NuevosIngresosAdmin/GetResponsables`;
export const getListaDocumentosEmpleadoNuevoIngresoGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetListaDocumentosEmpleado/${idNuevosIngresosEmpleado}`;
export const consultarReingresoNuevoIngresoGestionAPI = (numeroDocumento) => `NuevosIngresosAdmin/ConsultarReingreso/${numeroDocumento}`;
export const consultarSedeNuevoIngresoGestionAPI = (idSede) => `NuevosIngresosAdmin/ConsultarSede/${idSede}`;
export const GetBaseNuevoIngresoGestionAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetBaseNuevoIngreso/${idNuevosIngresosEmpleado}`;
export const getNuevosIngresosDocumentoObservadoAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetNuevosIngresosDocumentoObservado/${idEmpleadoFlujoActividad}`;
export const getTRegistroFileSinFirmaByIdEmpleadoFlujoActividadAPI = (idEmpleadoFlujoActividad) => `NuevosIngresos/GetTRegistroFileSinFirmaByIdEmpleadoFlujoActividad/${idEmpleadoFlujoActividad}`;
export const getPerfilPuestoByCargoAPI = (idCargo) => `Searcher/GetPerfilPuestoByCargo/${idCargo}`;
export const getNuevosIngresosPasosObligatoriosOptionsAPI = () => `NuevosIngresosAdmin/GetNuevosIngresosPasosObligatoriosOptions`;
export const getNuevosIngresosPasosObligatoriosOptionsByIdNuevosIngresosEmpleado = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetNuevosIngresosPasosObligatoriosOptionsByIdNuevosIngresosEmpleado/${idNuevosIngresosEmpleado}`;
export const getNuevosIngresosEmpleadoIdByIdEmpleadoFlujoAPI = (idEmpleadoFlujo) => `NuevosIngresosAdmin/GetNuevosIngresosEmpleadoIdByIdEmpleadoFlujo/${idEmpleadoFlujo}`;
export const getDatosFamiliaresNuevosIngresosAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/GetDatosFamiliares?idNuevosIngresosEmpleado=${idNuevosIngresosEmpleado}`;
export const getSolicitudDescuentoPensionToCreateAPI = (idEmpleado) => `SolicitudDescuentoPension/GetSolicitudDescuentoPensionToCreate/${idEmpleado}`;
export const getProcesosSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/GetProcesosSolicitudDescuentoPension`;
export const getEditPerfilPuestoAPI = (idPerfilPuesto) => `GestionRequerimiento/GetEditPerfilPuesto?idPerfilPuesto=${idPerfilPuesto}`;
export const getEditPresupuestoAPI = (idPresupuesto) => `GestionRequerimiento/GetEditPresupuesto?idPresupuesto=${idPresupuesto}`;
export const getEditRequerimientoPersonalAPI = (idRequerimientoPersonal) => `GestionRequerimiento/GetEditRequerimientoPersonal?idRequerimientoPersonal=${idRequerimientoPersonal}`;
export const getPerfilPuestoByCargoPI = (idCargo) => `GestionRequerimiento/GetPerfilPuestoByCargo/${idCargo}`;
export const getRequerimientosReasignarAPI = () => `GestionRequerimiento/GetRequerimientosReasignar`;
export const getConvocatoriaTrasladosToEditAPI = (idConvocatoriaTraslado) => `Traslados/GetConvocatoriaTrasladosToEdit?idConvocatoriaTraslado=${idConvocatoriaTraslado}`;
export const getAmonestacionesSuspencionesAPI = (idEmpleado) => `Traslados/GetAmonestacionesSuspenciones?idEmpleado=${idEmpleado}`;
export const getDetallePostulacionAPI = (idConvocatoriaTrasladoEmpleado) => `Traslados/GetDetallePostulacion?idConvocatoriaTrasladoEmpleado=${idConvocatoriaTrasladoEmpleado}`;
export const getRequerimientosDisponiblesPorCargoAPI = () => `Traslados/GetRequerimientosDisponiblesPorCargo`;
export const getProcesoSolicitudDescuentoPensionByIdAPI = (idProcesoSolicitudDescuentoPension) => `SolicitudDescuentoPension/GetProcesoSolicitudDescuentoPensionById/${idProcesoSolicitudDescuentoPension}`;
export const getProcesosSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/GetProcesosSolicitudTaiLoy`;
export const getValesTaiLoyAPI = (idProcesoSolicitudTaiLoy) => `SolicitudTaiLoy/GetValesTaiLoy/${idProcesoSolicitudTaiLoy}`;
export const getConfiguracionesProcesoTaiLoyByIdProcesoAPI = (idProcesoSolicitudTaiLoy) => `SolicitudTaiLoy/GetConfiguracionesProcesoTaiLoyByIdProceso/${idProcesoSolicitudTaiLoy}`;
export const getValesTaiLoyToCreateAPI = (idEmpleado) => `SolicitudTaiLoy/GetValesTaiLoyToCreate/${idEmpleado}`;
export const getAllQuestionsAsDropdownChatbotFaqAPI = () => `ChatbotFaq/GetAllQuestionsAsDropdown`;
export const getQuestionsForEditAndCreateAsDropdownChatbotFaqAPI = () => `ChatbotFaq/GetQuestionsForEditAndCreateAsDropdown`;
export const getTiposChatbotFaqAPI = (idChatbotFaq) => `ChatbotFaq/GetTiposChatbotFaq/${idChatbotFaq}`;
export const getEditarPopupByIdAPI = (idPopup) => `Popup/GetEditarPopupById/${idPopup}`;
export const getShowPopupAPI = (idEmpleado) => `Popup/GetShowPopup?idEmpleado=${idEmpleado}`;
export const getSeguimientosGestacionByIdCondicionAPI = (idCondicion) => `Condicion/GetSeguimientosGestacionByIdCondicion/${idCondicion}`;
export const asistenciaLactarioGetPeriodosDisponiblesBySedeAPI = (idSede) => `Condicion/AsistenciaLactarioGetPeriodosDisponiblesBySede/${idSede}`;
export const getBaseGuardarHorarioLactanciaAPI = (idCondicion) => `Condicion/GetBaseGuardarHorarioLactancia/${idCondicion}`;
export const getBaseGuardarPrePostNatalAPI = (idCondicion) => `Condicion/GetBaseGuardarPrePostNatal/${idCondicion}`;
export const generarCartaConvenioHorarioLactanciaAPI = () => `Condicion/GenerarCartaConvenioHorarioLactancia`;
export const guardarCartaConvenioHorarioLactanciaAPI = () => `Condicion/GuardarCartaConvenioHorarioLactancia`;
export const guardarActualizarHorarioLactanciaAPI = () => `Condicion/GuardarActualizarHorarioLactancia`;
export const getHorarioLactanciaByIdEmpleadoFlujoActividadAPI = (idEmpleadoFlujoActividad) => `Condicion/GetHorarioLactanciaByIdEmpleadoFlujoActividad/${idEmpleadoFlujoActividad}`;
export const getDataForModalGuardarDocumentoCanjeDMEssaludCitt = (idEmpleadoFlujoActividad) => `Condicion/GetDataForModalGuardarDocumentoCanjeDMEssaludCitt/${idEmpleadoFlujoActividad}`;
export const guardarDocumentoCanjeDMEssaludCittFirmado = () => `Condicion/GuardarDocumentoCanjeDMEssaludCittFirmado`;
export const getInfoFlujoCanjeDMCittAPI = (idEmpleadoFlujoActividad) => `Condicion/GetInfoFlujoCanjeDMCitt/${idEmpleadoFlujoActividad}`;
export const subirDocumentoTramiteEssaludAPI = () => `Condicion/SubirDocumentoTramiteEssalud`;
export const validarDocumentoTramiteEssaludAPI = () => `Condicion/ValidarDocumentoTramiteEssalud`;
export const observarDocumentoTramiteEssaludAPI = () => `Condicion/ObservarDocumentoTramiteEssalud`;
export const subirDocumentoDMCittAPI = () => `Condicion/SubirDocumentoDMCitt`;
export const validarDocumentoDMCittAPI = () => `Condicion/ValidarDocumentoDMCitt`;
export const observarDocumentoDMCittAPI = () => `Condicion/ObservarDocumentoDMCitt`;
export const getPuedeVerCargosMatricialesAPI = () => `Movimientos/PuedeVerCargosMatriciales`;
export const getTiposHorariosLactanciaMaternidadAPI = () => `Datos/GetTiposHorariosLactanciaMaternidad`;
export const getInfoCambioCuentaSueldoAPI = () => `SolicitudCambioCuentaSueldo/GetInfoCambioCuentaSueldo`;
export const getInfoToCreateCondicionAPI = (idEmpleado) => `Condicion/GetInfoToCreateCondicion/${idEmpleado}`;


//post
export const getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI = () => `Datos/GetEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdArea`;
export const calificarCapacitacionAPI = () => `Capacitacion/CalificarCapacitacion`;
export const guargarTercerPaso = () => `Capacitacion/GuardarTercerPaso`;
export const getListaCapacitacion =() => `Capacitacion/ListarGestor`;
export const descargarListaCapacitacion = () => `Capacitacion/DescargarGestor`;
export const eliminarRegistroCapacitacionAPI = () => `/Capacitacion/EliminarRegistro`;
export const activarRegistroCapacitacionAPI = (id) => `Capacitacion/ActivarRegistro/${id}`;
export const inactivarRegistroCapacitacionAPI = (id) => `Capacitacion/InactivarRegistro/${id}`;
export const activarModelarCapacitacionAPI = () => `Capacitacion/ActivarModelado`;
export const desactivarModeladoCapacitacionAPI = () => `Capacitacion/DesactivarModelado`;
export const listarCapacitacionesAPI = () => `MisCapacitaciones/Listar`;
export const ListarSeguimientoCasosCovid = () => `SeguimientoCasosCovid/Listar`;
export const descargarListaSeguimientoCasosCovid = () => `SeguimientoCasosCovid/Descargar`;
export const eliminarRegistoSeguimientoCasosCovid = (idCasoCovid) => `SeguimientoCasosCovid/Eliminar/${idCasoCovid}`;
export const reabrirCasoCovid = (idCasoCovid) => `SeguimientoCasosCovid/Reabrir/${idCasoCovid}`;
export const getColaboradoresCasoCovid = (criteria) => `SeguimientoCasosCovid/ListarColaboradores/${criteria}`;
export const crearSintomatologiaCasoCovid = () => `SeguimientoCasosCovid/Crear`;
export const cerrarCasoCovid = (idCaso) => `SeguimientoCasosCovid/CerrarCaso/${idCaso}`;
export const guardarSintomatologiaCasoCovid = () => `SeguimientoCasosCovid/SaveSintomasCasoCovid`;
export const reprocesarGrupos = (idCasoCovid) => `SeguimientoCasosCovid/ReprocesarGrupos/${idCasoCovid}`;
export const getNotasSeguimientoCasosCovid = (idCasoCovid) => `SeguimientoCasosCovid/GetNotasCasoCovid/${idCasoCovid}`;
export const postNotasSeguimientoCasosCovid = () => `SeguimientoCasosCovid/CrearNota`;
export const enviarCartaLicencia = (idCasoCovid) => `SeguimientoCasosCovid/EnviarCartaLicencia/${idCasoCovid}`;
export const saveTabSeguimiento = () => `SeguimientoCasosCovid/SaveSeguimientoCasoCovid`;
export const listarProgramaPruebasAPI = () => `PruebasLaboratorio/Listar`;
export const descargarListaProgramaPrueabasAPI = () => `PruebasLaboratorio/Descargar`;
export const registrarColaboradorProgramaPruebaAPI = () => `PruebasLaboratorio/AgregarColaborador`;
export const programarExamenCovidAPI = () => `PruebasLaboratorio/ProgramarExamenCovid`;
export const programarExamenCovidCaso = () => `PruebasLaboratorio/ProgramarExamenCovidByCasoCovid`;
export const cargarResultadosPruebaLaboratorioAPI = () => `PruebasLaboratorio/CargarResultados`;
export const guardarPruebaLaboratorioAPI = () => `PruebasLaboratorio/SaveEditar`;
export const editarSedeAPI = () => `Directorio/SaveEditarSede`;
export const eliminarRegistroPruebaLaboratorioAPI = () => `PruebasLaboratorio/Delete`;
export const seguimientoColaboradorEnvioCarnetVacunaZipAPI = () => 'SeguimientoColaborador/SendCartillaVacunacionMailZip';
export const listarGestionVacunacionAPI = () => `CartillaVacunacion/GestionVacunaIndex`;
export const eliminarCartillaVacunacionAPI = () => `CartillaVacunacion/EliminarCartillaVacunacion`;
export const guardarDatosIdentificacionAPI = () => `Ficha/GuardarDatosIdentificacion`;
export const guardarDatosNacimientoAPI = () => `Ficha/GuardarDatosNacimiento`;
export const guardarDatosCumpleAPI = () => `Ficha/GuardarDatosCumple`;
export const guardarInformacionResidenciaAPI = () => `Ficha/GuardarInformacionResidencia`;
export const guardarContactoDeEmergenciaAPI = () => `Ficha/GuardarContactoEmergencia`;
export const guardarDatosFamiliaresAPI = () => `Ficha/GuardarDatosFamiliares`;
export const guardarDatosEmpresaAPI = () => `Ficha/GuardarDatosEmpresa`;
export const agregarEstudioAdicionalAPI = () => `Ficha/AgregarEstudioAdicional`;
export const getEditarEstudioAdicionalAPI = (idEstudioAdicional) => `Ficha/GetEditarEstudioAdicional/${idEstudioAdicional}`
export const editarEstudioAdicionalAPI = () => `Ficha/EditarEstudioAdicional`;
export const eliminarEstudioAdicionalAPI = (idEstudioAdicional) => `Ficha/EliminarEstudioAdicional/${idEstudioAdicional}`; 
export const getEstudiosAdicionalesByIdAPI = (idEmpleado) => `Ficha/GetEstudiosAdicionales/${idEmpleado}`
export const guardarCurriculumFileAPI = () => `Ficha/SaveCurriculumFile`;
export const eliminarCurriculumFileAPI = (idEmpleado) => `Ficha/DeleteCurriculumFile/${idEmpleado}`;
export const listarDirectorioAPI = () => `Directorio/ListarDirectorio`;
export const listarDatosGrupoAPI = () => `Directorio/ListarDatosGrupo`;
export const listarSedesAPI = () => `Directorio/ListarSedes`;
export const listarGestionDirectorioAPI = () => `Directorio/ListarGestionDirectorio`;
export const listarDirectorioMiEquipoAPI = () => `Equipo/ListarMiEquipo`;
export const descargarReporteDirectorioMiEquipoAPI = () => `Equipo/DescargarReporteDirectorio`;
export const getNuevosIngresosListadoActividadesAPI = () => `NuevosIngresos/GetListaActividades`;
export const getNuevosIngresosMisActividadesAPI = () => `NuevosIngresos/GetListaMisActividades`;
export const descargarListaActividadesPendientesAPI = () => `Actividades/DescargarListaActividades`;
export const seguridadListarRolesAPI = () => `Seguridad/GetRoles`;
export const seguridadListarUsuariosAPI = () => `Seguridad/GetUsuarios`;
export const getPreguntasFrecuentesAPI = () => `Faq/GetPreguntasFrecuentes`;
export const enviarSolicitudAPI = () => `SolicitudCambio/EnviarSolicitud`;
export const seguridadAgregarAccesoRolAPI = () => `Seguridad/AgregarAccesoRol`;
export const seguridadEliminarAccesoRolAPI = () => `Seguridad/EliminarAccesoRol`;
export const enviarMensajeContactanosAPI = () => `Contactanos/EnviarConsulta`;
export const aprobarActividadNuevosIngresosAPI = () => `NuevosIngresos/AprobarActividad/`;
export const rechazarActividadNuevosIngresosAPI = () => `NuevosIngresos/RechazarActividad`;
export const leerNotificationsAPI = () => `Notificacion/LeerNotifications`;
export const aprobarSolicitudAPI = (idFichaCampoSolicitud) => `SolicitudCambio/AprobarSolicitud/${idFichaCampoSolicitud}`;
export const rechazarSolicitudAPI = () => `SolicitudCambio/RechazarSolicitud`;
export const anularItemTablaGeneralAPI = (idTabla) => `TablasGenerales/AnularItem/${idTabla}`;
export const activarItemTablaGeneralAPI = (idTabla) => `TablasGenerales/ActivarItem/${idTabla}`;
export const editarItemTablaGeneralAPI = () => `TablasGenerales/EditarItem`;
export const registrarRolAPI = () => `Seguridad/CreateRol`;
export const editarSeguridadRolAPI = () => `Seguridad/EditRole`;
export const getUsuarioToCreateAPI = () => `Seguridad/GetUsuariosToCreate`;
export const crearItemTablaGeneralAPI = () => `TablasGenerales/CrearItem`;
export const generarReporteCesesAPI = () => `Cese/DescargarReporte`;
export const configurarCeseAPI = () => `Cese/Configuracion`;
export const rechazarCeseAPI = () => `Cese/RechazarCese`;
export const aprobarCeseAPI = (idCese) => `Cese/AprobarCese/${idCese}`;
export const validarLiquidacionAPI = (idEmpleadoLiquidacion) => `Liquidaciones/ValidarLiquidacion/${idEmpleadoLiquidacion}`;
export const validarLiquidacionesAPI = () => `Liquidaciones/ValidarLiquidaciones`;
export const registrarNotaCeseAPI = () => `Cese/CrearNota`;
export const crearCeseAPI = () => `Cese/Crear`;
export const guardarEditarCeseAPi = () => `Cese/UpdateCese`;
export const buscarColaboradorCeseAPI = () => `Cese/GetColaboradores`;
export const aceptarOcultarModalInformativoAPI = () => `Ficha/AceptarOcultarModalInformativo`;
export const aceptarOcultarModalReferidosAPI = () => `Ficha/AceptarOcultarModalReferidos`;
export const aceptarOcultarModalSiteDirectoresAPI = () => `Ficha/AceptarOcultarModalSiteDirectores`;
export const aceptarOcultarModalHorariosSedesAPI= () => `Ficha/AceptarOcultarModalHorariosSedes`;
export const aceptarOcultarModalBoletinAPI= () => `Ficha/AceptarOcultarModalBoletin`;
export const aceptarOcultarModalHostigamientoAPI= () => `Ficha/AceptarOcultarModalHostigamiento`;
export const aceptarOcultarModalBeneficiosAPI= () => `Ficha/AceptarOcultarModalBeneficios`;
export const aceptarOcultarModalConcursoInnovacionAPI = () => `Ficha/AceptarOcultarModalConcursoInnovacion`;
export const enviarReporteGeneralAPI = () => `Reportes/EnviarReporteGeneral`;
export const createUsuariosAPI = () => `Seguridad/CreateUsuarios`;
export const updateRolesDeUsuarioAPI = () => `Seguridad/GuardarUsuarioRoles`;
export const desactivarUsuarioCallApiAPI = () => `Seguridad/DesactivarUsuario`;
export const activarUsuarioCallApiAPI = () => `Seguridad/ActivarUsuario`;
export const enviarReporteClimaLaboralAPI = () => `Reportes/EnviarReporteClimaLaboral`;
export const generarOfisisFileAPI = () => `NuevosIngresos/DescargarOfisisFicha`;
export const descargarArchivosZipAPI = () => `NuevosIngresos/DescargarArchivosZip`;
export const guardarTarjetaPaginaPrincipalAPI = () => `Tablero/GuardarTarjeta`;
export const aceptarCookiesAPI = (id) => `Tablero/AcceptCookies?idUsuario=${id}`;
export const aceptarTerminosAPI = (id) => `Tablero/AcceptTerms?idUsuario=${id}`;
export const cancelarSolicitudDeCambioAPI = (idFichaCampo) => `SolicitudCambio/Cancelar/${idFichaCampo}`;
export const deleteFileFromS3API = () => `Sistema/DeleteFileFromS3`;
export const guardarVerificarErPendienteAPI = () => `NuevosIngresos/GuardarVerificarErPendiente`;
export const validarGuardarInformacionResidenciaAPI = () => `Ficha/ValidarGuardarInformacionResidencia`;
export const guardarConfirmarEstadoRecepcionEquiposAPI = () => `NuevosIngresos/GuardarConfirmarEstadoRecepcionEquipos`;
export const guardarActividadGenerarLiquidacionAPI = () => `NuevosIngresos/GuardarActividadGenerarLiquidacion`;
export const guardarActividadAmpliarLiquidacionAPI = () => `NuevosIngresos/GuardarActividadAmpliarLiquidacion`;
export const cartillaVacunacionAPI = () => `CartillaVacunacion/Guardar`;
export const EditarcartillaVacunacionAPI = () => `CartillaVacunacion/Editar`;
export const vaccineConfirmationAPI = () => `CartillaVacunacion/vaccineconfirmation`;
export const validarRegistroVacunaAPI = () => `CartillaVacunacion/validarRegistroVacuna`;
export const ocultarModalInformativoVacunacionAPI = () => `CartillaVacunacion/OcultarModalInformativo`;
export const guardarConfirmarPrestamoAPI = () => `NuevosIngresos/GuardarConfirmarPrestamo`;
export const emitirConstanciaDeTrabajoAPI = () => `Usuario/EmitirConstanciaTrabajo`;
export const guardarCoordinarDevolucionAPI = () => `NuevosIngresos/GuardarCoordinarDevolucion`;
export const bulkAprobarActividadAPI = () => `NuevosIngresos/BulkAprobarActividad`;
export const getAllCesesAPI = () => `Cese/GetAll`;
export const getEditInfoCesesAPi = (idCese) => `Cese/GetCeseById/${idCese}`;
export const guardarEncuestaSaludAPI = () => `EncuestaSalud/Guardar`;
export const registrarAdjuntarSustentoAPI = () => `EncuestaSalud/RegistrarAdjuntarSustento`;
export const solicitarRevisionFichaSaludAPI = () => `EncuestaSalud/SolicitarRevision`;
export const reprocesarActividadAPI = () => `NuevosIngresos/ReprocesarActividad`;
export const downloadReporteRenunciasAPI = () => `Reportes/DownloadReporteRenuncias`;
export const downloadReporteVacacionesCantidadDiasSegunPeriodoAPI = () => `Reportes/ReporteVacacionesCantidadDiasSegunPeriodo`;
export const downloadReporteCumpleaniosAPI = () => `Reportes/DownloadReporteCumpleanios`;
export const downloadReporteDatosGeneralesAPI = () => `Reportes/DownloadReporteDatosGenerales`;
export const listarEncuestasDeSaludAPI = () => `EncuestaSalud/ListarEncuestas`;
export const eliminarEncuestaAPI = () => `EncuestaSalud/EliminarEncuesta`;
export const RegistrarEstadoEncuestaSaludAPI = () => `EncuestaSalud/RegistrarEstado`;
export const downloadReporteEncuestaSaludAPI = () => `EncuestaSalud/DownloadReporteEncuestaSalud`;
export const listarFichaSintomatologicaAPI = () => `FichaSintomatologica/Listar`;
export const descargarListadoFichaSintomatologicaAPI = () => `FichaSintomatologica/Descargar`;
export const downloadReporteClimaLaboralAPI = () => `Reportes/DownloadReporteClimaLaboral`;
export const downloadReporteAsignacionFamiliarAPI = () => `Reportes/DownloadReporteAsignacionFamiliar`;
export const downloadReporteBBDDColaboradoresAPI = () => `Reportes/DownloadReporteBBDDColaboradores`;
export const listarGestionFichaDatos = () => `Directorio/ListarGestionFichaDatos`;
export const reasignarActividadAPI = () => `NuevosIngresos/ReasignarActividad`;
export const observarActividadAPI = () => `NuevosIngresos/ObservarActividad`;
export const guardarVacacionesAPI = () => `NuevosIngresos/GuardarVacaciones`;
export const descargarCartaAutorizacionDatosPersonales = () => `Ficha/GenerarCartaAutorizacionDatosPersonales`;
export const guardarCartaAutorizacionFirmada = () => `Ficha/GuardarCartaAutorizacionFirmada`;
export const guardarCartaAprobadoLineaCarreraFirmada = () => 'NuevosIngresos/GuardarCartaAprobadoLineaCarreraFirmada'
export const guardarDocumentoTeletrabajoFirmada = () => 'NuevosIngresos/GuardarDocumentoTeletrabajoFirmada'
export const guardarCompraVacacionesFirmada = () => 'NuevosIngresos/GuardarCompraVacacionesFirmada'
export const guardarCartaCambioPuestoFirmada = () => 'NuevosIngresos/GuardarCartaCambioPuestoFirmada'
export const guardarCartaBonoFirmada = () => 'NuevosIngresos/GuardarCartaBonoFirmada'
export const guardarCartaObjetivoFirmada = () => 'NuevosIngresos/GuardarCartaObjetivoFirmada'
export const guardarCartaObjetivo2022y2023Firmada = () => 'NuevosIngresos/GuardarCartaObjetivo2022y2023Firmada'
export const guardarCartaIncremento2023Firmada = () => 'NuevosIngresos/GuardarCartaIncremento2023Firmada'
export const guardarConvenioBonoTrasladoFirmada = () => 'NuevosIngresos/GuardarConvenioBonoTrasladoFirmada'
export const guardarCartaIncrementoBO2023Firmada = () => 'NuevosIngresos/GuardarCartaIncrementoBO2023Firmada'
export const guardarConvenioCambioFuncionesFirmada = () => 'NuevosIngresos/GuardarConvenioCambioFuncionesFirmada'
export const guardarConvenioCambioPuestoFirmada = () => 'NuevosIngresos/GuardarConvenioCambioPuestoFirmada'
export const guardarLineaCarrera2023Firmada= () => 'NuevosIngresos/GuardarLineaCarrera2023Firmada'
export const guardarCartaIncrementooSede2024Firmada = () => 'NuevosIngresos/GuardarCartaIncrementooSede2024Firmada'
export const guardarConvenioMutuoDisensoFirmada = () => 'NuevosIngresos/GuardarConvenioMutuoDisensoFirmada'
export const guardarPoliticaBonoTotFirmada = () => 'NuevosIngresos/GuardarPoliticaBonoTotFirmada'
export const guardarDocumentoEMOFirmada = () => 'NuevosIngresos/GuardarDocumentoEMOFirmada'
export const guardarRecompensaTotalSede2024Firmada = () => 'NuevosIngresos/GuardarRecompensaTotalSede2024Firmada'
export const guardarRecompensaTotalBO2024Firmada = () => 'NuevosIngresos/GuardarRecompensaTotalBO2024Firmada'
export const guardarDocumentoProrrogaFirmada = () => 'NuevosIngresos/GuardarDocumentoProrrogaFirmada'
export const guardarDocumentoResultadoEvaluacion2024 = () => 'NuevosIngresos/GuardarDocumentoResultadoEvaluacion2024'
export const obtenerCartaAutorizacionFirmada = () => `Ficha/ObtenerCartaAutorizacionFirmada`;
export const aprobarCartaAutorizacionFirmada = () => `Ficha/AprobarCartaAutorizacionFirmada`;
export const listarProcesoCierreAPI = () => `ProcesoCierre/GetAll`;
export const editarColaboradorAPI = () => `ProcesoCierre/SaveEditarColaborador`;
export const agregarColaboradorAPI = () => `ProcesoCierre/AgregarColaborador`;
export const eliminarProcesoCierrColaborador = () => `ProcesoCierre/EliminarColaborador`;
export const bulkEliminarColaboradoresAPI = () => `ProcesoCierre/BulkEliminarColaborador`;
export const procesoCierreIniciarEtapaAPI = (idMonitorProcesoCierre) => `ProcesoCierre/IniciarEtapa/${idMonitorProcesoCierre}`;
export const procesoCierreIniciarGrupoAPI = (idMonitorProcesoCierre) => `ProcesoCierre/IniciarGrupo/${idMonitorProcesoCierre}`;
export const procesoCierreEjecutarEtapaAPI = (idMonitorProcesoCierre) => `ProcesoCierre/EjecutarEtapa/${idMonitorProcesoCierre}`;
export const getAllReservasAPI = () => `Reservas/GetAll`;
export const saveReservaAPI = () => `Reservas/SaveReserva`;
export const getDropDownEmpleadoAPI = () => `Datos/GetDropDownEmpleado`;
export const getTiposCursoAPI = () => `Datos/GetTiposCurso`;
export const getEstadosCursoAPI = () => `Datos/GetEstadosCurso`;
export const guardarReservaTriajeAPI = () => `Reservas/GuardarReservaTriaje`;
export const guardarReservaOcurrenciaAPI = () => `Reservas/GuardarReservaOcurrencia`;
export const confirmarReservaAPI = () => `Reservas/ConfirmarReserva`;
export const cancelarReservaAPI = () => `Reservas/CancelarReserva`;
export const DescargarReservasAPI = () => `Reservas/DescargarReservas`;
export const procesoCierreEjecutarGrupoAPI = (idMonitorProcesoCierre) => `ProcesoCierre/EjecutarGrupo/${idMonitorProcesoCierre}`;
export const procesoCierreReprocesarGrupoAPI = (idMonitorProcesoCierre) => `ProcesoCierre/ReprocesarGrupo/${idMonitorProcesoCierre}`;
export const getDropDownProcesoCierreColaboradorAPI = () => `Datos/GetDropDownProcesoCierreColaborador`;
export const saveEjecutarProcesoRenuevaAPI = () => `ProcesoCierre/SaveEjecutarProcesoRenueva`;
export const saveEjecutarProcesoNoRenuevaAPI = () => `ProcesoCierre/SaveEjecutarProcesoNoRenueva`;
export const downloadFormatoResultadosEvaluaciones = () => `ProcesoCierre/DownloadFormatoResultadosEvaluaciones`;
export const saveCargarResutadosEvaluacion = () => `ProcesoCierre/SaveCargarResutadosEvaluacion`;
export const downloadFormatoCargaMensualAPI = () => `ProcesoCierre/DownloadFormatoCargaMensual`;
export const saveCargaMensualAPI = () => `ProcesoCierre/SaveCargaMensual`;
export const bloquearColaboradorAPI = () => `ProcesoCierre/BloquearColaborador`;
export const desBloquearColaboradorAPI = () => `ProcesoCierre/DesbloquearColaborador`;
export const procesoCierreReprocesarEtapaAPI = (idMonitorProcesoCierre) => `ProcesoCierre/ReprocesarEtapa/${idMonitorProcesoCierre}`;
export const procesoCierreObservarProcesoAPI = () => `ProcesoCierre/ObservarProceso`;
export const procesoCierreReprocesarProcesoAPI = () => `ProcesoCierre/ReprocesarProceso`;
export const saveDocumentosEjecutarProcesoAPI = () => `ProcesoCierre/SaveDocumentosEjecutarProceso`;
export const saveEjecutarProcesoEmpleadoAPI = () => `ProcesoCierre/SaveEjecutarProcesoEmpleado`;
export const descargarPlantilaAPI = () => `ProcesoCierre/DescargarPlantilla`;
export const registrarNotaCierreDeAnioAPI = () => `ProcesoCierre/CrearNota`;
export const getDropDownColaboradorAPI = () => `Datos/GetDropDownColaborador`;
export const descargarColaboradoresAPI = () => 'ProcesoCierre/DescargarColaboradores';
export const saveCargarColaboradoresAPI = () => 'ProcesoCierre/SaveCargarColaboradores';
export const descargarFormatoCargaColaboradoresAPI = () => `ProcesoCierre/DescargarFormatoCargaColaboradores`;
export const puedeMandarRecordatorioAPI = () => `ProcesoCierre/PuedeMandarRecordatorio`;
export const actualizacionMasivaMensajeConfirmacionAPI = () => `ProcesoCierre/ActualizacionMasivaMensajeConfirmacion`;
export const actualizacionMasivaAPI = () => `ProcesoCierre/ActualizacionMasiva`;
export const downloadReporteActividadCoordinarDevolucionAPI = () => `Reportes/DownloadReporteActividadCoordinarDevolucion`;
export const downloadReporteSeguimientoVacacionesAPI = () => `Reportes/DownloadReporteSeguimientoVacaciones`;
export const downloadReporteSeguimientoVacacionesBackOfficeAPI = () => `Reportes/DownloadReporteSeguimientoVacacionesBackOffice`;

export const downloadReporteColaboradoresSedesActivosAPI = () => `Reportes/DownloadReporteColaboradoresSedesActivos`;
export const downloadReporteColaboradoresSedesCesadosAPI = () => `Reportes/DownloadReporteColaboradoresSedesCesados`;
export const enviarReporteNuevosIngresosAPI = () => `Reportes/EnviarReporteNuevosIngresos`;
export const enviarReporteGoldenbeltAPI = () => `Reportes/EnviarReporteGoldenbelt`;
export const procesoCierreConfigGetAllProcesosAPI = () => `ProcesoCierreConfig/GetAllProcesos`;
export const procesoCierreConfigCrearProcesoAPI = () => `ProcesoCierreConfig/CrearProceso`;
export const procesoCierreCrearNuevoGrupoAPI = () => `ProcesoCierreAdmin/CrearGrupo`;
export const procesoCierreEditarGrupoAPI = (grupoId) => `ProcesoCierreAdmin/EditarGrupo/${grupoId}`;
export const procesoCierreConfigEditarProcesoAPI = (idProcesoCierre) => `ProcesoCierreConfig/Editar/${idProcesoCierre}`;
export const procesoCierreConfigEliminarAPI = (idProcesoCierre) => `ProcesoCierreConfig/Eliminar/${idProcesoCierre}`;
export const downloadReporteProcesoCierre = () => `Reportes/DownloadReporteProcesoCierre`;
export const listarPoliticasAPI = () => `PoliticaReglamento/ListarPoliticas`;
export const listarPliticasAdministrador = () => `PoliticaReglamento/ListarAdminPoliticas`;
export const crearNuevaPoliticaAPI = () => `PoliticaReglamento/CrearPolitica`;
export const editarPoliticaAPI = () => `PoliticaReglamento/EditarPolitica`;
export const desactivarPoliticaAPI = (id) => `PoliticaReglamento/InactivarPolitica/${id}`;
export const activarPoliticaAPI = (id) => `PoliticaReglamento/ActivarPolitica/${id}`;
export const eliminarPoliticaAPI = (id) => `PoliticaReglamento/EliminarPolitica/${id}`;
export const descargarInformeLecturaAPI = (id) => `PoliticaReglamento/DescargarInformeLectura/${id}`;
export const descargarReporteCompromisoAPI = () => `PoliticaReglamento/DescargarReporteCompromiso`;
export const guardarLecturaPoliticaAPI = () => `PoliticaReglamento/GuardarCompromisoLectura/`;
export const guardarDescargaArchivoPolitica = (archivoId) => `PoliticaReglamento/GuardarDescargarArchivoPolitica/${archivoId}`; 
export const liquidacionesGetAllAPI = () => `Liquidaciones/GetAll`;
export const liquidacionesCargarColaboradoresAPI = () => `Liquidaciones/CargarColaboradores`;
export const liquidacionesSaveEditarColaboradorAPI = () => `Liquidaciones/SaveEditarColaborador`;
export const liquidacionesAgregarConfiguracion = () => `Liquidaciones/AgregarConfiguracion`;
export const liquidacionesGuardarEditarConfiguracion = () => `Liquidaciones/SaveEditarConfiguracion`;
export const liquidacionesEliminarConfiguracionAPI = () => `Liquidaciones/DeleteConfiguracion`;
export const uploadLiquidacionesFiles = () => `/UploadLiquidacionesFiles`;
export const ejecutarDistribucionAPI = () => `Liquidaciones/ProcesarLiquidacion`;
export const reprocesarLiquidacionAPI = () => `Liquidaciones/ReprocesarLiquidacion`;
export const eliminarColaboradorAPI = () => `Liquidaciones/EliminarColaborador`;
export const testProcesarLiquidacionAPI = () => `Liquidaciones/TestProcesarLiquidacion`;
export const guardarCartaCompromisoVacunacionAPI = () => `NuevosIngresos/GuardarCartaCompromisoVacunacion`;
export const guardarFirmaCompromisoLecturaPoliticaAPI =() => `Actividades/SaveFirmaCompromisoLecturaPoliticas`;
export const guardarCartaIncrementoAPI = () => `Actividades/SaveCartaIncremento`;
export const guardarCartaDeVacunacionAPI = () => `Actividades/SaveCartaCompromisoVacunacion`;
export const RevertirActividadesPendientes = (idEmpleadoFlujoActividad) => `Actividades/RevertirEstadoPendiente/${idEmpleadoFlujoActividad}`;
export const aprobarCartaCompromisoVacunacionAPI = () => `NuevosIngresos/AprobarCartaCompromisoVacunacion/`;
export const rechazarCartaCompromisoVacunacionAPI = () => `NuevosIngresos/RechazarCartaCompromisoVacunacion/`;
export const nuevosIngresosConfirmarPlantillaCartaCompromisoVacunacionIsOkAPI = () => `NuevosIngresos/ConfirmarPlantillaCartaCompromisoVacunacionIsOk/`;
export const exportarGenerarCorreoCorporativoAPI = () => `NuevosIngresos/ExportarGenerarCorreoCorporativo`;
export const descargarReporteLiquidacionesAPI = () => `Liquidaciones/DownloadReport`;
export const enviarFichaSintomatologica = () => `FichaSintomatologica/SaveFichaSintomatologica`;
export const eliminarRegistroFichaSintomatologica = () => `FichaSintomatologica/DeleteFichaSintomatologica`;
export const generarCasoCovidFichaSintomatologica = (idEncuesta) => `FichaSintomatologica/GenerarCasoCovid/${idEncuesta}`;
export const seguimientoColaboradorListarColaboradoresAPI = () => `SeguimientoColaborador/ListarColaboradores`;
export const seguimientoColaboradorDescargarReporteAPI = () => `SeguimientoColaborador/DescargarReporte`;
export const descargarReporteDirectorioAPI = () => `Directorio/DescargarReporteDirectorio`;
export const descargarReporteDirectorioGrupoAPI = () => `Directorio/DescargarReporteDirectorioGrupo`;
export const descargarReporteDirectorioSedeAPI = () => `Directorio/DescargarReporteDirectorioSede`;
export const getPermisoDirectorioPI = () => `Directorio/GetPermisoDirectorio`;
export const cartillaVacunacionDownloadReporteGestionVacunasAPI = () => `CartillaVacunacion/DownloadReporteGestionVacunas`;
export const encuestaSaludRegistrarEstadoAPI = () => `EncuestaSalud/RegistrarEstado`;
export const SeguimientoColaboradorGuardarAtributosAcademicosAPI = () => `SeguimientoColaborador/GuardarAtributosAcademicos`;
export const reportesGetReporteGeneralConfigAPI = () => `Reportes/GetReporteGeneralConfig`;
export const procesoCierreAdminGetAllGruposAPI = () => `ProcesoCierreAdmin/GetAllGrupos`;
export const getTemplatesByProcesoCierreAPI = () => `ProcesoCierreAsignar/GetTemplatesByProcesoCierre`;
export const getTemplatesByProcesoCierrePaginatorAPI = () => `ProcesoCierreAsignar/GetTemplatesByProcesoCierrePaginator`;
export const asignarTemplateToProcesoCierreAPI = () => `ProcesoCierreAsignar/AsignarTemplateToProcesoCierre`;
export const AsignarGrupoByProcesoAPI = () => `ProcesoCierreAsignar/AsignarGrupoByProceso`;
export const setAsPredefinidoCallApiAPI = (idMonitor) => `ProcesoCierreAsignar/SetAsPredefinido/${idMonitor}`;
export const EliminarGrupoAsignadoCallAPI = (idMonitorProcesoCierreGrupo) => `ProcesoCierreAsignar/EliminarGrupoAsignado/${idMonitorProcesoCierreGrupo}`;
export const editarAsignacionTemplateByProcesoCierreAPI = () => `ProcesoCierreAsignar/EditarAsignacionTemplateByProcesoCierre`;
export const GetGruposPredefinidosToAsignAPI = ()=> `ProcesoCierreAsignar/GetGruposPredefinidosToAsign`;
export const eliminarAsignacionTemplateByProcesoCierreAPI = (idProcesoCierrePlantillasConfig) => `ProcesoCierreAsignar/EliminarAsignacionTemplateByProcesoCierre/${idProcesoCierrePlantillasConfig}`;
export const EditarTipoContratoAPI = () => `ProcesoCierreAsignar/EditarTipoContrato`;
export const saveCargarConfiguracionApi360API = () => `ProcesoCierre/SaveCargarConfiguracionApi360`;
export const ActivarInactivarProcesoDeCierreAPI = (idProcesoCierre) => `ProcesoCierreConfig/ActivarInactivarProcesoDeCierre/${idProcesoCierre}`;
export const eliminarPlantillaPersonalizadaEmpleadoArchivoAPI = (id) => `ProcesoCierre/EliminarPlantillaPersonalizadaEmpleadoArchivo/${id}`;
export const agregarTipoInstruccionAPI = () => `Ficha/AgregarTipoInstruccion`;
export const agregarFormacionProfesionalAPI = () => `Ficha/AgregarFormacionProfesional`;
export const saveEditarFormacionProfesionalAPI = () => `Ficha/SaveEditarFormacionProfesional`;
export const eliminarFormacionProfesionalAPI = (id) => `Ficha/EliminarFormacionProfesional/${id}`;
export const agregarExperienciaLaboralAPI = () => `Ficha/AgregarExperienciaLaboral`;
export const editarExperienciaLaboralAPI = () => `Ficha/EditarExperienciaLaboral`;
export const eliminarExperienciaLaboralAPI = (idEmpleadoExperienciaLaboral) => `Ficha/EliminarExperienciaLaboral/${idEmpleadoExperienciaLaboral}`;
export const agregarEmpleadoIdiomaAPI = () => `Ficha/AgregarEmpleadoIdioma`;
export const editarEmpleadoIdiomaAPI = () => `Ficha/EditarEmpleadoIdioma`;
export const editarMayorGradoInstruccionAPI = (idFormacion) => `Ficha/EditarMayorGradoInstruccion/${idFormacion}`;
export const eliminarEmpleadoIdiomaAPI = (idEmpleadoIdioma) => `Ficha/EliminarEmpleadoIdioma/${idEmpleadoIdioma}`;
export const getSugerenciasDeNombreEmpresaParaExperienciaLaboralAPI = () =>`Ficha/GetSugerenciasDeNombreEmpresaParaExperienciaLaboral`;
export const getSugerenciasDeCargosParaExperienciaLaboralAPI = () =>`Ficha/GetSugerenciasDeCargosParaExperienciaLaboral`;
export const getDropDownNombreInstitucionAPI = () => `Datos/GetDropDownNombreInstitucion`;
export const getDropDownNombreCarreraAPI = () => `Datos/GetDropDownNombreCarrera`;
export const getSugerenciasDeNombreInstitucionAPI = () =>`Ficha/GetSugerenciasDeNombreInstitucion`;
export const getSugerenciasDeNombreCarreraAPI = () =>`Ficha/GetSugerenciasDeNombreCarrera`;
export const getSugerenciasDeNombreInstitucionEstudioAdicionalAPI = () =>`Ficha/GetSugerenciasDeNombreInstitucionEstudioAdicional`;
export const saveEncuestaTeletrabajoAPI = () =>`Actividades/SaveEncuestaTeletrabajo`;
export const saveBasicActivityAPI = () =>`Actividades/SaveBasicActivity`;
export const saveTestActivityAPI = () =>`Actividades/SaveTestActivity`;
export const saveEncuestaActivityAPI = () =>`Actividades/SaveEncuestaActivity`;
export const saveCreaCorreoActivityAPI = () =>`Actividades/SaveCreaCorreoActivity`;
export const saveInduccionVirtualActivityAPI = () =>`Actividades/SaveInduccionVirtualActivity`;
export const saveConfirmarAsistenciaActivityAPI = () =>`Actividades/SaveConfirmarAsistrenciaActivity`;
export const getRegistroInvitacionesAPI = () =>`RegistroInvitaciones/GetIndex`;
export const createRegistroInvitacionAPI = () =>`RegistroInvitaciones/CreateRegistroInvitacion`;
export const activarInactivarRegistroInvitacionAPI = (id) =>`RegistroInvitaciones/ActivarInactiarRegistroInvitacion/${id}`;
export const saveEditRegistroInvitacionAPI = () =>`RegistroInvitaciones/SaveEditRegistroInvitacion`;
export const confirmarAccesoDmActivityAPI = () =>`Actividades/ConfirmarAccesoDmActivity`;
export const confirmarAccesoJiraActivityAPI = () =>`Actividades/ConfirmarAccesoJiraActivity`;
export const confirmarIngresoActivityAPI = () =>`Actividades/ConfirmarIngresoActivity`;
export const indicarAmonestacionesActivityAPI = () => `Actividades/IndicarAmonestacionesActivity`;
export const maternidadGuardarCharlaMamaInnovaActivityAPI = () => `Actividades/MaternidadGuardarCharlaMamaInnovaActivity`;
export const cargarHelpDeskAPI = () =>`NuevosIngresos/CargarHelpDesk`;
export const getMisVacacionesAPI = () => `Movimientos/ListarMisVacaciones`;
export const getListarVacacionesDeMiEquipoAPI = () => `Movimientos/ListarVacacionesDeMiEquipo`;
export const getListarVacacionesByJefeMatricialAPI = () => `Movimientos/ListarVacacionesByJefeMatricial`;
export const crearSolicitudVacacionesAPI = () => `Movimientos/CrearSolicitudVacaciones`;
export const crearSolicitudVacacionesAdminAPI = () => `Movimientos/CrearSolicitudVacacionesAdmin`;
export const crearConfiguracionVacacionesAPI = () => `Movimientos/CrearConfiguracion`;
export const getResponsableEmpleadoAPI = () => `Datos/GetResponsableEmpleado`;
export const saveEditarSolicitudVacacionesAPI = () => `Movimientos/SaveEditarSolicitudVacaciones`;
export const activarAnularSolicitudVacacionesAPI = (id) => `Movimientos/ActivarAnularSolicitudVacaciones/${id}`;
export const enviarSolicitudVacacionesAPI = (id) => `Movimientos/EnviarSolicitudVacaciones/${id}`;
export const firmarFormatoVacacionesAPI = () => `Movimientos/FirmarFormato`;
export const aprobarSolicitudVacacionesAPI = () => `Movimientos/AprobarSolicitudVacaciones`;
export const rechazarSolicitudVacacionesAPI = () => `Movimientos/RechazarSolicitudVacaciones`;
export const descargarVacacionesAPI = () => `Movimientos/DescargarVacaciones`;
export const descargarVacacionesByJefeMatricialAPI = () => `Movimientos/DescargarVacacionesByJefeMatricial`;
export const descargarVacacionesDisponiblesAPI = () => `Movimientos/DescargarVacacionesDisponibles`;
export const descargarVacacionesDisponiblesAPIMatricial = () => `Movimientos/DescargarVacacionesDisponiblesMatricial`;
export const descargarVacacionesDisponiblesAPIMatricialReport = () => `Movimientos/DescargarVacacionesDisponiblesMatricialReport`;

export const downloadReporteVacacionesAPI = () => `Reportes/DownloadReporteVacaciones`;
export const eliminarConfiguracionVacacionesAPI = (idConfiguracion) => `Movimientos/EliminarConfiguracion/${idConfiguracion}`;
export const editarConfiguracionVacacionesAPI = () => `Movimientos/EditarConfiguracion`;
export const reasignarSolicitudVacacionesAPI = () => `Movimientos/ReasignarSolicitudVacaciones`;
export const addNotaSolicitudVacacionesAPI = () => `Movimientos/AddNota`;
export const subirFirmaColaboradorAPI = () => `Movimientos/SubirFirmaColaborador`;
export const eliminarFirmaColaboradorAPI = () => `Movimientos/EliminarFirmaColaborador`;
export const listarVacacionesDisponiblesAPI = () => `Movimientos/ListarVacacionesDisponibles`;
export const listarVacacionesDisponiblesAPIMatricial = () => `Movimientos/ListarVacacionesDisponiblesMatricial`
export const cambiarPermitirVacacionesAPI = (idEmpleado) => `Movimientos/CambiarPermitirVacaciones/${idEmpleado}`;
export const eliminarSolicitudVacacionesAPI = (idSolicitudMovimiento) => `Movimientos/EliminarSolicitudVacaciones/${idSolicitudMovimiento}`;
export const eliminarSolicitudVacacionesCrearAPI = (idSolicitudMovimiento) => `Movimientos/EliminarSolicitudVacacionesCrear/${idSolicitudMovimiento}`;
export const crearProcesoCierreSolicitudVacacionesAPI = () => `Movimientos/CrearProcesoCierreSolicitudVacaciones`;
export const vacacionesAdminIndexHistoricoAPI = () => `Movimientos/IndexHistorico`;
export const vacacionesAdminIndexHistoricoAPIMatricial = () => `Movimientos/IndexHistoricoMatriciales`;
export const vacacionesAdminDescargarHistoricoAPI = () => `Movimientos/DescargarHistorico`;
export const vacacionesAdminDescargarHistoricoAPIMatricial = () => `Movimientos/DescargarHistoricoMatricial`;

export const vacacionesColaboradorIndexHistoricoAPI = () => `Movimientos/IndexColaboradorHistorico`;
export const getPeriodoPlanillaByIdSolicitudMovimientoAPI = (idSolicitudMovimiento) => `Movimientos/GetPeriodoPlanilla?idSolicitudMovimiento=${idSolicitudMovimiento}`;
export const saveEditCommandScheduleAPI = () => `Schedule/SaveEditCommand`;
export const enableDisableCommandScheduleAPI = (idCommand) => `Schedule/EnableDisableCommand/${idCommand}`;
export const cleanExecutingCommandScheduleAPI = (idCommand) => `Schedule/CleanExecutingCommand/${idCommand}`;
export const downloadReporteFamiliarAPI = () => `Reportes/DownloadReporteFamiliar`;
export const downloadReporteCierreAnioRenovacionAPI = () => `Reportes/DownloadReporteCierreAnioRenovacion`;
export const downloadReportePeriodoPlanillaAPI = () => `Reportes/ReportePeriodoPlanilla`;
export const enviarReporteSeguimientoNuevosIngresosAPI = () => `Reportes/EnviarReporteSeguimientoNuevosIngresos`;
export const enviarReporteRankmiAPI = () => `Reportes/EnviarReporteRankmi`;
export const enviarReporteDatosHijosTxtsAPI = () => `Reportes/EnviarReporteDatosHijosTxts`;
export const enviarReporteDatosEmpleadoTxtsAPI = () => `Reportes/EnviarReporteDatosEmpleadoTxts`;
export const enviarReporteNuevosIngresosPasosCompletadosAPI = () => `Reportes/EnviarReporteDeNuevosIngresosYpasosCompletados`;
export const eliminarContratoAPI = (idEmpleadoContrato) => `Ficha/EliminarContrato/${idEmpleadoContrato}`;
export const saveEditarContratoAPI = () => `Ficha/SaveEditarContrato`;
export const createArchivosFichaDatosAPI = () => `Ficha/CreateArchivosFichaDatos`;
export const editArchivosFichaDatosAPI = () => `Ficha/EditArchivosFichaDatos`;
export const deleteArchivosFichaDatosAPI = (idEmpleadoArchivoFichaDatos) => `Ficha/DeleteArchivosFichaDatos/${idEmpleadoArchivoFichaDatos}`;
export const getAllTemplatesAPI = () => `Template/GetAllTemplates`;
export const crearTemplateAPI = () => `Template/CrearTemplate`;
export const previsualizarTemplateAPI = () => `Template/PrevisualizarTemplate`;
export const editarTemplateAPI = () => `Template/EditarTemplate`;
export const activarInactivarTemplateAPI = (idTemplate) => `Template/ActivarInactivarTemplate/${idTemplate}`;
export const solicitarAsignacionFamiliarAPI = () => `Ficha/SolicitarAsignacionFamiliar`;
export const asignacionFamiliarAdminIndexAPI = () => `AsignacionFamiliarAdmin/Index`;
export const asignacionFamiliarAdminDescargarExcelAPI = () => `AsignacionFamiliarAdmin/DescargarExcel`;
export const asignacionFamiliarAdminAprobarSolicitudAPI = () => `AsignacionFamiliarAdmin/AprobarSolicitud`;
export const asignacionFamiliarAdminRechazarSolicitudAPI = () => `AsignacionFamiliarAdmin/RechazarSolicitud`;
export const asignacionFamiliarAdminActualizarFechasSolicitudAPI = () => `AsignacionFamiliarAdmin/ActualizarFechasSolicitudAsignacionFamiliar`;
export const getAllEtiquetasAPI = () => `Template/GetAllEtiquetas`;
export const crearEtiquetaAPI = () => `Template/CrearEtiqueta`;
export const editarEtiquetaAPI = () => `Template/EditarEtiqueta`;
export const activarInactivarEtiquetaAPI = (idEtiqueta) => `Template/ActivarInactivarEtiqueta/${idEtiqueta}`;
export const getAllComponentesAPI = () => `Template/GetAllComponentes`;
export const crearComponenteAPI = () => `Template/CrearComponente`;
export const editarComponenteAPI = () => `Template/EditarComponente`;
export const activarDesactivarComponenteAPI = (idComponente) => `Template/ActivarDesactivarComponente/${idComponente}`;
export const getConfiguracionDocumentoIndexAPI = () => `NuevosIngresosAdmin/DocumentosIndex`;
export const crearConfiguracionDocumentoAPI = () => `NuevosIngresosAdmin/CrearDocumento`;
export const saveEditarConfiguracionDocumentoAPI = () => `NuevosIngresosAdmin/SaveEditarDocumento`;
export const activarInactivarConfiguracionDocumentoAPI = (idNuevosIngresosDocumentos) => `NuevosIngresosAdmin/ActivarInactivarDocumento/${idNuevosIngresosDocumentos}`;
export const getConfiguracionListaDocumentosIndexAPI = () => `NuevosIngresosAdmin/ListaDocumentosIndex`;
export const crearConfiguracionListaDocumentosAPI = () => `NuevosIngresosAdmin/CrearLista`;
export const saveEditarConfiguracionListaDocumentosAPI = () => `NuevosIngresosAdmin/SaveEditarLista`;
export const activarInactivarConfiguracionListaDocumentosAPI = (idNuevosIngresosLista) => `NuevosIngresosAdmin/ActivarInactivarLista/${idNuevosIngresosLista}`;
export const getPlantillasAPI = () => `NuevosIngresosAdmin/GetPlantillas`;
export const getNuevosIngresosIndexAPI = () => `NuevosIngresosAdmin/NuevosIngresosIndex`;
export const crearNuevoIngresoAPI = () => `NuevosIngresosAdmin/CrearNuevoIngreso`;
export const validarDocumentoEmpleadoAPI = (idNuevosIngresosEmpleadoDocumento) => `NuevosIngresosAdmin/ValidarDocumentoEmpleado/${idNuevosIngresosEmpleadoDocumento}`;
export const cambiarPasoObligatorioDocumentoEmpleadoAPI = () => `NuevosIngresosAdmin/CambiarPasoObligatorioDocumentoEmpleado`;
export const eliminarDocumentoEmpleadoAPI = (idNuevosIngresosEmpleadoDocumento) => `NuevosIngresosAdmin/EliminarDocumentoEmpleado/${idNuevosIngresosEmpleadoDocumento}`;
export const cargarGenerarCorreoCorporativoAPI = () => `NuevosIngresos/CargarGenerarCorreoCorporativo`;
export const guardarNuevosIngresosDocumentoObservadoAPI = () => `NuevosIngresos/GuardarNuevosIngresosDocumentoObservado`;
export const cargarTRegistrosNuevosIngresosAPI = () => `NuevosIngresos/CargarTRegistros`;
export const cargarTRegistroFirmadoNuevosIngresosAPI = () => `NuevosIngresos/CargarTRegistroFirmado`;
export const cargarDocumentoEmpleadoAPI = () => `NuevosIngresosAdmin/CargarDocumentoEmpleado`;
export const saveDatosIdentificacionAPI = () => `NuevosIngresosAdmin/SaveDatosIdentificacion`;
export const saveDatosNacimientoAPI = () => `NuevosIngresosAdmin/SaveDatosNacimiento`;
export const saveInformacionResidenciaAPI = () => `NuevosIngresosAdmin/SaveInformacionResidencia`;
export const saveFormacionAcademicaAPI = () => `NuevosIngresosAdmin/SaveFormacionAcademica`;
export const saveDatosEmpresaAPI = () => `NuevosIngresosAdmin/SaveDatosEmpresa`;
export const saveInformacionEconomicaAPI = () => `NuevosIngresosAdmin/SaveInformacionEconomica`;
export const saveDatosNuevoIngresoAPI = () => `NuevosIngresosAdmin/SaveDatosNuevoIngreso`;
export const reenviarTokenNuevoIngresoAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/ReenviarToken/${idNuevosIngresosEmpleado}`;
export const observarNuevoIngresoAPI = () => `NuevosIngresosAdmin/Observar`;
export const validarNuevoIngresoAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/Validar/${idNuevosIngresosEmpleado}`;
export const desistirNuevoIngresoAPI = () => `NuevosIngresosAdmin/Desistir`;
export const enviarDocumentosAprobacionNuevoIngresoAPI = (idNuevosIngresosEmpleado) => `NuevosIngresosAdmin/EnviarDocumentosAprobacion/${idNuevosIngresosEmpleado}`;
export const descargarDocumentoEmpleadoFromPlantillaAPI = (idNuevosIngresosEmpleadoDocumento) => `NuevosIngresosAdmin/DescargarDocumentoEmpleadoFromPlantilla/${idNuevosIngresosEmpleadoDocumento}`;
export const firmarDocumentoEmpleadoAPI = (idNuevosIngresosEmpleadoDocumento) => `NuevosIngresosAdmin/FirmarDocumentoEmpleado/${idNuevosIngresosEmpleadoDocumento}`;
export const subirFirmaDocumentosIngresoAPI = () => `NuevosIngresosAdmin/SubirFirma`;
export const eliminarFirmaDocumentosIngresoAPI = () => `NuevosIngresosAdmin/EliminarFirma`;
export const descargarExcelNuevosIngresosAPI = () => `NuevosIngresosAdmin/DescargarExcel`;
export const descargarExcelOfisisNuevosIngresosAPI = () => `NuevosIngresosAdmin/DescargarExcelOfisis`;
export const descargarExcelSoporteNuevosIngresosAPI = () => `NuevosIngresosAdmin/DescargarExcelSoporte`;
export const descargarFormatoCargaNuevosIngresosAPI = () => `NuevosIngresosAdmin/DescargarFormatoCarga`;
export const cargarNuevosIngresosAPI = () => `NuevosIngresosAdmin/CargarNuevosIngresos`;
export const descargarFormatoEmpleadosSinCodigoOfisisAPI = () => `NuevosIngresosAdmin/DescargarFormatoEmpleadosSinCodigoOfisis`;
export const cargarFormatoEmpleadosSinCodigoOfisisAPI = () => `NuevosIngresosAdmin/CargarFormatoEmpleadosSinCodigoOfisis`;
export const updateListaDocumentosEmpleadoNuevosIngresosAPI = () => `NuevosIngresosAdmin/UpdateListaDocumentosEmpleado`;
export const reemplazarDocumentoEmpleadoNuevosIngresosAPI = () => `NuevosIngresosAdmin/ReemplazarDocumentoEmpleado`;
export const observarDocumentoEmpleadoNuevosIngresosAPI = (idNuevosIngresosEmpleadoDocumento) => `NuevosIngresosAdmin/ObservarDocumentoEmpleado/${idNuevosIngresosEmpleadoDocumento}`;
export const activarUsuarioEmpleadoNuevosIngresosAdminAPI = (idEmpleado) => `NuevosIngresosAdmin/ActivarUsuarioEmpleado/${idEmpleado}`;
export const ejecutarActividadInicioLaboresOnboardingAPI = (idEmpleado) => `NuevosIngresosAdmin/EjecutarActividadInicioLaboresOnboarding/${idEmpleado}`;
export const generarActividadActualizarCuentaCorreoOnboardingAPI = (idEmpleado) => `NuevosIngresosAdmin/GenerarActividadActualizarCuentaCorreoOnboarding/${idEmpleado}`;
export const enviarNotificacionEncuestaDocentesNuevosAPI = (idEmpleado) => `NuevosIngresosAdmin/EnviarNotificacionEncuestaDocentesNuevos/${idEmpleado}`;
export const asignarEquipoNuevosIngresosAdminAPI = () => `NuevosIngresosAdmin/AsignarEquipo`;
export const guardarDatosFamiliaresNuevosIngresosAdminAPI = () => `NuevosIngresosAdmin/GuardarDatosFamiliares`;
export const validarDatosFamiliaresNuevosIngresosAdminAPI = () => `NuevosIngresosAdmin/ValidarDatosFamiliares`;
export const reasignarRequerimientoNuevosIngresosAdminAPI = () => `NuevosIngresosAdmin/ReasignarRequerimiento`;
export const reprogramarNuevoIngreso = () => `NuevosIngresosAdmin/ReprogramarNuevoIngreso`;
export const puedeCrearConfigurarGestionRequerimientoAPI = () => `GestionRequerimiento/PuedeCrearConfigurar`;
export const perfilPuestoIndexGestionRequerimientoAPI = () => `GestionRequerimiento/PerfilPuestoIndex`;
export const activarIncativarPerfilPuestoAPI = (idPerfilPuesto) => `GestionRequerimiento/ActivarIncativarPerfilPuesto/${idPerfilPuesto}`;
export const crearPerfilPuestoAPI = () => `GestionRequerimiento/CrearPerfilPuesto`;
export const saveEditPerfilPuestoAPI = () => `GestionRequerimiento/SaveEditPerfilPuesto`;
export const presupuestoIndexGestionRequerimientoAPI = () => `GestionRequerimiento/PresupuestoIndex`;
export const activarIncativarPresupuestoAPI = (idPresupuesto) => `GestionRequerimiento/ActivarIncativarPresupuesto/${idPresupuesto}`;
export const crearPresupuestoAPI = () => `GestionRequerimiento/CrearPresupuesto`;
export const saveEditPresupuestoAPI = () => `GestionRequerimiento/SaveEditPresupuesto`;
export const requerimientoPersonalIndexAPI = () => `GestionRequerimiento/RequerimientoPersonalIndex`;
export const crearRequerimientoPersonalAPI = () => `GestionRequerimiento/CrearRequerimientoPersonal`;
export const saveEditRequerimientoPersonalAPI = () => `GestionRequerimiento/SaveEditRequerimientoPersonal`;
export const anularRequerimientoPersonalAPI = () => `GestionRequerimiento/AnularRequerimientoPersonal`;
export const standByRequerimientoPersonalAPI = () => `GestionRequerimiento/StandByRequerimientoPersonal`;
export const rechazarRequerimientoPersonalAPI = () => `GestionRequerimiento/RechazarRequerimientoPersonal`;
export const abrirRequerimientoPersonalAPI = (idRequerimientoPersonal) => `GestionRequerimiento/AbrirRequerimientoPersonal/${idRequerimientoPersonal}`;
export const aprobarRequerimientoPersonalAPI = (idRequerimientoPersonal) => `GestionRequerimiento/AprobarRequerimientoPersonal/${idRequerimientoPersonal}`;
export const exportarRequerimientoPersonalAPI = () => `GestionRequerimiento/ExportarRequerimientoPersonal`;
export const completarRequerimientoPersonalInternoAPI = () => `GestionRequerimiento/CompletarRequerimientoPersonalInterno`;
export const crearPerfilPuestoByRequerimientoAPI = () => `GestionRequerimiento/CrearPerfilPuestoByRequerimiento`;
export const descargarRequerimientosToCreateNuevosIngresosAPI = () => `GestionRequerimiento/DescargarRequerimientosToCreateNuevosIngresos`;
export const cargarNuevosIngresosFromRequerimientosAPI = () => `GestionRequerimiento/CargarNuevosIngresosFromRequerimientos`;
export const toggleEsControlRequerimientosAPI = () => `GestionRequerimiento/ToggleEsControl`;
export const getReemplazoByFiltersRequerimientosAPI = () => `GestionRequerimiento/GetReemplazoByFilters`;
export const convocatoriaTrasladoIndexAPI = () => `Traslados/ConvocatoriaTrasladoIndex`;
export const getRequerimientosToTrasladosAPI = () => `Traslados/GetRequerimientosToTraslados`;
export const crearConvocatoriaTrasladoAPI = () => `Traslados/CrearConvocatoriaTraslado`;
export const saveEditarConvocatoriaTrasladoAPI = () => `Traslados/SaveEditarConvocatoriaTraslado`;
export const publicarConvocatoriaTrasladoAPI = (idConvocatoriaTraslado) => `Traslados/PublicarConvocatoriaTraslado/${idConvocatoriaTraslado}`;
export const anularConvocatoriaTrasladoAPI = (idConvocatoriaTraslado) => `Traslados/AnularConvocatoriaTraslado/${idConvocatoriaTraslado}`;
export const postulantesTrasladoIndexAPI = () => `Traslados/PostulantesTrasladoIndex`;
export const calificarConvocatoriaTrasladoEmpleadoAPI = (idConvocatoriaTrasladoEmpleado) => `Traslados/CalificarConvocatoriaTrasladoEmpleado/${idConvocatoriaTrasladoEmpleado}`;
export const calificarConvocatoriasTrasladoEmpleadoAPI = () => `Traslados/CalificarConvocatoriasTrasladoEmpleado`;
export const seleccionarConvocatoriaTrasladoEmpleadoAPI = () => `Traslados/SeleccionarConvocatoriaTrasladoEmpleado`;
export const enviarCartaConvocatoriaTrasladoEmpleadoAPI = (idConvocatoriaTrasladoEmpleado) => `Traslados/EnviarCartaConvocatoriaTrasladoEmpleado/${idConvocatoriaTrasladoEmpleado}`;
export const aceptarPropuestaConvocatoriaTrasladoEmpleadoAPI = () => `Traslados/AceptarPropuestaConvocatoriaTrasladoEmpleado`;
export const rechazarPropuestaConvocatoriaTrasladoEmpleadoAPI = (idConvocatoriaTrasladoEmpleado) => `Traslados/RechazarPropuestaConvocatoriaTrasladoEmpleado/${idConvocatoriaTrasladoEmpleado}`;
export const downloadPlantillaSuspensionesTrasladosAPI = (idConvocatoriaTraslado) => `Traslados/DownloadPlantillaSuspensiones/${idConvocatoriaTraslado}`;
export const cargarSuspensionesTrasladosAPI = () => `Traslados/CargarSuspensiones`;
export const generarActividadPendienteDeAmonestacionesAPI = (idConvocatoriaTrasladoEmpleado) => `Traslados/GenerarActividadPendienteDeAmonestaciones/${idConvocatoriaTrasladoEmpleado}`;
export const convocatoriasVigentesIndexTrasladosAPI = () => `Traslados/ConvocatoriasVigentesIndex`;
export const misPostulacionesIndexTrasladosAPI = () => `Traslados/MisPostulacionesIndex`;
export const crearConvocatoriaTrasladoEmpleadoAPI = () => `Traslados/CrearConvocatoriaTrasladoEmpleado`;
export const createSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/CreateSolicitudDescuentoPension`;
export const listarSolicitudesDescuentoPensionAPI = () => `SolicitudDescuentoPension/ListarSolicitudesDescuentoPension`;
export const rechazarSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/RechazarSolicitudDescuentoPension`;
export const aprobarSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/AprobarSolicitudDescuentoPension`;
export const inactivarActivarSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/InactivarActivarSolicitudDescuentoPension`;
export const getHijosPorIdSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/GetHijosPorIdSolicitudDescuentoPension`;
export const saveProcesoSolicitudDescuentoPensionAPI = () => `SolicitudDescuentoPension/SaveProcesoSolicitudDescuentoPension`;
export const solicitudDescuentoPensionDescargarExcelAPI = () => `SolicitudDescuentoPension/DescargarExcel`;
export const crearProcesoSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/CrearProcesoSolicitudTaiLoy`;
export const editarProcesoSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/EditarProcesoSolicitudTaiLoy`;
export const aprobarSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/AprobarSolicitudTaiLoy`;
export const rechazarSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/RechazarSolicitudTaiLoy`;
export const inactivarActivarSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/InactivarActivarSolicitudTaiLoy`;
export const crearValeTaiLoyAPI = () => `SolicitudTaiLoy/CrearValeTaiLoy`;
export const editarValeTaiLoyAPI = () => `SolicitudTaiLoy/EditarValeTaiLoy`;
export const eliminarValeTaiLoyAPI = () => `SolicitudTaiLoy/EliminarValeTaiLoy`;
export const crearConfiguracionProcesoTaiLoyAPI = () => `SolicitudTaiLoy/CrearConfiguracionProcesoTaiLoy`;
export const editarConfiguracionProcesoTaiLoyAPI = () => `SolicitudTaiLoy/EditarConfiguracionProcesoTaiLoy`;
export const eliminarConfiguracionProcesoTaiLoyAPI = () => `SolicitudTaiLoy/EliminarConfiguracionProcesoTaiLoy`;
export const createSolicitudTaiLoyAPI = () => `SolicitudTaiLoy/CreateSolicitudTaiLoy`;
export const listarSolicitudesTaiLoyAPI = () => `SolicitudTaiLoy/ListarSolicitudesTaiLoy`;
export const generarFormatoAutorizacionDescuentoPlantillaAPI = () => `SolicitudTaiLoy/GenerarFormatoAutorizacionDescuentoPlantilla`;
export const solicitudTaiLoyDescargarExcelAPI = () => `SolicitudTaiLoy/DescargarExcel`;
export const listarPlantillasCuponCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/ListarPlantillasCupon`;
export const crearPlantillaCuponCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/CrearPlantillaCupon`;
export const editarPlantillaCuponCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/EditarPlantillaCupon`;
export const inactivarActivarPlantillaCuponCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/InactivarActivarPlantillaCupon`;
export const listarCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/ListarCuponeraTiempoLibre`;
export const crearCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/CrearCuponeraTiempoLibre`;
export const editarCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/EditarCuponeraTiempoLibre`;
export const inactivarActivarCuponeraTiempoLibreAPI = () => `CuponeraTiempoLibre/InactivarActivarCuponeraTiempoLibre`;
export const getPlantillasCuponParaAsignarAPI = () => `CuponeraTiempoLibre/GetPlantillasCuponParaAsignar`;
export const getCuponesByIdCuponeraAPI = () => `CuponeraTiempoLibre/GetCuponesByIdCuponera`;
export const crearCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/CrearCuponTiempoLibre`;
export const inactivarActivarCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/InactivarActivarCuponTiempoLibre`;
export const updateCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/UpdateCuponTiempoLibre`;
export const listarCuponeraTiempoLibreColaboradorAPI = () => `CuponeraTiempoLibre/ListarCuponeraTiempoLibreColaborador`;
export const listarMisSolicitudesCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/ListarMisSolicitudesTiempoLibre`;
export const getCuponesDisponiblesByIdEmpleadoAPI = () => `CuponeraTiempoLibre/GetCuponesDisponiblesByIdEmpleado`;
export const crearSolicitudCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/CrearSolicitudCuponTiempoLibre`;
export const listarSolicitudesCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/ListarSolicitudesTiempoLibre`;
export const listarSolicitudesCuponTiempoLibreDescargarExcelAPI = () => `CuponeraTiempoLibre/DescargarExcel`;
export const aprobarSolicitudCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/AprobarSolicitudCuponTiempoLibre`;
export const rechazarSolicitudCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/RechazarSolicitudCuponTiempoLibre`;
export const reasignarAprobadorSolicitudCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/ReasignarAprobadorDeSolicitud`;
export const eliminarCuponTiempoLibreAPI = (idCuponTiempoLibre) => `CuponeraTiempoLibre/EliminarCuponTiempoLibre/${idCuponTiempoLibre}`;
export const inactivarActivarSolicitudCuponTiempoLibreAPI = () => `CuponeraTiempoLibre/InactivarActivarSolicitudCuponTiempoLibre`;
export const subirSustentoSolicitudCuponAPI = () => `CuponeraTiempoLibre/SubirSustentoSolicitudCupon`;
export const chatbotFaqAPI = () => `ChatbotFaq/GetChilds`;
export const chatbotFaqAdminAPI = () => `ChatbotFaq/GetChildsAdmin`;
export const listarChatbotFaqAdminAPI = () => `ChatbotFaq/ListarChatbotFaq`;
export const crearChatbotFaqAdminAPI = () => `ChatbotFaq/CrearChatbotFaq`;
export const activarInactivarChatbotFaqAPI = () => `ChatbotFaq/ActivarInactivarChatbotFaq`;
export const editarChatbotFaqAdminAPI = () => `ChatbotFaq/EditarChatbotFaq`;
export const eliminarChatbotFaqAdminAPI = (idChatbotFaq) => `ChatbotFaq/EliminarChatbotFaq/${idChatbotFaq}`;
export const descargarListarAdminChatbotFaqAPI = () => `ChatbotFaq/DescargarListar`;
export const descargarEstadisticaAdminChatbotFaqAPI = () => `ChatbotFaq/DescargarEstadistica`;
export const registraInicioSesionChatbotFaqAPI = () => `ChatbotFaq/RegistraInicioSesion`;
export const registrarCierreSesionChatbotFapAPI = () => `ChatbotFaq/RegistrarCierreSesion`;
export const registrarRespuestaLeidaChatbotFaqAPI = () => `ChatbotFaq/RegistrarRespuestaLeida`;
export const registrarUrlVisitadaChatbotFaqAPI = () => `ChatbotFaq/RegistrarUrlVisitada`;
export const gestionPopupIndexAPI = () => `Popup/GestionPopupIndex`;
export const crearPopupAPI = () => `Popup/CrearPopup`;
export const editarPopupAPI = () => `Popup/EditarPopup`;
export const activarInactivarPopupAPI = () => `Popup/ActivarInactivarPopup`;
export const noMostrarPopupAPI = (idPopup) => `Popup/NoMostrarPopup/${idPopup}`;
export const getListaCondicionAPI = () => `Condicion/GetListaCondicion`;
export const getListaMisCondicionesAPI = () => `Condicion/GetListaMisCondiciones`;
export const descargarExcelMaternidadAPI = () => `Condicion/DescargarExcel`;
export const registrarCondicionAPI = () => `Condicion/RegistrarCondicion`;
export const editarCondicionAPI = () => `Condicion/EditarCondicion`;
export const aprobarCondicionAPI = () => `Condicion/AprobarCondicion`;
export const rechazarCondicionAPI = () => `Condicion/RechazarCondicion`;
export const observarCondicionAPI = () => `Condicion/ObservarCondicion`;
export const completarSeguimientoGestacionAPI = () => `Condicion/CompletarSeguimientoGestacion`;
export const registrarTerminoCondicionAPI = () => `Condicion/RegistrarTerminoCondicion`;
export const toggleValidarFechasPrePostNatalAPI = () => `Condicion/ToggleValidarFechasPrePostNatal`;
export const getCondicionToEditAPI = () => `Condicion/GetCondicionToEdit`;
export const guardarPrePostNatalAPI = () => `Condicion/GuardarPrePostNatal`;
export const guardarHorarioLactanciaAPI = () => `Condicion/GuardarHorarioLactancia`;
export const getDataParaGuardarCharlaMamaInnovaAPI = () => `Condicion/GetDataParaGuardarCharlaMamaInnova`;
export const listarAsistenciaLactarioAPI = () => `Condicion/ListarAsistenciaLactario`;
export const registrarAsistenciaLactarioAPI = () => `Condicion/RegistrarAsistenciaLactario`;
export const asistenciaLactarioGetAllPeriodosRegistradosAPI = () => `Condicion/AsistenciaLactarioGetAllPeriodosRegistrados`;
export const eliminarAsistenciaLactarioAPI = (idAsistenciaLactario) => `Condicion/EliminarAsistenciaLactario/${idAsistenciaLactario}`;
export const getListaDocumentosCondicionAPI = () => `Condicion/GetListaDocumentos`;
export const enviarReporteTxtONPAPI = () => `Reportes/EnviarReporteTxtOnp`;
export const crearSolicitudCambioSueldo = () => `SolicitudCambioCuentaSueldo/CrearSolicitudCambioCuentaSueldo`
export const getSolicitudCambioCuentaSueldoByIdEmpleado = (idEmpleado) => `SolicitudCambioCuentaSueldo/GetSolicitudPendienteCambioCuentaSueldoByIdEmpleado/${idEmpleado}`
export const cancelarSolicitudCambioSueldo = () => `SolicitudCambioCuentaSueldo/CancelarSolicitudCambioCuentaSueldo`
export const listarSolicitudesCambioCuentaSueldo = () => `SolicitudCambioCuentaSueldo/ListarSolicitudes`
export const aprobarSolicitudCambioSueldo = () => `SolicitudCambioCuentaSueldo/AprobarSolicitud`
export const rechazarSolicitudCambioSueldo = () => `SolicitudCambioCuentaSueldo/RechazarSolicitud`
export const exportarSolicitudesCambioSueldoAprobadas = () => `SolicitudCambioCuentaSueldo/ExportarTxtSolicitudesAprobadas`