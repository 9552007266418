import React, { useEffect, useState } from "react";
import ModalForm from "../../../components/modalComponents/ModalForm";
import {
  getInfoFlujoCanjeDMCittAPI,
  validarDocumentoTramiteEssaludAPI,
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import InputUploadFile from "../../../components/modalComponents/InputUploadFile";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import ObservarDocumentoTramiteEssalud from "./ObservarDocumentoTramiteEssalud";
import ModalEnabled from "../../../components/modalComponents/ModalEnabled";

const ValidarDocumentoTramiteEssalud = ({
  showModal,
  IdEmpleadoFlujoActividad,
  IdEmpleadoFlujo,
  manageSearch,
}) => {
  const [modalEnabled, setModalEnabled] = useState({
    isEnable: false,
    component: React.Fragment,
  });
  const SECTION_NAME = "ValidarDocumentoTramiteEssalud";
  const KEYS = {
    documentoTramiteEssaludFileViewModel:
      "DocumentoTramiteEssaludFileViewModel",
    idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad,
  });
  const [manageGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const init = async function () {
    setIsLoading(true);
    if (IdEmpleadoFlujo) {
      await manageGetRequest(
        getInfoFlujoCanjeDMCittAPI(IdEmpleadoFlujo),
        callbackSuccess,
        false
      );
    }
    setIsLoading(false);
  };

  const callbackSuccess = function (response) {
    setFormValues({
      ...formValues,
      [KEYS.documentoTramiteEssaludFileViewModel]:
        response.data[KEYS.documentoTramiteEssaludFileViewModel],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = validarDocumentoTramiteEssaludAPI();
    try {
      await submitForm(
        Object.values(KEYS),
        api,
        formValues,
        SECTION_NAME,
        successSubmitCallback
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  };

  const observar = (
    <button
      className="btn btn-outline-primary generic-button-ficha-outlined"
      onClick={(e) => {
        e.preventDefault();
        setModalEnabled({
          isEnable: true,
          component: ObservarDocumentoTramiteEssalud,
          data: {
            IdEmpleadoFlujoActividad,
            IdEmpleadoFlujo,
            manageSearch,
            showModal,
          },
        });
      }}>
      Observar
    </button>
  );

  return (
    <>
      <ModalEnabled
        modalEnabled={modalEnabled}
        setModalEnabled={setModalEnabled}
        refreshPage={manageSearch}
      />
      <ModalForm
        titulo="Validar sustento de trámite EsSalud"
        width="600px"
        showModal={showModal}
        isSubmitting={isLoading}
        saveButtonDisabled={isLoading}
        onSubmit={handleSubmit}
        label="Validar"
        footerExtraButtons={observar}>
        <div className="col-md-12">
          <InputUploadFile
            label="Sustento"
            name={KEYS.documentoTramiteEssaludFileViewModel}
            sectionName={SECTION_NAME}
            uploadFile={formValues[KEYS.documentoTramiteEssaludFileViewModel]}
            isDisabled={true}
          />
        </div>
      </ModalForm>
    </>
  );
};

export default ValidarDocumentoTramiteEssalud;
