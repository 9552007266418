import React from "react";
import { DivInput } from "../atoms/DivInput";

const InputTextArea = ({
  label,
  name,
  value,
  onChange,
  sectionName,
  isRequired,
  isDisabled,
  colLabel = 4,
  colInput = 8,
  placeholder = `Ingresar ${label}`,
  className = "form-control",
  direction = "row",
  rows = 2,
  maxLength = null,
  ...props
}) => {
  let caracteresRestantes = maxLength - (value?.length ?? 0);
  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color`}>
        <textarea 
          placeholder={placeholder}
          className={className} 
          name={name}
          onChange={onChange}
          value={value ?? ""}
          disabled={isDisabled}
          rows={rows}
          {...props}
        />
        {maxLength && (
          <small
            className="ficha-form-input-max-lenght"
            id={`ficha-edit-${sectionName}-${name}-max-lenght`}>
            {Math.max(caracteresRestantes, 0)} caracteres restantes
          </small>
        )}
        {sectionName && name && (
          <p
            className="ficha-form-input-validation-message"
            id={`ficha-edit-${sectionName}-${name}-validation-message`}
          ></p>
        )}
      </div>
    </DivInput>
  );
};

export default InputTextArea;
