import React, { useEffect, useState } from "react";
import ModalForm from "../../../components/modalComponents/ModalForm";
import {
  getInfoFlujoCanjeDMCittAPI,
  subirDocumentoDMCittAPI,
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import InputUploadFile from "../../../components/modalComponents/InputUploadFile";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { EXTENSION_PDF } from "../../../constants/hardCodedConstants";

const SubirDocumentoDMCITT = ({
  showModal,
  IdEmpleadoFlujoActividad,
  IdEmpleadoFlujo,
  manageSearch,
}) => {
  const SECTION_NAME = "SubirDocumentoDMCITT";
  const KEYS = {
    documentoDMCittFileViewModel: "DocumentoDMCittFileViewModel",
    idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad,
  });
  const [manageGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const init = async function () {
    setIsLoading(true);
    if (IdEmpleadoFlujo) {
      await manageGetRequest(
        getInfoFlujoCanjeDMCittAPI(IdEmpleadoFlujo),
        callbackSuccess,
        false
      );
    }
    setIsLoading(false);
  };

  const callbackSuccess = function (response) {
    setFormValues({
      ...formValues,
      [KEYS.documentoDMCittFileViewModel]:
        response.data[KEYS.documentoDMCittFileViewModel],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = subirDocumentoDMCittAPI();
    try {
      await submitForm(
        Object.values(KEYS),
        api,
        formValues,
        SECTION_NAME,
        successSubmitCallback
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  };

  return (
    <ModalForm
      titulo="Subir Documento CITT emitido por Essalud"
      width="600px"
      showModal={showModal}
      isSubmitting={isLoading}
      saveButtonDisabled={isLoading}
      onSubmit={handleSubmit}>
      <div className="col-md-12">
        <InputUploadFile
          label="Documento CITT emitido por Essalud"
          name={KEYS.documentoDMCittFileViewModel}
          sectionName={SECTION_NAME}
          uploadFile={formValues[KEYS.documentoDMCittFileViewModel]}
          setIsUploading={setIsLoading}
          isRequired={true}
          validExtensions={EXTENSION_PDF}
          setUploadFile={(res) =>
            setFormValues({
              ...formValues,
              [KEYS.documentoDMCittFileViewModel]: res,
            })
          }
        />
      </div>
    </ModalForm>
  );
};

export default SubirDocumentoDMCITT;
