import React from 'react'
import { Link } from 'react-router-dom'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ActionGenericModal from '../../components/modal/ActionGenericModal'
import { anularConvocatoriaTrasladoAPI, publicarConvocatoriaTrasladoAPI } from '../../consumers/backendApisUrls'
import EditarTraslado from './modales/EditarTraslado'

const TableWebBody = ({ filas, setModalEnabled }) => {

  return (
    <tbody className='listar-tbody'>
      {filas?.map((fila, index) =>(
        <tr key={index}>
          <td className='pl-3'>{fila.NombreConvocatoria}</td>
          <td className='text-center'>{fila.Estado}</td>
          <td className='text-center'>{fila.PeriodoVigencia}</td>
          <td className='text-center'>{fila.CantidadPostulantes}</td>
          <td className='text-center'>{fila.CantidadVacantes}</td>
          <td className='text-center'>{fila.VacantesCubiertas}</td>
          <td className='text-center'>{fila.Avance}</td>
          <td>
            <div className='d-flex justify-content-center blue-standard-color' style={{gap: "15px"}}>
              {fila.PuedeEditar &&
                <FontAwesomeIcon 
                  icon='edit'
                  size='lg'
                  title='Ver campaña'
                  onClick={() => setModalEnabled({isEnable: true, component: EditarTraslado, data: { id: fila.IdConvocatoriaTraslado }})}
                />
              }
              <Link to={`/adminTraslados/PostulantesCampaña/${fila.IdConvocatoriaTraslado}`}>
                <FontAwesomeIcon
                  className='blue-standard-color'
                  icon={faUserFriends}
                  size='lg'
                  title='Ver postulantes'
                  />
              </Link>
              {fila.PuedeAnular &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: ActionGenericModal, data: {title: "Anular Traslado", actionLabel: "Anular", api: anularConvocatoriaTrasladoAPI, id: fila.IdConvocatoriaTraslado, name: fila.NombreConvocatoria}})}>
                  Anular
                </button>
              }
              {fila.PuedePublicar &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: ActionGenericModal, data: {title: "Publicar Traslado", actionLabel: "Publicar", api: publicarConvocatoriaTrasladoAPI, id: fila.IdConvocatoriaTraslado, name: fila.NombreConvocatoria}})}>
                  Publicar
                </button>
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody