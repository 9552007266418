import React, { useEffect, useState } from 'react'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useIndexValues } from '../../../hooks/useIndexValues/useIndexValues';
import SearchContainer from '../../../components/pageComponents/SearchContainer';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import PageWrapper from '../../../components/pageComponents/PageWrapper';
import PageTable from '../../../components/pageComponents/PageTable';
import DetallePostularConvocatoria from '../modales/DetallePostularConvocatoria';
import { convocatoriasVigentesIndexTrasladosAPI } from '../../../consumers/backendApisUrls';

const Convocatorias = ({ setModalEnabled }) => {

  const ID_TAB = "Convocatorias_Opciones";

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [isOrdenEsDescendente, setIsOrdenEsDescendente] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);

  const [executePost] = useManagePostRequest();
  const [searchIndex] = useIndexValues(setFormValues, setPaginator, setExtraData);

  const init = () => {
    try {
      manageSearch();
    } catch (error) {
      console.error(error);
    } finally{
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = convocatoriasVigentesIndexTrasladosAPI();
    const payload = getPayload();
    await executePost(api, payload, searchIndex);
  }

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      OrdenEsDescendente: isOrdenEsDescendente
    };
  }

  useEffect(() => {
    init();
  }, [])

  return {
    manageSearchConvocatoria: manageSearch,
    renderConvocatoria: (
      <div id={`accordion-${ID_TAB}`} style={{marginBottom: "180px"}}>
        <div className='card p-0'>
          <PageWrapper isLoading={isLoading}>
            <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} />
            <PageTable headers={tableHeaders} paginator={paginator} setPaginator={setPaginator}>
              <TableWebBody rows={formValues} setModalEnabled={setModalEnabled}/>
            </PageTable>
          </PageWrapper>
        </div>
      </div>
    )
  }
}

export default Convocatorias;

const tableHeaders = [
  {label: "Nombre Convocatoria Traslado", col: "col-sm-4"},
  {label: "Fecha publicación", col: "col-sm-3"},
  {label: "Fecha plazo", col: "col-sm-3"},
  {label: "Acciones", col: "col-sm-2"}
]

const TableWebBody = ({ rows, setModalEnabled }) => {

  return (
    <tbody className='listar-tbody'>
      {rows?.map((row, index) => (
        <tr key={index}>
          <td>{row.NombreConvocatoria}</td>
          <td className='text-center'>{row.FechaInicio}</td>
          <td className='text-center'>{row.FechaCierre}</td>
          <td>
            <div className='d-flex justify-content-center' style={{gap: "15px"}}>
              <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: DetallePostularConvocatoria, data: { title: "Detalle convocatoria", IdConvocatoriaTraslado: row.IdConvocatoriaTraslado }})}>
                Detalles
              </button>
              <button type='button' className='btn generic-button-buscador px-2 mx-0' disabled={!row.PuedePostular} onClick={() => setModalEnabled({isEnable: true, component: DetallePostularConvocatoria, data: { title: "Postular convocatoria", isApply: true, IdConvocatoriaTraslado: row.IdConvocatoriaTraslado  }})}>
                Postular
              </button>
            </div>
          </td>
        </tr>
      ))}

    </tbody>
  )
}