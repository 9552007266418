import React from "react";
import PropTypes from "prop-types";
import InputTextArea from "../../../../components/modalComponents/InputTextArea";
import InputUploadFile from "../../../../components/modalComponents/InputUploadFile";
import InputDate from "../../../../components/modalComponents/InputDate";
import { Div } from "./Components";

const TerminoDescansoMedico = ({
  formValues,
  setFormValues,
  setIsUploading,
  commonProps,
  maxDate,
  keys: KEYS,
  sectionName: SECTION_NAME,
}) => {
  return (
    <>
      <Div>
        <InputDate
          label={"Fecha de fin"}
          name={KEYS.fechaFinCondicion}
          max={maxDate}
          sectionName={SECTION_NAME}
          isDisabled={formValues.isDisabled}
          {...commonProps(KEYS.fechaFinCondicion)}
        />
      </Div>
      <Div>
        <InputUploadFile
          label="Sustento"
          name={KEYS.file}
          uploadFile={formValues[KEYS.file]}
          sectionName={SECTION_NAME}
          setIsUploading={setIsUploading}
          validExtensions={["pdf", "jpg", "png"]}
          isDisabled={formValues.isDisabled}
          setUploadFile={(res) => setFormValues({ ...formValues, [KEYS.file]: res })}
        />
      </Div>
      <Div col={12}>
        <InputTextArea
          label="Comentario"
          direction="column"
          sectionName={SECTION_NAME}
          isDisabled={formValues.isDisabled}
          {...commonProps(KEYS.comentario)}
        />
      </Div>
    </>
  );
};

TerminoDescansoMedico.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.any.isRequired,
  setIsUploading: PropTypes.any.isRequired,
  commonProps: PropTypes.func.isRequired,
  keys: PropTypes.object,
  sectionName: PropTypes.string,
};

export default TerminoDescansoMedico;
