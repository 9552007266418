import React, {useState} from 'react';
import styled from 'styled-components';
import Arrow from '../../components/icons/Arrow';
import whatsapp from '../../image/whatsapp.png';
import gmail from '../../image/gmail.png';
import {openGmail, openWhatsapp} from "../../components/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDataEmpleadoAPI} from "../../consumers/backendApisUrls";
import ReactTooltip from 'react-tooltip';
import {toast} from "react-toastify";

const Wrapper = styled.div`
  display: none;
  padding: 20px 0;
  background: #ffffff;
  border-radius: 5px;

  .title-body {
    font-size: 0.875rem;
    color: #0063a1;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0 20px;
  }
  .title-body-name {
    color: #595959;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .wrapper {
    /* height: 46px; */
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid #ebebeb;
  }
  img {
    width: 20px;
    margin-right: 15px;
  }
  .description {
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 20px;
    div {
      padding: 5px 0;
    }
  }
  @media (max-width: 992px) {
    display: block;
  }
`;

const ArrowCont = styled.div`
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;
  display: flex;
  transition: all 0.3s ease 0s;
  align-items: center;
`;

function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
         });
    }
}

const Card = (props) => {
    const user = props.user;
    const [detail, setDetail] = useState({});
    const [executeGetRequest] = useGetRequest();

    const getData = async (IdEmpleado) => {
        try {
            const response = await executeGetRequest(getDataEmpleadoAPI(IdEmpleado));

            if (responseCode200(response)) {
                setDetail(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className='wrapper'>
            <div style={{width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={user.ProfileImageUrl} alt=''/>
                        <p className='title-body-name '>{user.NombreCompleto}</p>
                    </div>
                    <ArrowCont
                        active={props.id === user.IdEmpleado}
                        onClick={() => {
                            getData(user.IdEmpleado);
                            props.getId(user.IdEmpleado);
                        }}>
                        <Arrow/>
                    </ArrowCont>
                </div>
                {props.id === user.IdEmpleado && (
                    <div className='description'>
                        <div>{detail.Sede}</div>
                        <div>{detail.NombreCargo}</div>
                        <div>{detail.TiempoEnInnova} en Innova Schools</div>
                        {detail.NombreEmpleadoSuperior !== null && (
                            <div>
                                Su Jefe Líder es <a href='#'>{detail.NombreEmpleadoSuperior}</a>
                            </div>
                        )}

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '10px',
                        }}>
                            {user.Anexo && user.CanSendWhatsApp &&(
                                <div>
                                <p data-tip data-for="BottonAnexo" style={{ display: 'inline' }}> <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                                </p>
                                <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo" effect="solid" place="bottom" title
                                        getContent={function () {
                                        return (<div style={{'marginRight': '10px', 'marginLeft':'10px'}}>
                                                        Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(user.Anexo).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                        <br></br>
                                                        {user.Anexo}
                                                </div>
                                            )
                                        }}
                                        />
                                </div>
                            )}
                            {user.CanSendWhatsApp && (
                                <div>
                                     <p data-tip data-for="BottonWhatsAppModal" style={{ display: 'inline' }}> <img src={whatsapp} alt='' width='20' />
                                     </p>
                                     <ReactTooltip className="tooltip-style tooltip-with-buttons" id={'BottonWhatsAppModal'} effect="solid" place="bottom"
                                        getContent={function () {
                                        return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' style={{'marginRight': '1px', 'marginLeft':'20px'}} onClick={() => copyToClipboard(user.Celular).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' style={{}} onClick={() => openWhatsapp(user.Celular)}/>
                                            </div>
                                        )
                                    }}
                                    />
                                </div>
                            )}
                            {user.Email && (
                                <div>
                                    <p data-tip data-for="BottonCorreoModal" style={{ display: 'inline' }}> <img src={gmail} alt='' width='20'/>
                                    </p>
                                    <ReactTooltip className="tooltip-style tooltip-with-buttons" id={"BottonCorreoModal"} effect="solid" place="bottom" 
                                        getContent={function () {
                                        return (<div>
                                                    <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' style={{'marginRight': '1px', 'marginLeft':'20px'}} onClick={() => copyToClipboard(user.Email).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                    &nbsp;|&nbsp;
                                                    <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(user.Email)}/>
                                                </div>
                                            )
                                    }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const TableMobileBody = (props) => {
    const users = props.users;
    const [id, setId] = useState();

    return (
        <Wrapper>
            <div className='title-body'>Personas</div>
            {users.map((user, i) => (
                <Card key={i} user={user} getId={(e) => setId(e)} id={id}/>
            ))}
        </Wrapper>
    );
};

export default TableMobileBody;
