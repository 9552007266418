import React, { useState } from "react";
import ModalForm from "../../../components/modalComponents/ModalForm";
import { observarDocumentoDMCittAPI } from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { usePropsInputs } from "../../../hooks/useProps/usePropsInput";
import InputTextArea from "../../../components/modalComponents/InputTextArea";

const ObservarDocumentoDMCITT = ({
  toggleModal,
  IdEmpleadoFlujoActividad,
  showModal,
  manageSearch,
}) => {
  const SECTION_NAME = "ObservarDocumentoDMCITT";
  const KEYS = {
    ObservacionDocumentosFlujoCanjeDMcitt:
      "ObservacionDocumentosFlujoCanjeDMcitt",
    idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad,
  });
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = observarDocumentoDMCittAPI();
    try {
      await submitForm(
        Object.values(KEYS),
        api,
        formValues,
        SECTION_NAME,
        successSubmitCallback
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const successSubmitCallback = async () => {
    toggleModal();
    showModal();
    await manageSearch();
  };

  return (
    <ModalForm
      titulo="Observar documento CITT"
      width="500px"
      showModal={toggleModal}
      isSubmitting={isLoading}
      saveButtonDisabled={isLoading}
      onSubmit={handleSubmit}
      label="Observar">
      <div className="col-md-12">
        <InputTextArea
          label="Observación"
          name={KEYS.ObservacionDocumentosFlujoCanjeDMcitt}
          {...commonProps(KEYS.ObservacionDocumentosFlujoCanjeDMcitt)}
          sectionName={SECTION_NAME}
          direction="column"
          isRequired={true}
          maxLenght={200}
        />
      </div>
    </ModalForm>
  );
};

export default ObservarDocumentoDMCITT;
