import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { cleanDropdown, initialAllOptionPromises } from '../../../../../helper/utils';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import InputDate from '../../../../../components/modalComponents/InputDate';
import InputBool from '../../../../../components/modalComponents/InputBool';
import InputText from '../../../../../components/modalComponents/InputText';
import FooterModal from '../../../../../components/modalComponents/FooterModal';
import InputSelect from '../../../../../components/modalComponents/InputSelect';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import InputSearchAsync from '../../../../../components/modalComponents/InputSearchAsync';
import InputSelectCascade from '../../../../../components/modalComponents/InputSelectCascade';
import { KEYS_DATOS_NUEVO_INGRESO_NI, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS } from '../../../../../constants/hardCodedConstants';
import { getDatosNuevoIngresoGestionAPI, getDocumentosByIdListaAPI, getFlujosOnboardingAPI, getListaDocumentosAPI, getResponsableEmpleadoAPI, getResponsablesNuevoIngresoGestionAPI, saveDatosNuevoIngresoAPI, buscarColaboradorAPI, getTiposLicenciaAPI, getPeriodosPruebaAPI } from '../../../../../consumers/backendApisUrls';

const DatosNuevoIngreso = ({ idNuevosIngresosEmpleado, idParent: ID_PARENT, inputDisabled, manageSearchListaDocumentos }) => {

  const SECTION_NAME = "EditarDatosNuevoIngresoFichaNuevosIngresos";
  const KEYS_INIT = {FlujoOnboarding: "IdFlujoOnboarding", ResponsableProceso: "IdResponsableProceso"};

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({EsReingreso: false});
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();

  const allGetPromises = () => {
    return [
      getOptions(getFlujosOnboardingAPI()),
      getOptions(getResponsablesNuevoIngresoGestionAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      manageSearch();
      await initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), (res) => successInitCallback(res));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getDatosNuevoIngresoGestionAPI(idNuevosIngresosEmpleado);
    return await executeGet(api, (res) => setFormValues(res.data));
  }

  const successInitCallback = async (res) => {
    setAllDropdowns(res);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const api = saveDatosNuevoIngresoAPI();
      await submitForm(KEYS_DATOS_NUEVO_INGRESO_NI, api, formValues, SECTION_NAME, successSubmitCallback, true, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }
  
  const successSubmitCallback = () => {
    manageSearch();
    manageSearchListaDocumentos();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <form onSubmit={handleSubmit}>
      <CardAccordion id={OPTIONS.idDatosNuevoIngreso} idParent={ID_PARENT} label="7. Datos de nuevo ingreso" isShow>
        <div className="displayFlexWrap mt-4">
          <DivCol required><InputSelect label="Flujo de onboarding" {...commonPropsSelect("IdFlujoOnboarding")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputBool label="Reingreso" textInput="No / Sí" {...commonProps("EsReingreso")} colLabel={6} colInput={6} isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Responsable de Proceso" {...commonPropsSelect("IdResponsableProceso")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputDate label="Fecha límite de entrega de documentos" {...commonProps('FechaLimiteDocumentos')} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputBool label="Bono Movilidad" textInput="No / Sí" {...commonProps("TieneBonoMovilidad", ["MontoBonoMovilidad"])} colLabel={6} colInput={6} isDisabled={inputDisabled}/></DivCol>
          {formValues?.TieneBonoMovilidad &&
            <DivCol><InputText label="Monto Movilidad" {...commonProps("MontoBonoMovilidad")} direction='column' isDisabled={inputDisabled}/></DivCol>
          }
          <DivCol><InputBool label="Bono Destajo" textInput="No / Sí" {...commonProps("TieneBonoDesatajo", ["MontoBonoDesatajo"])} colLabel={6} colInput={6} isDisabled={inputDisabled}/></DivCol>
          {formValues?.TieneBonoDesatajo && 
            <DivCol><InputText label="Monto Destajo" {...commonProps("MontoBonoDesatajo")} direction='column' isDisabled={inputDisabled}/></DivCol>
          }
          <DivCol><InputBool label="Bono Referido" textInput="No / Sí" {...commonProps("TieneBonoReferido", ["MontoBonoReferido", "IdEmpleadoReferido"])} colLabel={6} colInput={6} isDisabled={inputDisabled}/></DivCol>
          {formValues.TieneBonoReferido &&
            <>
              <DivCol><InputText label="Monto Referido" {...commonProps("MontoBonoReferido")} direction='column' isDisabled={inputDisabled}/></DivCol>
              <DivCol><InputSearchAsync typeGet label="Nombre referido" api={buscarColaboradorAPI} valueText={formValues.EmpleadoReferido} {...commonProps('IdEmpleadoReferido')} direction='column' isDisabled={inputDisabled}/></DivCol>
            </>
          }

         <DivCol><InputBool label="Bono Atracción Talento" textInput="No / Sí" {...commonProps("TieneBonoAtraccionTalento", ["MontoBonoAtraccionTalento"])} colLabel={6} colInput={6} isDisabled={inputDisabled}/></DivCol>
          {formValues?.TieneBonoAtraccionTalento && 
            <DivCol><InputText label="Monto Atracción Talento" {...commonProps("MontoBonoAtraccionTalento")} direction='column' isDisabled={inputDisabled}/></DivCol>
          }

        </div>
        {!inputDisabled && <FooterModal isDisabled={isSending}/>}
      </CardAccordion>
    </form>
  )
}

export default DatosNuevoIngreso;

const DivCol = ({ children, required }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""} my-auto`}>
      {children}
    </div>
  )
}

const HandleDocuments = ({ lista }) => {
  return (
    lista?.length > 0 &&
      <div className="col-md-6">
        <label>Documentos:</label>
        <ul className="column-vertical-wrap pl-3 col-12">
        {lista?.map((o, i) => 
          <li key={`${o.label}-${i}`} className='col-6 pr-5'>{o.label}</li>)}
        </ul>
      </div>
  )
}