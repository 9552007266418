import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {downloadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../consumers/httpRequiestsUtils";
import {useForm} from "../../hooks/useForm/UseForm";
import {clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput} from "../../validations/FormValidatorUtils";
import {
    deleteFileFromS3API,
    downloadReporteVacacionesCantidadDiasSegunPeriodoAPI,
} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";

const ReporteDeVacacionesCantidadDiasSegunPeriodo = () => {
        const {state} = useContext(AuthContext);
        const seccionName = "ReporteDeVacacionesCantidadDiasSegunPeriodo";

        const [loading, setLoading] = useState(true);
        const [executePostRequest] = usePostRequest();

        const [formValues, setFormValues] = useState({});
        const [handleChange] = useForm(formValues, setFormValues);

        useEffect(() => {
            let unmounted = false;
            if (!unmounted) {
                init();
            }

            return () => {
                unmounted = true;
            };
        }, []);

        const init = async () => {
            setLoading(true);
            console.debug("init ReporteDeVacacionesCantidadDiasSegunPeriodo");

            setLoading(false);
        };

        const descargarReporte = async () => {
            let payload = {
                "FechaInicio": formValues["FechaInicio"],
                "FechaFin": formValues["FechaFin"]
            };

            let allInputKeys = ["FechaInicio", "FechaFin"];
            let responseReporteGenerado = await executePostRequest(downloadReporteVacacionesCantidadDiasSegunPeriodoAPI(), payload);
            if (responseCode200(responseReporteGenerado)) {
                clearAllInputValidationMessages(allInputKeys, seccionName);
                let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
                if (responseCode200(descargarReporteResponse)) {
                    await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
                } else {
                    mostrarMensajeDeErrorConToast(descargarReporteResponse);
                }
            } else if (responseTieneModelState(responseReporteGenerado)) {
                mostrarMensajesDeValidacionEnCadaInput(responseReporteGenerado, allInputKeys, seccionName);
            } else {
                clearAllInputValidationMessages(allInputKeys, seccionName);
                mostrarMensajeDeErrorConToast(responseReporteGenerado);
            }
        };

        const eliminarArchivoDeS3 = async function (filePath) {
            let deleteFileFromS3Payload = {
                "Path": filePath
            };
            let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
            if (responseCode200(deleteFileFromS3Response)) {
                console.debug("delete file from s3 successfully");
            } else {
                mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
            }
        };

        if (loading) return (<SpinnerCargandoSeccionFicha/>);
        return (
            <>
                <div className='card-body'>
                    <div id='ReporteDeVacacionesCantidadDiasSegunPeriodo'>
                        <div>
                            <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Inicio<span className="required">*</span></label>
                                <div className="col-sm-4">
                                    <input type="date" className="form-control" name="FechaInicio" onChange={handleChange}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-FechaInicio-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Fin<span className="required">*</span></label>
                                <div className="col-sm-4">
                                    <input type="date" className="form-control" name="FechaFin" onChange={handleChange}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-FechaFin-validation-message`}></p>
                                </div>
                            </div>
                        </div>

                        <div className="reporte-datos-generales-dato-obligatorio">
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>

                        <div>
                            <button className="btn btn-primary generic-button-ficha" onClick={descargarReporte}>Descargar Reporte</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
;

export default ReporteDeVacacionesCantidadDiasSegunPeriodo;