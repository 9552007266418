import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useIndexValues } from '../../hooks/useIndexValues/useIndexValues';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import SearchContainer from '../../components/pageComponents/SearchContainer';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import PageButtons from '../../components/pageComponents/PageButtons';
import PageWrapper from '../../components/pageComponents/PageWrapper';
import FormSelect from '../../components/atoms/Select';
import PageHeader from '../../components/pageHeader/PageHeader';
import PageTable from '../../components/pageComponents/PageTable';
import TableWebBody from './TableWebBody';
import CrearNuevoTraslado from './modales/CrearNuevoTraslado';
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../helper/utils';
import { convocatoriaTrasladoIndexAPI, getAllEstadosAPI } from '../../consumers/backendApisUrls';

const GestionTraslados = () => {

  const KEYS_FILTERS = {Estados: "IdsEstados"};
  const history = useHistory();

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [allFilters, setAllFilters] = useState();
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  
  const [isLoading, setIsLoading] = useState(true);
  const [isOrdenEsDescendente, setIsOrdenEsDescendente] = useState(true);
  
  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();
  const [searchIndex] = useIndexValues(setFormValues, setPaginator, setExtraData);

  const initialPromises = () => {
    return [
      executeGet(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(KEYS_FILTERS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally{
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = convocatoriaTrasladoIndexAPI();
    const payload = getPayload();
    await executePost(api, payload, searchIndex);
  }

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      isOrdenEsDescendente,
      ...selectedFilters
    };
  }

  const handleCreateButton = () => {
    setModalEnabled({ isEnable: true, component: CrearNuevoTraslado })
  }

  const handleFilterSelected = (res, key) => {
    setSelectedFilters(lastData => ({...lastData, [key]: res }));
  }

  useDidMountEffect(() => {
    if(jsonNoEstaVacio(selectedFilters)) manageSearch();
  }, [selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allFilters, extraData, setFilters);
  }, [extraData])

  useEffect(() => {
    init();
  }, [])

  const tableHeaders = [
    {label: "Nombre", col: "col-sm-4"},
    {label: 
      <DCenter>
        <FormSelect
          options={handleFind(filters, KEYS_FILTERS.Estados)}
          description={<label className='text-wrap mb-0'>Estado</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Estados)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Estados] ?? []}
        />
      </DCenter>,
      col: ""
    },
    {label: "Vigencia", col: "col-sm-2"},
    {label: "Cant. Postulantes", col: "col-sm-1"},
    {label: "Cant. Vacantes", col: "col-sm-1"},
    {label: "Vacantes Cubiertas", col: "col-sm-1"},
    {label: "Avance %", col: "col-sm-1"},
    {label: "Acciones", col: "col-sm-1"},
  ]

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Gestión de Traslados"/>
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={() => setSelectedFilters({})}>
        <PageButtons hasCreateButton handleCreateButton={handleCreateButton}/>
      </SearchContainer>
      <PageTable headers={tableHeaders} paginator={paginator} setPaginator={setPaginator}>
        <TableWebBody filas={formValues} setModalEnabled={setModalEnabled}/>
      </PageTable>
    </PageWrapper>
  )
}

export default GestionTraslados;

const DCenter = ({ children }) => (
  <div className='d-flex justify-content-center'>
    {children}
  </div>
)