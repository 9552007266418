import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import TerminoGestacion from './components/TerminoGestacion';
import TerminoLactancia from './components/TerminoLactancia'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputSelect from '../../../components/modalComponents/InputSelect';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { DM_CONDICION, ESTADOS, MATERNIDAD_CONDICION } from '../../../constants/hardCodedConstants';
import { jsonNoEstaVacio, toIsoForInput } from '../../../helper/utils';
import { getRelatedDataTypesAPI, registrarTerminoCondicionAPI } from '../../../consumers/backendApisUrls';
import TerminoDescansoMedico from './components/TerminoDescansoMedico';
import { Div } from './components/Components';

const KEYS = Object.freeze({
  idCondicion: "IdCondicion",
  idTipoDeCondicion: "IdTipoDeCondicion",
  idMotivo: "IdMotivoTerminoMaternidad",
  nombre: "NombreBebe",
  nacimiento: "FechaNacimiento",
  comentario: "ComentarioTermino",
  file: "SustentoFile",
  fechaFinCondicion: "FechaFinCondicion",
  idEstadoProceso: "IdEstadoProceso",
});
const SECTION_NAME = "CrearPopUpGenerico";

const TerminoMaternidad = ({ toggleModal = ()=>{}, refreshPage = ()=>{}, idCondicion, idTipoDeCondicion, row = {} , externalCallback, isModalShowed = true }) => {
  const [title, setTitle] = useState('');
  const [maxDate, setMaxDate] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const getRelatedDropdowns = async () => {
    setIsLoading(true);
    const api = getRelatedDataTypesAPI(idTipoDeCondicion);
    try {
      const data = await getOption(api);
      setAllDropdowns([{ key: [KEYS.idMotivo], value: data }]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = registrarTerminoCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdCondicion: idCondicion,
      ...formValues
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    if(externalCallback) externalCallback();
    await refreshPage();
  }

  useEffect(() => {
    getRelatedDropdowns();
  }, [])

  useEffect(() => {
    if (idTipoDeCondicion === DM_CONDICION.DESCANSO_MEDICO) {
      setTitle("Termino de Descanso Médico");
      setMaxDate(toIsoForInput(new Date(row[KEYS.fechaFinCondicion])));
    } else {
      setTitle("Termino de Maternidad");
    }
  }, [idTipoDeCondicion, row]);

  useEffect(() => {    
    if (jsonNoEstaVacio(row)) {
      const {
        IdMotivoTerminoMaternidad,
        NombreBebe,
        FechaNacimiento,
        SustentoTerminoRegistroMaternidaArchivo: SustentoFile,
        ComentarioTermino,
        FechaFinCondicion,
      } = row;
      setFormValues({
        IdMotivoTerminoMaternidad,
        NombreBebe,
        FechaNacimiento,
        SustentoFile,
        ComentarioTermino,
        FechaFinCondicion,
        isDisabled: row[KEYS.idEstadoProceso] === ESTADOS.TERMINADO ? true : false,
      });
    }
  }, [row])

  if (isLoading) return <SpinnerLoading />

  const content = (
    <>
      {Object.values(MATERNIDAD_CONDICION).includes(idTipoDeCondicion) && (
        <Div>
          <InputSelect
            label="Motivo"
            {...selectProps(KEYS.idMotivo, [KEYS.nombre, KEYS.nacimiento, KEYS.comentario])}
            isDisabled={formValues.isDisabled}
          />
        </Div>
      )}
      {idTipoDeCondicion === MATERNIDAD_CONDICION.GESTANTE && (
        <TerminoGestacion
          formValues={formValues}
          setFormValues={setFormValues}
          setIsUploading={setIsUploading}
          commonProps={commonProps}
          keys={KEYS}
          sectionName={SECTION_NAME}
        />
      )}
      {idTipoDeCondicion === MATERNIDAD_CONDICION.LACTANCIA && (
        <TerminoLactancia
          formValues={formValues}
          setFormValues={setFormValues}
          setIsUploading={setIsUploading}
          commonProps={commonProps}
          keys={KEYS}
          sectionName={SECTION_NAME}
        />
      )}
      {idTipoDeCondicion === DM_CONDICION.DESCANSO_MEDICO && (
        <TerminoDescansoMedico
          formValues={formValues}
          setFormValues={setFormValues}
          setIsUploading={setIsUploading}
          commonProps={commonProps}
          maxDate={maxDate}
          keys={KEYS}
          sectionName={SECTION_NAME}
        />
      )}
    </>
  );

  return (
    <>
      {isModalShowed ? (
        <ModalForm
          titulo={title}
          width="650px"
          showModal={toggleModal}
          isSubmitting={isSubmitting}
          saveButtonDisabled={isUploading || isSubmitting}
          onSubmit={handleSubmit}>
          <div className="displayFlexWrap py-4">{content}</div>
        </ModalForm>
      ) : (
        <div className="displayFlexWrap py-4">{content}</div>
      )}
    </>
  );
}

TerminoMaternidad.propTypes = {
  toggleModal: PropTypes.func,
  refreshPage: PropTypes.func,
  idTipoDeCondicion: PropTypes.number.isRequired
}

export default TerminoMaternidad