import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VerDetalle from './modales/verDetalle/VerDetalle';
import PageTable from '../../components/pageComponents/PageTable';
import FormSelect from '../../components/atoms/Select';
import { ESTADOS, ESTADOS_SI_NO } from '../../constants/hardCodedConstants';
import ActionsIcons from '../../components/atoms/ActionsIcons';
import TerminoMaternidad from './modales/TerminoMaternidad';
import { handleFind } from '../../helper/utils';
import { useDescargarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import ReactTooltip from 'react-tooltip';
import PrePostNatal from './modales/prePostNatal/PrePostNatal';

const TableMaternidad = ({ formValues, setFormValues, extraData, setModalEnabled, paginator, setPaginator, keys: KEYS, isAdmin }) => {
  const rows = formValues.Items;

  const [descargarArchivoDeS3] = useDescargarArchivoDeS3();

  const HEADERS = [
    { col: "col-sm-1", label: "Fecha de Registro" },
    { col: "col-sm-2", label: "Nombre" },
    {
      col: "col-sm-1", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.departamentos)}
            description="Departemento"
            selectedOptions={formValues.selectedFilters?.[KEYS.departamentos] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.departamentos)}
          />
        </div>
    },
    {
      col: "col-sm-1", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.cargos)}
            description="Cargo"
            selectedOptions={formValues.selectedFilters?.[KEYS.cargos] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.cargos)}
          />
        </div>
    },
    {
      col: "col-sm-2", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.sedes)}
            description="Sede"
            selectedOptions={formValues.selectedFilters?.[KEYS.sedes] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.sedes)}
          />
        </div>
    },
    { col: "col-sm-1", label: "Fecha de Ingreso" },
    {
      col: "col-sm-1", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.estadosAprobacion)}
            description="Est.Aprob"
            selectedOptions={formValues.selectedFilters?.[KEYS.estadosAprobacion] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.estadosAprobacion)}
          />
        </div>
    },
    {
      col: "col-sm-1", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.estadoProceso)}
            description="Est.Proc."
            selectedOptions={formValues.selectedFilters?.[KEYS.estadoProceso] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.estadoProceso)}
          />
        </div>
    },
    { col: "col-sm-1", label: "Fecha de Inicio" },
    { col: "col-sm-1", label: "Fecha de Fin" },
    { col: "col-sm-1", label:
      <div className='d-flex justify-content-center'>
        <FormSelect
          options={ESTADOS_SI_NO.filter(o => extraData[KEYS.preExistencia]?.includes(o.Value))}
          description="Pre-Existencia"
          selectedOptions={formValues.selectedFilters?.[KEYS.preExistencia] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.preExistencia)}
        />
      </div>
    },
    { col: "col-sm-1", label: 
      <div className='d-flex justify-content-center'>
        <FormSelect
          options={ESTADOS_SI_NO.filter(o => extraData[KEYS.subsidio]?.includes(o.Value))}
          description="Subsidio"
          selectedOptions={formValues.selectedFilters?.[KEYS.subsidio] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.subsidio)}
        />
      </div> 
    },
    { col: "col-sm-1", label: "Aprobador" },
    {
      col: "col-sm-1", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.condicion)}
            description="Condición"
            selectedOptions={formValues.selectedFilters?.[KEYS.condicion] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.condicion)}
          />
        </div>
    },
    {
      col: "col-sm-1", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.motivo)}
            description="Motivo Término"
            selectedOptions={formValues.selectedFilters?.[KEYS.motivo] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.motivo)}
          />
        </div>
    },
    { col: "", label: "Acciones" },
  ]

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = { ...formValues.selectedFilters, [key]: res };
    setFormValues(lastData => ({ ...lastData, selectedFilters: updatedSelectedFilters }));
  }

  const handleDownloadFile = ({ OriginalFileName, Path }) => {
    if (OriginalFileName && Path) descargarArchivoDeS3(OriginalFileName, Path);
  }

  return (
    <PageTable headers={HEADERS} paginator={paginator} setPaginator={setPaginator}>
      <tbody className="listar-tbody">
        {rows.map((row) => (
          <tr key={row.IdCondicion}>
            <td className="text-center">{row.FechaRegistro}</td>
            <td className="text-center">{row.NombreEmpleado}</td>
            <td className="text-center">{row.Departamento}</td>
            <td className="text-center">{row.Cargo}</td>
            <td className="text-center">{row.Sede}</td>
            <td className="text-center">{row.FechaIngresoEmpresaText || "-"}</td>
            <td className="text-center">{row.EstadoAprobacion}</td>
            <td className="text-center">{row.EstadoProceso}</td>
            <td className="text-center">{row.FechaInicioCondicionText || "-"}</td>
            <td className="text-center">{row.FechaFinCondicionText || "-"}</td>
            <td className="text-center">{row.PreExistencia}</td>
            <td className="text-center">{row.TieneSubsidioText}</td>
            <td className="text-center">{row.NombreEmpleadoAprobador || "-"}</td>
            <td className="text-center">{row.TipoDeCondicion || "-"}</td>
            <td className="text-center">{row.MotivoTerminoMaternidad || "-"}</td>
            <td className="text-center">
              <ActionsIcons>
                {row.MotivoRechazo && <ToolTip id={`MotivoRechazo${row.IdCondicion}`} text={row.MotivoRechazo} />}
                <FontAwesomeIcon
                  icon="id-card"
                  title="Ver Detalle Registro"
                  size="lg"
                  onClick={() =>
                    setModalEnabled({
                      isEnable: true,
                      component: VerDetalle,
                      data: { row, isAdmin },
                    })
                  }
                />
                {row.PuedeRegistrarFechasPrePostNatal && (
                  <FontAwesomeIcon
                    icon="calendar-day"
                    title="Registrar Pre/Post Natal"
                    size="lg"
                    onClick={() =>
                      setModalEnabled({
                        isEnable: true,
                        component: PrePostNatal,
                        data: { IdCondicion: row.IdCondicion },
                      })
                    }
                  />
                )}
                {row.RegistroCondicionSustentoArchivo && (
                  <FontAwesomeIcon
                    icon="paperclip"
                    title="Descargar Documento"
                    size="lg"
                    onClick={() => handleDownloadFile(row.RegistroCondicionSustentoArchivo)}
                  />
                )}
                {row.IdEstadoProceso === ESTADOS.SEGUIMIENTO && isAdmin && (
                  <FontAwesomeIcon
                    icon="hand-paper"
                    title="Terminar Registro"
                    size="lg"
                    onClick={() =>
                      setModalEnabled({
                        isEnable: true,
                        component: TerminoMaternidad,
                        data: {
                          idCondicion: row.IdCondicion,
                          idTipoDeCondicion: row.IdTipoDeCondicion,
                          row: row,
                        },
                      })
                    }
                  />
                )}
              </ActionsIcons>
            </td>
          </tr>
        ))}
      </tbody>
    </PageTable>
  );
}

export default TableMaternidad;

const ToolTip = ({ id, text }) => (
  <div>
    <FontAwesomeIcon data-tip data-for={id} icon='info-circle' title='Motivo Rechazo' size='lg' style={{color: "#b3a11a"}} />
    <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style">
        Motivo de Rechazo: {text}
    </ReactTooltip>
  </div>
)