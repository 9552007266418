import React from 'react'
import styled from 'styled-components'

const SlideButton = ({ value, onChange, nameOne, nameTwo, isDisabled = false}) => {
  const handleChange = (res) => {
    onChange(res.target.checked)
  }

  return (
    <CustomCheckBox>
      <div className='custom-checkbox'>
        <input id="status" 
          type="checkbox" 
          name="status" 
          checked={value}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          />
        <label for="status">
          <div className="status-switch"
            data-unchecked={nameOne}
            data-checked={nameTwo}>
          </div>
        </label>
      </div>
    </CustomCheckBox>
  )
}

export default SlideButton

const CustomCheckBox = styled.div`
  .custom-checkbox {
    width: 180px;
    height: 30px;
  }
  .custom-checkbox input#status {
    display: none;
  }
  .custom-checkbox input#status + label {
    height: 100%;
    width: 100%;
  }
  .custom-checkbox input#status + label > .status-switch {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #3a87c8;
    color: white;
    transition: all 0.5s ease;
    padding: 3px;
    border-radius: 3px;
  }
  .custom-checkbox input#status + label > .status-switch:before, .custom-checkbox input#status + label > .status-switch:after {
    border-radius: 2px;
    height: calc(100% - 6px);
    width: calc(50% - 3px);
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    transition: all 0.3s ease;
  }
  .custom-checkbox input#status + label > .status-switch:before {
    background-color: white;
    color: #3a87c8;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
    left: 3px;
    z-index: 10;
    content: attr(data-unchecked);
  }
  .custom-checkbox input#status + label > .status-switch:after {
    right: 0;
    content: attr(data-checked);
  }
  .custom-checkbox input#status:checked + label > .status-switch {
    background-color: #3a87c8;
  }
  .custom-checkbox input#status:checked + label > .status-switch:after {
    left: 0;
    content: attr(data-unchecked);
  }
  .custom-checkbox input#status:checked + label > .status-switch:before {
    color: #3a87c8;
    left: 50%;
    content: attr(data-checked);
  }
`;