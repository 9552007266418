import React, { useEffect, useState } from 'react'
import ModalForm from '../../../components/modalComponents/ModalForm';
import {
  getDataForModalGuardarDocumentoCanjeDMEssaludCitt,
  guardarDocumentoCanjeDMEssaludCittFirmado,
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';

const FirmarDocumentoCanjeDMEssaludCITT = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {
  const SECTION_NAME = "FirmarDocumentoCanjeDMEssaludCITT";
  const KEYS = {
    plantillaDocumentoCanjeDMEssaludCittFileViewMode: "PlantillaDocumentoCanjeDMEssaludCittFileViewModel",
    documentoCanjeDMEssaludCittFirmadoFileViewModel: "DocumentoCanjeDMEssaludCittFirmadoFileViewModel",
    idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad
  });
  const [manageGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const init = async function () {
    setIsLoading(true);
    if (IdEmpleadoFlujoActividad) {
      await manageGetRequest(
        getDataForModalGuardarDocumentoCanjeDMEssaludCitt(IdEmpleadoFlujoActividad),
        callbackSuccess,
        false
      );
    }
    setIsLoading(false);
  };

  const callbackSuccess = function (response) {
    setFormValues({
      ...formValues,
      [KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewMode]: response.data[KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewMode],
    });
  };

  const loadingMessage = (
    <>
      Se está generando la plantilla... <FontAwesomeIcon icon="spinner" transform="left-9" className="ml-3 spinner" />
    </>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = guardarDocumentoCanjeDMEssaludCittFirmado();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  };

  return (
    <ModalForm
      titulo="Canje de tu descanso médico particular"
      width="890px"
      showModal={showModal}
      isSubmitting={isLoading}
      saveButtonDisabled={isLoading}
      onSubmit={handleSubmit}>
      <InputUploadFile
        label="Descarga aquí las instrucciones para el canje de tu descanso médico"
        name={KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewMode}
        uploadFile={formValues[KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewMode]}
        sectionName={SECTION_NAME}
        NoFileMessage={loadingMessage}
        isDisabled
      />
      <InputUploadFile
        label="Sube tu CITT otorgado o canjeado por Essalud"
        name={[KEYS.documentoCanjeDMEssaludCittFirmadoFileViewModel]}
        uploadFile={formValues[KEYS.documentoCanjeDMEssaludCittFirmadoFileViewModel]}
        setUploadFile={(res) =>
          setFormValues({
            ...formValues,
            [KEYS.documentoCanjeDMEssaludCittFirmadoFileViewModel]: res,
          })
        }
        sectionName={SECTION_NAME}
        validExtensions={["pdf"]}
        isDisabled={isLoading}
        isRequired
      />
    </ModalForm>
  );
};

export default FirmarDocumentoCanjeDMEssaludCITT;