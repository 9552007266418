import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImportFileGenericModal from '../../../components/modal/ImportFileGenericModal'
import ActionGenericModal from '../../../components/modal/ActionGenericModal'
import AmonestacionesSuspenciones from './modales/AmonestacionesSuspenciones'
import SeleccionarConvocatoria from './modales/SeleccionarConvocatoria'
import DetallePostulacion from './modales/DetallePostulacion'
import { calificarConvocatoriaTrasladoEmpleadoAPI, enviarCartaConvocatoriaTrasladoEmpleadoAPI, aceptarPropuestaConvocatoriaTrasladoEmpleadoAPI } from '../../../consumers/backendApisUrls'

const TableWebBody = ({ filas, setModalEnabled, setRowsSelected }) => {

  const ID_IMPORT_FILE = "ACEPTAR_CARTA_PROPUESTA";

  const handleSelectRow = (isSelected, rowId) => {
    setRowsSelected(prevSelectedRows => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (isSelected) {
        newSelectedRows.add(rowId);
      } else {
        newSelectedRows.delete(rowId);
      }
  
      return [...newSelectedRows];
    });
  }

  return (
    <tbody className='listar-tbody'>
      {filas?.map((fila, index) =>(
        <tr key={`${fila.IdConvocatoriaTrasladoEmpleado}-${index}`}>
          <td className='text-center'>
            {fila.PuedeGenerarActividadAmonestaciones ?
              <input className='mt-1' type='checkbox' onChange={(e) => handleSelectRow(e.target.checked, fila.IdConvocatoriaTrasladoEmpleado)} /> :
              <div>-</div>
            }
          </td>
          <td className='text-center'>{fila.FechaPostulacion}</td>
          <td className='pl-3'>{fila.NombreCompleto}</td>
          <td className='text-center small'>{fila.Sede}</td>
          <td className='text-center small'>{fila.Unidad}</td>
          <td className='text-center small'>{fila.Gerencia}</td>
          <td className='text-center small'>{fila.Area}</td>
          <td className='text-center'>{fila.GradoDesarrollo}</td>
          <td className='text-center'>{fila.FechaPostulacion}</td>
          <td className='text-center'>{fila.AntiguedadInnova}</td>
          <td className='text-center small'>{fila.SedePrimeraOpcion}</td>
          <td className='text-center small'>{fila.SedeSegundaOpcion}</td>
          <td className='text-center small'>{fila.RequerimientoSede}</td>
          <td className='text-center'>{fila.Estado}</td>
          <td className='text-center'>
            <Link to={`/fichadedatos/fromGestionFicha/${fila.IdEmpleado}`} target="_blank">
              ver Ficha
            </Link>
          </td>
          <td className='text-center'>{fila.NotaEvaluacion ?? "-"}</td>
          <td className='text-center'>{fila.TieneAmonestaciones === "" ? "-" : fila.TieneAmonestaciones}</td>
          <td className='text-center'>{fila.TieneSuspensiones === "" ? "-" : fila.TieneSuspensiones}</td>
          <td>
            <div className='d-flex justify-content-center blue-standard-color' style={{gap: "15px"}}>
              {fila.PuedeCalificar &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: ActionGenericModal, data: {title: "Calificar", actionLabel: "calificar", api: calificarConvocatoriaTrasladoEmpleadoAPI, id: fila.IdConvocatoriaTrasladoEmpleado, name: `${fila.NombreCompleto}-${fila.Sede}`}})}>
                  Calificar
                </button>
              }
              {fila.PuedeSeleccionar &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: SeleccionarConvocatoria, data: {id: fila.IdConvocatoriaTrasladoEmpleado}})}>
                  Seleccionar
                </button>
              }
              {fila.PuedeEnviarCarta &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: ActionGenericModal, data: {title: "Enviar Carta", actionLabel: "enviar carta en", api: enviarCartaConvocatoriaTrasladoEmpleadoAPI, id: fila.IdConvocatoriaTrasladoEmpleado, name: `${fila.NombreCompleto}-${fila.Sede}`}})}>
                  Enviar carta
                </button>
              }
              {fila.PuedeAceptarPropuesta &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0' onClick={() => setModalEnabled({isEnable: true, component: ImportFileGenericModal, data: {api: aceptarPropuestaConvocatoriaTrasladoEmpleadoAPI(), id: ID_IMPORT_FILE, additionalPayload: {IdConvocatoriaTrasladoEmpleado: fila.IdConvocatoriaTrasladoEmpleado}, validExtensions: ["pdf"]}})}>
                  Aceptar
                </button>
              }
              <FontAwesomeIcon
                icon='address-card'
                size='lg'
                title='Ver Detalle'
                onClick={() => setModalEnabled({isEnable: true, component: DetallePostulacion, data: {idConvocatoriaTrasladoEmpleado: fila.IdConvocatoriaTrasladoEmpleado, FileViewModelCartaPropuestaSinFirma: fila.FileViewModelCartaPropuestaSinFirma, FileViewModelCartaFirmada: fila.FileViewModelCartaFirmada}})}
                />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody