import React from 'react'
import { Div } from './Components'
import InputDate from '../../../../components/modalComponents/InputDate'
import InputText from '../../../../components/modalComponents/InputText'
import { getOneYearAfterDate, getTodayDate, toIsoForInput } from '../../../../helper/utils'
import CustomAlert from '../../../../components/alerts/CustomAlert'

const Lactancia = ({ commonProps, puedeCrearLactancia, keys: KEYS, isDisabled = false, isCreate = false }) => {
  function handleFechaNacimientoChange(e) {
    commonProps(KEYS.fechaNacimiento).onChange(e);
    let oneYearAppart = {
      target:{
        name: KEYS.fechaFinCondicion,
        value: getOneYearAfterDate(e.target.value)
      }
    }
    commonProps(KEYS.fechaFinCondicion).onChange(oneYearAppart);
  }
  
  const fechasDeCondicion = (
    <>
      <Div>
        <InputDate
          label={"Inicio Lactancia"}
          name={KEYS.fechaInicioCondicion}
          {...commonProps(KEYS.fechaInicioCondicion)}
          isDisabled={true}
        />
      </Div>
    </>
  );

  return (
    <>
      {!puedeCrearLactancia && (
        <Div col={12}>
          <CustomAlert variant={"info"} isDismissible={false}>
            No se puede registrar directamente como Lactante. Debe iniciar su
            registro con la condición de Gestante.
          </CustomAlert>
        </Div>
      )}
      <Div>
        <InputDate
          label="Fec. Nac."
          {...commonProps(KEYS.fechaNacimiento)}
          max={toIsoForInput(getTodayDate())}
          onChange={handleFechaNacimientoChange}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      </Div>
      {!isCreate && fechasDeCondicion}
      <Div>
        <InputDate
          label="Fin Lactancia"
          {...commonProps(KEYS.fechaFinCondicion)}
          isDisabled
        />
      </Div>
      {isDisabled === true && (
        <Div>
          <InputText
            label="Horario Lactancia"
            {...commonProps(KEYS.horarioLactanciaMaternidad)}
            isDisabled={isDisabled}
            isRequired={!isDisabled}
          />
        </Div>
      )}
      <Div>
        <InputText
          label="Nombre del Bebé"
          {...commonProps(KEYS.nombre)}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      </Div>
    </>
  );
}

export default Lactancia