import React, { useEffect, useState } from "react";
import ModalForm from "../../../components/modalComponents/ModalForm";
import {
  getDataForModalGuardarDocumentoCanjeDMEssaludCitt,
  getInfoFlujoCanjeDMCittAPI,
  subirDocumentoTramiteEssaludAPI,
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import InputUploadFile from "../../../components/modalComponents/InputUploadFile";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import CustomAlert from "../../../components/alerts/CustomAlert";
import { EXTENSION_PDF } from "../../../constants/hardCodedConstants";

const EntregarDocumentoTramiteEssalud = ({
  showModal,
  IdEmpleadoFlujoActividad,
  IdEmpleadoFlujo,
  manageSearch,
}) => {  
  const SECTION_NAME = "EntregarDocumentoTramiteEssalud";
  const KEYS = {
    documentoTramiteEssaludFileViewModel:
      "DocumentoTramiteEssaludFileViewModel",
    idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
    plantillaDocumentoCanjeDMEssaludCittFileViewModel:
      "PlantillaDocumentoCanjeDMEssaludCittFileViewModel",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad,
  });
  const [manageGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const init = async function () {
    setIsLoading(true);
    if (IdEmpleadoFlujo) {
      await manageGetRequest(
        getDataForModalGuardarDocumentoCanjeDMEssaludCitt(IdEmpleadoFlujo),
        setTemplate,
        false
      );
    }
    setIsLoading(false);
  };

  const setTemplate = function (response) {
    setFormValues({
      ...formValues,
      [KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewModel]:
        response.data[KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewModel],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = subirDocumentoTramiteEssaludAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  };

  return (
    <ModalForm
      titulo="Subir sustento del trámite EsSalud"
      width="600px"
      showModal={showModal}
      isSubmitting={isLoading}
      saveButtonDisabled={isLoading}
      onSubmit={handleSubmit}>
      <div className="col-md-12">
        <InputUploadFile
          label="Descarga aqui las instrucciones para el canje de tu descanso médico"
          name={KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewModel}
          sectionName={SECTION_NAME}
          uploadFile={
            formValues[KEYS.plantillaDocumentoCanjeDMEssaludCittFileViewModel]
          }
          isDisabled={true}
        />
      </div>
      <div className="col-md-12">
        <InputUploadFile
          label="Sustento"
          name={KEYS.documentoTramiteEssaludFileViewModel}
          sectionName={SECTION_NAME}
          uploadFile={formValues[KEYS.documentoTramiteEssaludFileViewModel]}
          setIsUploading={setIsLoading}
          isRequired={true}
          validExtensions={EXTENSION_PDF}
          setUploadFile={(res) =>
            setFormValues({
              ...formValues,
              [KEYS.documentoTramiteEssaludFileViewModel]: res,
            })
          }
        />
      </div>
      <div className="col-md-12">
        <CustomAlert variant={"warning"} isDismissible={false}>
          Adjunta en pdf el sustento de tu trámite realizado en EsSalud
        </CustomAlert>
      </div>
    </ModalForm>
  );
};

export default EntregarDocumentoTramiteEssalud;
