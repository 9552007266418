import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";
import GenericUploadFileV2 from "../GenericUploadFile/GenericUploadFileV2";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { DivInput } from "../atoms/DivInput";
import { MAX_FILE_SIZE } from "../../constants/hardCodedConstants";
import { AlertErrorMaxFileSizeMessage } from "../alerts/AlertErrorMaxFileSizeMessage";
import GenericTooltip from "../GenericTooltip/GenericTooltip";

const InputUploadFile = ({
  label,
  uploadFile,
  setUploadFile,
  id,
  textButton = "Subir archivo",
  validExtensions,
  isUploading,
  setIsUploading,
  isIcon = false,
  icon,
  name,
  sectionName,
  colLabel=4,
  colInput=8,
  direction = "row",
  isRequired,
  showToastSuccess = true,
  isDisabled = false,
  NoFileMessage = "No se ha subido archivo",
  onClick = null,
  maxFileSize = MAX_FILE_SIZE,
  ToastArchivoNoValido = AlertErrorMaxFileSizeMessage,
  toolTipText = "",
}) => {  
  const { state } = useContext(AuthContext);
  if(onClick == null) {
    onClick = () => {
      downloadFile(
        state.token,
        uploadFile?.Path,
        uploadFile?.OriginalFileName
      );
    }
  }

  const iconComponent = (
    <>
      {isIcon ? (
        <FontAwesomeIcon
          title="Descargar archivo"
          size="lg"
          icon={faDownload}
          className="green-innova"
        />
      ) : (
        <>
          <FontAwesomeIcon icon="paperclip" transform="left-9" />
          {uploadFile?.OriginalFileName}
        </>
      )}
    </>
  );

  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput}>
      {label &&
        <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}{" "}
        {toolTipText !== "" && <GenericTooltip id={`ficha-edit-${sectionName}-${name}-tooltip`} text={toolTipText} />}
        </label>
      }
      <div className="black-color">
        {!isDisabled &&
          <>{uploadFile?.Path ? (
            <div>
              <a className="mx-3 hand-pointer blue-standard-color" onClick={onClick}>
                {iconComponent}
              </a>
              <FontAwesomeIcon
                icon="trash"
                size="lg"
                title="Eliminar"
                className="hand-pointer blue-standard-color"
                onClick={() => setUploadFile({})}
              />
            </div>
          ) : (
            <div className="">
              <GenericUploadFileV2
                id={id}
                extensionesValidas={validExtensions}
                uploadFileToS3={setUploadFile}
                textButton={textButton}
                subiendoArchivoPublicState={isUploading}
                setSubiendoArchivoPublicState={setIsUploading}
                resetInitialFile={false}
                isIcon={isIcon}
                icon={icon}
                maxFileSize={maxFileSize}
                showToastSuccess={showToastSuccess}
                ToastArchivoNoValido={ToastArchivoNoValido}
              />
            </div>
          )}
          </>
        }
        {isDisabled && uploadFile?.Path && 
          <a className="mx-4 hand-pointer blue-standard-color" onClick={onClick}>
            {iconComponent}
          </a>
        }
        {isDisabled && !uploadFile?.Path &&
          <div className="d-flex align-items-center">
            <p className="m-0 text-muted">{NoFileMessage}</p>
          </div>
        }
        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${name}-validation-message`} />
      </div>
    </DivInput>
  );
};

export default InputUploadFile;