import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadFile } from '../../../consumers/backendConsumer'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { useIndexValues } from '../../../hooks/useIndexValues/useIndexValues'
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { AuthContext } from '../../../hooks/context/authContext'
import ActionGenericModal from '../../../components/modal/ActionGenericModal'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import PageWrapper from '../../../components/pageComponents/PageWrapper'
import PageTable from '../../../components/pageComponents/PageTable'
import FormSelect from '../../../components/atoms/Select'
import { aceptarPropuestaConvocatoriaTrasladoEmpleadoAPI, getAllEstadosAPI, misPostulacionesIndexTrasladosAPI, rechazarPropuestaConvocatoriaTrasladoEmpleadoAPI } from '../../../consumers/backendApisUrls'
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils'
import ImportFileGenericModal from '../../../components/modal/ImportFileGenericModal'
import PaginatorV2 from '../../../components/pageComponents/PaginatorV2'

const MisPostulaciones = ({ setModalEnabled }) => {

  const ID_TAB = "Mis_Postulaciones";
  const KEYS_FILTERS = {Estados: "IdsEstados"};

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [allFilters, setAllFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  
  const [searchCriteria, setSearchCriteria] = useState("");
  const [isOrdenEsDescendente, setIsOrdenEsDescendente] = useState(true);
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();
  const [searchIndex] = useIndexValues(setFormValues, setPaginator, setExtraData);

  const allFiltersPromises = () => {
    return [
      executeGet(getAllEstadosAPI())
    ]
  }

  const init = () => {
    try {
      initialAllOptionPromises(allFiltersPromises, Object.values(KEYS_FILTERS), setAllFilters);
      manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = misPostulacionesIndexTrasladosAPI();
    const payload = getPayload();
    await executePost(api, payload, searchIndex);
  }

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      OrdenEsDescendente: isOrdenEsDescendente,
      ...selectedFilters
    };
  }

  const handleFilterSelected = (res, key) => {
    setSelectedFilters(lastData => ({...lastData, [key]: res }));
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allFilters, extraData, setFilters);
  }, [extraData])

  useEffect(() => {
    init();
  }, [])

  return {
    manageSearchMisPostulaciones: manageSearch,
    renderMisPostulaciones: (
      <div id={`accordion-${ID_TAB}`} style={{marginBottom: "180px"}}>
        <div className='card p-0'>
          <PageWrapper isLoading={isLoading}>
            <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} />
            <div className="container-fluid body-content">
              <div className="table-scroll pt-3">
                <table className="table table-hover">
                  <thead className="listar-thead">
                    <tr className="font-gothan-medium">
                      <th className="col-sm-3 text-center">Nombre Convocatoria</th>
                      <th className="col-sm-1 text-center">Fec. Postulación</th>
                      <th className="col-sm-2 text-center">Primera Opción</th>
                      <th className="col-sm-2 text-center">Segunda Opción</th>
                      <th className="">
                        <DCenter>
                          <FormSelect
                            options={handleFind(filters, KEYS_FILTERS.Estados)}
                            description={<label className='text-wrap mb-0'>Estado Postulación</label>}
                            setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Estados)}
                            selectedOptions={selectedFilters[KEYS_FILTERS.Estados] ?? []}
                          />
                        </DCenter>
                      </th>
                      <th className="col-sm-2 text-center">Sede Final</th>
                      <th className="col-sm-1 text-center">Acciones</th>
                    </tr>
                    </thead>
                  <TableWebBody rows={formValues} setModalEnabled={setModalEnabled}/>
                </table>
              <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
              </div>
            </div>
          </PageWrapper>
        </div>
      </div>
    )
  }
}

export default MisPostulaciones;

const TableWebBody = ({ rows, setModalEnabled }) => {

  const ID_IMPORT_FILE = "ACEPTAR_CARTA_PROPUESTA";
  const {state} = useContext(AuthContext);

  return (
    <tbody className='listar-tbody'>
      {rows?.map((row, index) => (
        <tr key={index}>
          <td className='pl-3'>{row.NombreConvocatoria}</td>
          <td className='text-center'>{row.FechaCrea}</td>
          <td className='small pl-3'>{row.SedePrimeraOpcion}</td>
          <td className='small pl-3'>{row.SedeSegundaOpcion}</td>
          <td className='text-center'>{row.EstadoPostulacion}</td>
          <td className='small pl-3'>{row.SedeFinal}</td>
          <td>
            <div className='d-flex justify-content-center blue-standard-color' style={{gap: "15px"}}>
              {row.PuedeDescargar &&
                <FontAwesomeIcon
                  icon='download'
                  title='Descargar carta'
                  size='lg'
                  onClick={() => downloadFile(state.token, row.FileViewModelCartaPropuestaSinFirma?.Path, row.FileViewModelCartaPropuestaSinFirma?.OriginalFileName )}
                />
              }
              {row.PuedeDescargar && row.FileViewModelCartaPropuestaFirmada &&
                <FontAwesomeIcon
                  icon='download'
                  title='Descargar carta firmada'
                  size='lg'
                  className='green-innova'
                  onClick={() => downloadFile(state.token, row.FileViewModelCartaPropuestaFirmada?.Path, row.FileViewModelCartaPropuestaFirmada?.OriginalFileName )}
                />
              }
              {row.PuedeAceptarPropuesta &&
                <button type='button' className='btn generic-button-buscador px-2 mx-0'
                  onClick={() => setModalEnabled({isEnable:true, component: ImportFileGenericModal, data: {api: aceptarPropuestaConvocatoriaTrasladoEmpleadoAPI(), id:ID_IMPORT_FILE, additionalPayload: {IdConvocatoriaTrasladoEmpleado: row.IdConvocatoriaTrasladoEmpleado}, validExtensions: ["pdf"]}})}
                >
                  Acepto
                </button>
              }
              {row.PuedeRechazarPropuesta && 
                <button type='button' className='btn generic-button-buscador px-2 mx-0'
                  onClick={() => setModalEnabled({isEnable:true, component: ActionGenericModal, data: {title: "Rechazar Postulación", api: rechazarPropuestaConvocatoriaTrasladoEmpleadoAPI, id: row.IdConvocatoriaTrasladoEmpleado, name: row.NombreConvocatoria, actionLabel: "Rechazar"}})}
                >
                  Rechazar
                </button>
              }
            </div>
          </td>
        </tr>
      ))}

    </tbody>
  )
}

const DCenter = ({ children }) => (
  <div className='d-flex justify-content-center'>
    {children}
  </div>
)