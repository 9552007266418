import React, { useState, useEffect } from 'react'
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import InputSelect from '../../../../components/modalComponents/InputSelect';
import ModalForm from '../../../../components/modalComponents/ModalForm'
import { getDetallePostulacionAPI, getRequerimientosDisponiblesPorCargoAPI, seleccionarConvocatoriaTrasladoEmpleadoAPI } from '../../../../consumers/backendApisUrls';

const SeleccionarConvocatoria = ({ toggleModal, refreshPage, id }) => {
  
  const SECTION_NAME = "SeleccionarConvocatoria";
  const KEYS = "IdRequerimientoPersonal";

  const [rowData, setRowData] = useState({});
  const [formValues, setFormValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [executeManageGet] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  const init = () => {
    try {
      manageSearch();
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    executeManageGet(getDetallePostulacionAPI(id), ({ data }) => setRowData(lastData => ({...lastData, ...data})));
    executeManageGet(getRequerimientosDisponiblesPorCargoAPI(), ({ data }) => setRowData(lastData => ({...lastData, requirements: data.map(o => ({ value: o.IdRequerimientoPersonal, label: o.RequerimientoSede }) )})));
    setIsLoading(false);
  }

  
  const handleChange = (e) => {
    let { value, name } = e.target;
    setFormValues(lastData => ({...lastData, [name]: value}))
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = seleccionarConvocatoriaTrasladoEmpleadoAPI();
    const payload = {IdRequerimientoPersonal: formValues.IdRequerimientoPersonal?.value, IdConvocatoriaTrasladoEmpleado: id};
    await submitForm(KEYS, api, payload, SECTION_NAME, handleSubmitCallback);
  }

  const handleSubmitCallback = () => {
    refreshPage();
    toggleModal();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Seleccionar convocatoria" width="900px">
      <div className="d-flex flex-column">
        <div className='d-flex mb-2'>
          <label className='col-sm-4 modal-label ml-1'>1ra Opción</label>
          <label className='col-sm-8'>{rowData?.SedePrimeraOpcion}</label>
        </div>
        <div className='d-flex mb-2'>
          <label className='col-sm-4 modal-label ml-1'>2da Opción</label>
          <label className='col-sm-8'>{rowData?.SedeSegundaOpcion}</label>
        </div>
        <div className='col-sm-12'>
          <InputSelect label="Seleccionar vacante" name='IdRequerimientoPersonal' options={rowData?.requirements} value={formValues.IdRequerimientoPersonal} onChange={handleChange} sectionName={SECTION_NAME}/>
        </div>
      </div>
    </ModalForm>
  )
}

export default SeleccionarConvocatoria