import React from 'react'
import PropTypes from "prop-types"
import InputText from '../../../../components/modalComponents/InputText'
import InputDate from '../../../../components/modalComponents/InputDate'
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile'
import { MATERNIDAD_MOTIVO_TERMINO } from '../../../../constants/hardCodedConstants'
import { getTodayDate, toIsoForInput } from '../../../../helper/utils'
import { Div } from './Components'

const TYPES = [MATERNIDAD_MOTIVO_TERMINO.NACIMIENTO_BEBE, MATERNIDAD_MOTIVO_TERMINO.PERDIDA];

const TerminoGestacion = ({ formValues, setFormValues, setIsUploading, commonProps, keys: KEYS, sectionName: SECTION_NAME }) => {
  return (
    <>
      {formValues[KEYS.idMotivo] === MATERNIDAD_MOTIVO_TERMINO.NACIMIENTO_BEBE && (
        <>
          <Div>
            <InputText label="Nombre BB" {...commonProps(KEYS.nombre)} isDisabled={formValues.isDisabled} />
          </Div>
          <Div>
            <InputDate
              label="Fec. Nacimiento"
              {...commonProps(KEYS.nacimiento)}
              max={toIsoForInput(getTodayDate())}
              isDisabled={formValues.isDisabled}
            />
          </Div>
          <span className="required font-size-search-label-text">
            * Adjuntar el documento de Acta de Nacimiento o copia de DNI, este registro solo se toma para fines de
            registro de lactancia, no significa el pago de asignación familiar
          </span>
        </>
      )}
      {TYPES.includes(formValues[KEYS.idMotivo]) && (
        <Div>
          <InputUploadFile
            label="Sustento"
            name={KEYS.file}
            uploadFile={formValues[KEYS.file]}
            setUploadFile={(res) => setFormValues({ ...formValues, [KEYS.file]: res })}
            sectionName={SECTION_NAME}
            setIsUploading={setIsUploading}
            validExtensions={["pdf", "jpg", "png"]}
            isDisabled={formValues.isDisabled}
          />
        </Div>
      )}
      {formValues[KEYS.idMotivo] === MATERNIDAD_MOTIVO_TERMINO.PERDIDA && (
        <>
          <Div col={12}>
            <InputTextArea
              label="Comentarios"
              {...commonProps(KEYS.comentario)}
              direction="column"
              isDisabled={formValues.isDisabled}
            />
          </Div>
        </>
      )}
    </>
  );
}

TerminoGestacion.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.any.isRequired,
  setIsUploading: PropTypes.any.isRequired,
  commonProps: PropTypes.func.isRequired,
  keys: PropTypes.object.isRequired,
  sectionName: PropTypes.string, 
}

export default TerminoGestacion