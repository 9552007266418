import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import MultiSelectInput from "../../components/MultiSelectInput/MultiSelectInput";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {convertirArrayToStringSeparadoPorComas, isTrue, jsonNoEstaVacio, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, stringSeEncuentraEnArray} from "../../helper/utils";
import SelectInput from "../../components/Select/SelectInput";
import {useForm} from "../../hooks/useForm/UseForm";
import EnviarReporteViaGmailModal from "./EnviarReporteViaGmailModal";
import {toast} from "react-toastify";
import {KEY_CAMPOS_CON_CHECK_DISABLED_POR_DEFECTO, KEY_CAMPOS_CON_CHECK_POR_DEFECTO, REPORTE_FILE_NAME, REPORTE_SEDE} from "../../constants/hardCodedConstants";
import {cleanFormValidationMessage, updateFormValidationMessage} from "../../validations/FormValidatorUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {
    deleteFileFromS3API,
    downloadReporteDatosGeneralesAPI,
    enviarReporteGeneralAPI, getAllEstadosAPI,
    getAllSedesAPI,
    getAreasByGerenciaAPI,
    getUnidadesForReporteDatosGeneralesAPI,
    getEspecialidadesAPI,
    getDepartamentosbyUnidadForReporteDatosGeneralesAPI,
    getPersonaEstadosAPI,
    getReporteGeneralCamposAPI, reportesGetReporteGeneralConfigAPI, retornoClasesListar
} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {Spinner} from "react-bootstrap";
import {downloadFile} from "../../consumers/backendConsumer";
import {useManagePostRequest} from "../../hooks/useManagePostRequest/useManagePostRequest";
import {useManageGetRequest} from "../../hooks/useManageGetRequest/useManageGetRequest";

const ReporteDeDatosGenerales = () => {
        const {state} = useContext(AuthContext);
        const multiselectPlaceholder = "Selecciona una o más opciones";
        const singleSelectPlaceholder = "Selecciona una opción";

        const [loading, setLoading] = useState(true);

        const [sedesOptions, setSedesOptions] = useState([]);
        const [selectedSedesValues, setSelectedSedes] = useState([]);

        const [divisionesOptions, setDivisionesOptions] = useState([]);
        const [jsonSelectedDivisionValue, setJsonSelectedDivisionValue] = useState({});

        const [especialidadesOptions, setEspecialidadesOptions] = useState([]);
        const [selectedEspecialidadesValues, setSelectedEspecialidadesValues] = useState([]);

        const [estadosOptions, setEstadosOptions] = useState([]);
        const [selectedEstadosValues, setSelectedEstadosValues] = useState([]);

        const [gerenciasOptions, setGerenciasOptions] = useState([]);
        const [selectedGerenciasValues, setSelectedGerenciasValues] = useState([]);

        const [areasOptions, setAreasOptions] = useState([]);
        const [selectedAreasValues, setSelectedAreasValues] = useState([]);

        const [reporteGeneralCamposCheckboxs, setReporteGeneralCamposCheckboxs] = useState([]);
        const [reporteGeneralCamposCheckboxsViewModel, setReporteGeneralCamposCheckboxsViewModel] = useState([]);

        const [camposCheckboxJsonFormValues, setCamposCheckboxJsonFormValues] = useState({});
        const [handleChangeCamposCheckboxFormValues] = useForm(camposCheckboxJsonFormValues, setCamposCheckboxJsonFormValues);

        const [formTipoReporte, setFormTipoReporte] = useState({TipoReporte: "Division"});
        const [handleChangeTipoReporte] = useForm(formTipoReporte, setFormTipoReporte);

        const [hideEnviarReportesViaGmailModal, setHideEnviarReportesViaGmailModal] = useState(true);
        const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
        const [executePostRequest] = usePostRequest();
        const [managePostRequest] = useManagePostRequest();
        const [manageGetRequest] = useManageGetRequest();

        const [reporteGeneralConfig, setReporteGeneralConfig] = useState({});
        const [disableDivision, setDisableDivision] = useState(false);
        const [disableGerencia, setDisableGerencia] = useState(false);
        const [disableArea, setDisableArea] = useState(false);

        useEffect(() => {
            let unmounted = false;
            if (!unmounted) {
                init();
            }

            return () => {
                unmounted = true;
            };
        }, []);

        useDidMountEffect(() => {
            camposCheckboxJsonFormValuesUseEffect();
        }, [camposCheckboxJsonFormValues, formTipoReporte?.TipoReporte]);

        useDidMountEffect(() => {
            console.debug("useDidMountEffect, jsonSelectedDivisionValue: ", jsonSelectedDivisionValue);
            if (jsonNoEstaVacio(jsonSelectedDivisionValue)) {
                setGerenciasOptionsDeLaDivision(jsonSelectedDivisionValue["value"]);
                disableGerencia === false && setSelectedGerenciasValues([]);
                disableArea === false && setSelectedAreasValues([]);
            }
        }, [jsonSelectedDivisionValue]);

        useDidMountEffect(() => {
            console.debug("useDidMountEffect, selectedGerenciasValues: ", selectedGerenciasValues);
            setAreasOptionsDeLaGerencia(selectedGerenciasValues);
            disableArea === false && setSelectedAreasValues([]);
        }, [selectedGerenciasValues]);

        useDidMountEffect(() => {
            let temp = camposCheckboxJsonFormValues;
            let selectedDivisionValue = getSelectedDivisionValue();
            temp = getUpdatedJsonFormValues(temp, selectedDivisionValue, "Division");
            temp = getUpdatedJsonFormValues(temp, selectedGerenciasValues, "Gerencia");
            temp = getUpdatedJsonFormValues(temp, selectedSedesValues, "Sede");
            temp = getUpdatedJsonFormValues(temp, selectedAreasValues, "Area");
            //temp = getUpdatedJsonFormValues(temp, selectedEspecialidadesValues, "EspecialidadPrincipal");
            //temp = getUpdatedJsonFormValues(temp, selectedEstadosValues, "Estado");

            setCamposCheckboxJsonFormValues(temp);

        }, [jsonSelectedDivisionValue, selectedGerenciasValues, selectedSedesValues, selectedAreasValues, selectedEspecialidadesValues, selectedEstadosValues]);

        const getSelectedDivisionValue = () => {
            return (jsonSelectedDivisionValue["value"]) ? [jsonSelectedDivisionValue["value"]] : [];
        };

        const camposCheckboxJsonFormValuesUseEffect = () => {
            if (jsonNoEstaVacio(camposCheckboxJsonFormValues)) {
                console.debug("useDidMountEffect, camposCheckboxJsonFormValues: ", camposCheckboxJsonFormValues);
                let reporteGeneralCamposCheckboxsTemp = [];

                for (let campoCheckbox of reporteGeneralCamposCheckboxs) {
                    let tempCampo = {};
                    let checkboxKeyField = campoCheckbox["Key"];
                    let valuee = camposCheckboxJsonFormValues[checkboxKeyField];

                    tempCampo = {...campoCheckbox, "valuee": valuee};
                    tempCampo = {...tempCampo, "disabledd": getDisabledValueForTheReporteGeneralCamposCheckboxsViewModel(checkboxKeyField)};

                    reporteGeneralCamposCheckboxsTemp = [...reporteGeneralCamposCheckboxsTemp, tempCampo];
                }
                setReporteGeneralCamposCheckboxsViewModel(reporteGeneralCamposCheckboxsTemp);
            }
        };

        const getDisabledValueForTheReporteGeneralCamposCheckboxsViewModel = (checkboxKeyField) => {
            let disabled = false;
            if (stringSeEncuentraEnArray(checkboxKeyField, KEY_CAMPOS_CON_CHECK_DISABLED_POR_DEFECTO)) {
                return true;
            } else {
                disabled = (checkboxKeyField === "Sede" && selectedSedesValues.length > 0) ? true : disabled;
                disabled = (checkboxKeyField === "Division" && getSelectedDivisionValue().length > 0) ? true : disabled;
                disabled = (checkboxKeyField === "Gerencia" && selectedGerenciasValues.length > 0) ? true : disabled;
                disabled = (checkboxKeyField === "Area" && selectedAreasValues.length > 0) ? true : disabled;
                disabled = (checkboxKeyField === "EspecialidadPrincipal" && selectedEspecialidadesValues.length > 0) ? true : disabled;
                disabled = (checkboxKeyField === "Estado" && selectedEstadosValues.length > 0) ? true : disabled;
            }
            return disabled;
        };

        const getUpdatedJsonFormValues = (camposCheckboxJsonFormValuess, selectedValues, key) => {
            let temp = camposCheckboxJsonFormValuess;
            return (selectedValues.length > 0) ? {...temp, [key]: true} : {...temp, [key]: false};
        };

        const setAreasOptionsDeLaGerencia = async (selectedGerenciasValuess) => {
            let areasOptionss = [];
            for (let idGerencia of selectedGerenciasValuess) {
                const response = await executeGetRequest(getAreasByGerenciaAPI(idGerencia));
                if (responseCode200(response)) {
                    areasOptionss = [...areasOptionss, ...response.data];
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
            updateOptionBasedOnResponse(areasOptionss, setAreasOptions);
        };

        const setGerenciasOptionsDeLaDivision = async (idDivision) => {
            const response = await executeGetRequest(getDepartamentosbyUnidadForReporteDatosGeneralesAPI(idDivision));
            if (responseCode200(response)) {
                updateOptionBasedOnResponse(response.data, setGerenciasOptions);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        };

        const reportesGetReporteGeneralConfigAPISuccess = function (response) {
            setReporteGeneralConfig(response.data);

            setDisableDivision(response.data?.DisableDivision);
            setDisableGerencia(response.data?.DisableGerencia);
            setDisableArea(response.data?.DisableArea);

            response.data?.IdDivision && setJsonSelectedDivisionValue({ value: response.data?.IdDivision, label: response.data?.Division })
            response.data?.IdGerencia && setSelectedGerenciasValues([response.data?.IdGerencia])
            response.data?.IdArea && setSelectedAreasValues([response.data?.IdArea])
        }

        const getReporteGeneralCamposAPISuccess = function (response) {
            if (responseCode200(response)) {
                setReporteGeneralCamposCheckboxs(response.data);
                let camposCheckboxFormValues = getCheckBoxkInitialFormValues(response.data);
                setCamposCheckboxJsonFormValues(camposCheckboxFormValues);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }

        const init = async () => {
            setLoading(true);
            await setOptionsDropDownIndependiente(executeGetRequestCallback(getAllSedesAPI()), setSedesOptions);
            await setOptionsDropDownIndependiente(executeGetRequestCallback(getUnidadesForReporteDatosGeneralesAPI()), setDivisionesOptions);
            await setOptionsDropDownIndependiente(executeGetRequestCallback(getEspecialidadesAPI()), setEspecialidadesOptions);
            await setOptionsDropDownIndependiente(executeGetRequestCallback(getPersonaEstadosAPI()), setEstadosOptions);

            await manageGetRequest(getReporteGeneralCamposAPI(), getReporteGeneralCamposAPISuccess, false);
            await managePostRequest(reportesGetReporteGeneralConfigAPI(), {}, reportesGetReporteGeneralConfigAPISuccess, false);



            setLoading(false);
        };

        const getCheckBoxkInitialFormValues = (camposCheckbox) => {
            let formInputValues = {};
            console.debug("KEY_CAMPOS_CON_CHECK_POR_DEFECTO ", KEY_CAMPOS_CON_CHECK_POR_DEFECTO);
            for (let campoCheckbox of camposCheckbox) {
                let key = campoCheckbox["Key"];
                console.debug("key ", key);

                let value = (stringSeEncuentraEnArray(key, KEY_CAMPOS_CON_CHECK_POR_DEFECTO)) ? true : false;
                console.debug("value ", value);
                formInputValues = {...formInputValues, [key]: value}
            }

            formInputValues = {...formInputValues};
            console.debug("formInputValues", formInputValues);
            return formInputValues;
        };

        const updateOptionBasedOnResponse = (apiOptions, setStatee) => {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(apiOptions);
            setStatee(options);
        };

        const setOptionsDropDownIndependiente = async (getApiFunction, setStatee) => {
            const response = await getApiFunction(state.token);
            if (responseCode200(response)) {
                updateOptionBasedOnResponse(response.data, setStatee);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        };

        const showEnviarReportesViaGmailModal = () => {
            let camposObligatoriosOK = validarCamposObligatorios();

            if (isTrue(camposObligatoriosOK)) {
                let currentState = hideEnviarReportesViaGmailModal;
                setHideEnviarReportesViaGmailModal(!currentState);
            } else {
                toast.error("Seleccionar opciones en campos obligatorios.");
            }
        };

        const validarCamposObligatorios = () => {
            const mensajeCampoObligatorio = "Seleccionar una opción";
            let prefixId = "reportes-validation-message-";
            let camposObligatoriosOK = false;
            let campoDivisionOk = validarCampooListaDesplegableObligatorio(getSelectedDivisionValue(), `${prefixId}Division`, mensajeCampoObligatorio);
            let campoGerenciaOk = validarCampooListaDesplegableObligatorio(selectedGerenciasValues, `${prefixId}Gerencia`, mensajeCampoObligatorio);

            if (isTrue(campoDivisionOk) && isTrue(campoGerenciaOk)) {
                camposObligatoriosOK = true;
            }

            return camposObligatoriosOK;
        };

        const validarCampooListaDesplegableObligatorio = (selectedOptions, elementHtmlId, mensaje) => {
            let camposObligatoriosOK = true;
            if (selectedOptions.length === 0) {
                updateFormValidationMessage(elementHtmlId, mensaje);
                camposObligatoriosOK = false;
            } else {
                cleanFormValidationMessage(elementHtmlId);
            }
            return camposObligatoriosOK;
        };

        const submitModalEnviarReporteViaGmail = async (emails) => {
            let payload = getPayloadWithOutMails();
            console.debug("emails: ", emails);
            payload = {...payload, "Correos": emails};
            console.debug("payload ", payload);

            let response = await executePostRequest(enviarReporteGeneralAPI(), payload);

            if (responseCode200(response)) {
                toast.success(response.data.Message);
                showEnviarReportesViaGmailModal();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        };

        const getPayloadWithOutMails = () => {
            console.debug("camposCheckboxJsonFormValues: ", camposCheckboxJsonFormValues);

            let keyCampos = [];

            for (let key in camposCheckboxJsonFormValues) {
                if (camposCheckboxJsonFormValues.hasOwnProperty(key) && camposCheckboxJsonFormValues[key] === true) {
                    if(formTipoReporte?.TipoReporte === "Sede"){
                        if(key === "Division" || key === "Gerencia" || key === "Area")
                            keyCampos = [...keyCampos]
                        else
                            keyCampos = [...keyCampos, key]
                    } else {
                        keyCampos = [...keyCampos, key]
                    }
                }
            }

            let keyCamposString = convertirArrayToStringSeparadoPorComas(keyCampos);

            let payload = {
                "IdSedes": formTipoReporte?.TipoReporte === "Sede"? selectedSedesValues: [],
                "IdDivisiones": reporteGeneralConfig?.IdSede === REPORTE_SEDE.BACK_OFFICE && formTipoReporte?.TipoReporte === "Sede"? 
                    [] : (jsonSelectedDivisionValue["value"]) ? [jsonSelectedDivisionValue["value"]] : [],
                "IdGerencias": reporteGeneralConfig?.IdSede === REPORTE_SEDE.BACK_OFFICE && formTipoReporte?.TipoReporte === "Sede"? []: selectedGerenciasValues,
                "IdAreas": reporteGeneralConfig?.IdSede === REPORTE_SEDE.BACK_OFFICE && formTipoReporte?.TipoReporte === "Sede"? [] : selectedAreasValues,
                "IdEspecialidades": selectedEspecialidadesValues,
                "KeyCampos": keyCamposString
            };

            console.debug("payload: ", payload);

            return payload;
        };

        const descargarReporte = async () => {
            const payload = getPayloadWithOutMails();
            console.debug("payload ", payload);

            let responseReporteGenerado = await executePostRequest(downloadReporteDatosGeneralesAPI(), payload);
            if (responseCode200(responseReporteGenerado)) {
                let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
                if (responseCode200(descargarReporteResponse)) {
                    await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
                } else {
                    mostrarMensajeDeErrorConToast(descargarReporteResponse);
                }
            } else {
                mostrarMensajeDeErrorConToast(responseReporteGenerado);
            }
        };

        const eliminarArchivoDeS3 = async function (filePath) {
            let deleteFileFromS3Payload = {
                "Path": filePath
            };
            let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
            if (responseCode200(deleteFileFromS3Response)) {
                console.debug("delete file from s3 successfully");
            } else {
                mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
            }
        };

        if (loading) return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm"/>
            </div>
        );
        return (
            <>
                <div id="modal-contactanos" className="modal-contactanos modal-response-ficha-background " hidden={hideEnviarReportesViaGmailModal}>
                    {hideEnviarReportesViaGmailModal === false &&
                    <EnviarReporteViaGmailModal showEnviarReportesViaGmailModal={showEnviarReportesViaGmailModal} submitModalEnviarReporteViaGmail={submitModalEnviarReporteViaGmail}/>
                    }
                </div>
                <div className='card-body'>
                    <div id='reportesDeDatosGenerales'>
                        <p className="reportes-seccion-first-title">1. Elige el grupo de personas del cual necesitas la información:</p>
                        <div>
                            {reporteGeneralConfig?.IdSede === REPORTE_SEDE.BACK_OFFICE? (
                                <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                    <label className="col-sm-3 col-form-label modal-label blue-standard-color reportes-label">Tipo de reporte</label>
                                    <div className="col-sm-8">
                                    <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="TipoReporte" 
                                                id="inlineRadio2" 
                                                value="Sede"
                                                checked={formTipoReporte?.TipoReporte === "Sede"}
                                                onChange={handleChangeTipoReporte}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Por Sede</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="TipoReporte" 
                                                id="inlineRadio1" 
                                                value="Division"
                                                checked={formTipoReporte?.TipoReporte === "Division"}
                                                onChange={handleChangeTipoReporte}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Por División</label>
                                        </div>
                                    </div>
                                </div>
                            ): null}
                            {reporteGeneralConfig?.IdSede !== REPORTE_SEDE.BACK_OFFICE ||formTipoReporte?.TipoReporte === "Division"? (
                                <>
                                    <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                        <label className="col-sm-3 col-form-label modal-label blue-standard-color reportes-label">División<span className="required">*</span></label>
                                        <div className="col-sm-8">
                                            <SelectInput options={divisionesOptions} jsonSelectedValue={jsonSelectedDivisionValue} setJsonSelectedValue={setJsonSelectedDivisionValue} isDisabled={disableDivision} placeholder={singleSelectPlaceholder} resetSelectedValue={false}/>
                                            <p className="ficha-form-input-validation-message" id={"reportes-validation-message-Division"}></p>
                                        </div>
                                    </div>
                                    <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                        <label className="col-sm-3 col-form-label modal-label blue-standard-color reportes-label">Gerencia<span className="required">*</span></label>
                                        <div className="col-sm-8">
                                            <MultiSelectInput options={gerenciasOptions} selectedValues={selectedGerenciasValues} setSelectedValues={setSelectedGerenciasValues} isDisabled={disableGerencia} placeholder={multiselectPlaceholder} resetSelectedValues={false}/>
                                            <p className="ficha-form-input-validation-message" id={"reportes-validation-message-Gerencia"}></p>
                                        </div>
                                    </div>
                                    <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                        <label className="col-sm-3 col-form-label modal-label blue-standard-color reportes-label">Área</label>
                                        <div className="col-sm-8">
                                            <MultiSelectInput options={areasOptions} selectedValues={selectedAreasValues} setSelectedValues={setSelectedAreasValues} isDisabled={disableArea} placeholder={multiselectPlaceholder} resetSelectedValues={false}/>
                                        </div>
                                    </div>
                                </>
                            ): null}

                            { reporteGeneralConfig?.IdSede === REPORTE_SEDE.BACK_OFFICE && formTipoReporte?.TipoReporte === "Sede"? (
                                    <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                        <label className="col-sm-3 col-form-label modal-label blue-standard-color reportes-label">Sede<span className="required">*</span></label>
                                        <div className="col-sm-8">
                                            <MultiSelectInput options={sedesOptions} selectedValues={selectedSedesValues} setSelectedValues={setSelectedSedes} isDisabled={false} placeholder={multiselectPlaceholder}/>
                                            <p className="ficha-form-input-validation-message" id={"reportes-validation-message-Sede"}></p>
                                        </div>
                                    </div>
                                ): null
                            }
                            
                            <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                <label className="col-sm-3 col-form-label modal-label blue-standard-color reportes-label">Especialidad</label>
                                <div className="col-sm-8">
                                    <MultiSelectInput options={especialidadesOptions} selectedValues={selectedEspecialidadesValues} setSelectedValues={setSelectedEspecialidadesValues} isDisabled={false} placeholder={multiselectPlaceholder}/>
                                </div>
                            </div>
                        </div>
                        <p className="reportes-seccion-second-title">2. Selecciona los campos que necesitas que aparezcan en tu reporte:</p>
                        <div className="row">
                            {reporteGeneralCamposCheckboxsViewModel && reporteGeneralCamposCheckboxsViewModel.map((campo, i) => {
                                if(formTipoReporte?.TipoReporte === "Sede" &&
                                    (campo["Key"] === "Division" || campo["Key"] === "Gerencia" || campo["Key"] === "Area")
                                ) return;
                                else return (
                                    <div className="col-3" key={i}>
                                        <input type="checkbox" name={campo["Key"]} checked={campo["valuee"]} disabled={campo["disabledd"]} onChange={handleChangeCamposCheckboxFormValues}></input><p className="resportes-checkbox-input-text">{campo.Text}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="reporte-datos-generales-dato-obligatorio">
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>

                        <div>
                            <button className="btn btn-primary generic-button-ficha" onClick={showEnviarReportesViaGmailModal}>Enviar via Gmail</button>
                            <button className="btn btn-primary generic-button-ficha reportes-descargar-button" onClick={descargarReporte}>Descargar Reporte</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
;

export default ReporteDeDatosGenerales;