import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import { AuthContext } from '../../../../hooks/context/authContext';
import ModalForm from '../../../../components/modalComponents/ModalForm'
import { downloadFile } from '../../../../consumers/backendConsumer';
import { getDetallePostulacionAPI } from '../../../../consumers/backendApisUrls';

const DetallePostulacion = ({ toggleModal, refreshPage, idConvocatoriaTrasladoEmpleado, FileViewModelCartaPropuestaSinFirma, FileViewModelCartaFirmada }) => {
  
  const {state} = useContext(AuthContext);

  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [executeGet] = useManageGetRequest();

  const init = () => {
    try {
      manageSearch();
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    const api = getDetallePostulacionAPI(idConvocatoriaTrasladoEmpleado);
    await executeGet(api, (res) => setFormValues(res.data));
    setIsLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm showModal={toggleModal} titulo="Detalle de la Postulación" hasSaveButton={false} width="900px">
      <div className='d-flex flex-column'>
        <div className='d-flex'>
          <strong className='col-sm-4'>Nombre</strong>
          <label className='col-sm-8'>{formValues?.Nombre}</label>
        </div>
        <div className='d-flex'>
          <strong className='col-sm-4'>Sede Actual</strong>
          <label className='col-sm-8'>{formValues?.SedeActual}</label>
        </div>
        <div className='d-flex'>
          <strong className='col-sm-4'>Fecha Postulación</strong>
          <label className='col-sm-8'>{formValues?.FechaPostulacion}</label>
        </div>
        <div className='d-flex'>
          <strong className='col-sm-4'>Sede Actual</strong>
          <label className='col-sm-8'>{formValues?.SedeActual}</label>
        </div>
        <div className='d-flex'>
          <strong className='col-sm-4'>1ra Opción</strong>
          <label className='col-sm-8'>{formValues?.SedePrimeraOpcion}</label>
        </div>
        <div className='d-flex'>
          <strong className='col-sm-4'>2da Opción</strong>
          <label className='col-sm-8'>{formValues?.SedeSegundaOpcion}</label>
        </div>
        <div className='d-flex'>
          <strong className='col-sm-4'>Motivo</strong>
          <label className='col-sm-8'>{formValues?.Motivo}</label>
        </div>
        {FileViewModelCartaPropuestaSinFirma && 
          <div className='d-flex'>
            <strong className='col-sm-4'>Carta Enviada</strong>
            <label className='col-sm-8'>
              <FontAwesomeIcon
                icon='download'
                title='Descargar carta'
                size='lg'
                className='blue-standard-color hand-pointer'
                onClick={() => downloadFile(state.token, FileViewModelCartaPropuestaSinFirma?.Path, FileViewModelCartaPropuestaSinFirma?.OriginalFileName )}
              />
            </label>
          </div>
        }
        {FileViewModelCartaFirmada && 
          <div className='d-flex'>
            <strong className='col-sm-4'>Carta Firmada</strong>
            <label className='col-sm-8'>
              <FontAwesomeIcon
                icon='download'
                title='Descargar firmada'
                size='lg'
                className='green-innova hand-pointer'
                onClick={() => downloadFile(state.token, FileViewModelCartaFirmada?.Path, FileViewModelCartaFirmada?.OriginalFileName )}
              />
            </label>
          </div>
        }
      </div>
    </ModalForm>
  )
}

export default DetallePostulacion