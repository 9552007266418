import React, {useContext, useEffect, useState, useReducer} from 'react';

import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import TableWebBody from "./TableWebBody";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import FormSelect from "../../components/atoms/Select";
import DetalleRegistroModal from "./modals/DetalleRegistroModal";
import DescargarOfisisModal from "./modals/DescargarOfisisModal";
import DetalleDePasosDeActividades from "./modals/DetalleDePasosDeActividades";
import Paginator from "../directorio/Paginator";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {
    bulkAprobarActividadAPI,
    getMisActividadEstadosAPI,
    getFlujosAPI,
    getPasosOptionsNuevosIngresosAPI,
    getActividadesDeFlujo,
    descargarListaActividadesPendientesAPI,
    getAllSedesAPI,
    getNuevosIngresosMisActividadesAPI
} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {Dropdown, Spinner} from "react-bootstrap";
import VerificarERPendienteModal from "./modals/VerificarERPendienteModal";
import GenerarLiquidacionModal from "./modals/GenerarLiquidacionModal";
import ConfirmarPrestamoModal from "./modals/ConfirmarPrestamoModal";
import CoordinarDevolucionModal from "./modals/CoordinarDevolucionModal";
import ConfirmarEstadoRecepcionEquiposModal from "./modals/ConfirmarEstadoRecepcionEquiposModal";
import ActualizarLiquidacionModal from "./modals/ActualizarLiquidacionModal";
import {toast} from "react-toastify";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {ACTIVIDADES_PENDIENTES_MODALES, FLUJOS} from "../../constants/hardCodedConstants";
import ReasignarActividadModal from "./modals/ReasignarActividadModal";
import ObservarActividadModal from "./modals/ObservarActividadModal";
import RegistrarVacacionesModal from "./modals/RegistrarVacacionesModal";
import FirmarCartaAutorizacionModal from "./modals/FirmarCartaAutorizacionModal";
import FirmaCartaAprobadosLineaCarreraModal from "./modals/FirmaCartaAprobadosLineaCarreraModal";
import FirmaDocumentoTeletrabajoModal from "./modals/FirmaDocumentoTeletrabajoModal";
import FirmaCompraVacacionesModal from "./modals/FirmaCompraVacacionesModal";
import FirmaCartaCambioPuestoModal from "./modals/FirmaCartaCambioPuestoModal";
import FirmaCartaIncremento2023Modal from "./modals/FirmaCartaIncremento2023Modal";
import FirmaConvenioBonoTrasladoModal from "./modals/FirmaConvenioBonoTrasladoModal";
import FirmaCartaIncrementoBO2023Modal from "./modals/FirmaCartaIncrementoBO2023Modal";
import FirmaConvenioCambioFuncionesModal from "./modals/FirmaConvenioCambioFuncionesModal";
import FirmaCartaBonoModal from "./modals/FirmaCartaBonoModal";
import ValidarCartaAutorizacionModal from "./modals/ValidarCartaAutorizacionModal";
import ValidarCartaCompromisoVacunacionFirmadaModal from './modals/ValidarCartaCompromisoVacunacionFirmadaModal';
import FirmarCartaCompromisoVacunacionModal from './modals/FirmarCartaCompromisoVacunacionModal';
import ValidarPlantillaCartaCompromisoVacunacionModal from "./modals/ValidarPlantillaCartaCompromisoVacunacionModal";
import FirmarCartaIncrementoModal from './modals/FirmarCartaIncrementoModal';
import CartaDeVacunacion from './modals/CartaDeVacunacion';
import {useGenerarDescargarEliminarArchivo} from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import FirmarCompromisoLecturaPoliticas from './modals/FirmarCompromisoLecturaPoliticas';
import {estados} from "../../constants/states";
import { ACTIONS, ActividadesPendientesreducer, initialState } from './reducer/ActividadesPendientesReducer';
import TestInduccionModal from './modals/TestInduccionModal';
import EncuestaInduccionModal from './modals/EncuestaInduccionModal';
import CrearCorreoCorporativoModal from './modals/CrearCorreoCorporativoModal';
import InduccionVirtualModal from './modals/InduccionVirtualModal';
import ConfirmarAsistenciaModal from './modals/ConfirmarAsistenciaModal';
import { useLocation } from 'react-router-dom';
import { isNullOrUndefinded } from '../../helper/utils';
import ConfirmarAccesoDM from './modals/ConfirmarAccesoDM';
import ConfirmarAccesoJira from './modals/ConfirmarAccesoJira';
import ConfirmarIngresoColaboradorNuevo from './modals/ConfirmarIngresoColaboradorNuevo';
import FirmaCartaObjetivoModal from "./modals/FirmaCartaObjetivoModal";
import FirmaCartaObjetivo2023y2024Modal from "./modals/FirmaCartaObjetivo2023y2024Modal";
import FirmaLineaCarrera2023Modal from "./modals/FirmaLineaCarrera2023Modal";
import FirmaCartaIncrementoSede2024Modal from "./modals/FirmaCartaIncrementoSede2024Modal";
import FirmaConvenioMutuoDisensoModal from "./modals/FirmaConvenioMutuoDisensoModal";
import FirmaPoliticaBonoTotModal from "./modals/FirmaPoliticaBonoTotModal";
import FirmaDocumentoEMOModal from "./modals/FirmaDocumentoEMOModal";
import FirmaConvenioCambioPuestoModal from "./modals/FirmaConvenioCambioPuestoModal";
import FirmaRecompensaTotalSede2024Modal from "./modals/FirmaRecompensaTotalSede2024Modal";
import FirmaRecompensaTotalBO2024Modal from "./modals/FirmaRecompensaTotalBO2024Modal";
import FirmaDocumentoProrrogaModal from "./modals/FirmaDocumentoProrrogaModal";
import FirmaDocumentoResultadoEvaluacion2024 from './modals/FirmaDocumentoResultadoEvaluacion2024'
import SeleccionarHorarioLactancia from './modals/SeleccionarHorarioLactancia';
import ValidarHorarioLactancia from './modals/ValidarHorarioLactancia';
import AprobarHorarioLactancia from './modals/AprobarHorarioLactancia';
import FirmarRecepcionHorarioLactancia from './modals/FirmarRecepcionHorarioLactancia';
import FirmarDocumentoCanjeDMEssaludCITT from './modals/FirmarDocumentoCanjeDMEssaludCITT';
import EntregarDocumentoTramiteEssalud from './modals/EntregarDocumentoTramiteEssalud';
import ValidarDocumentoTramiteEssalud from './modals/ValidarDocumentoTramiteEssalud';
import SubirDocumentoDMCITT from './modals/SubirDocumentoDMCITT';
import ValidarDocumentoDMCITT from './modals/ValidarDocumentoDMCITT';

const MisActividadesPendientes = () => {
    const [loading, setLoading] = useState(true);
    const [actividadesFiltradas, setActividadesFiltradas] = useState([]);

    //onst [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [pasosSeleccionados, setPasosSeleccionados] = useState([]);
    const [sedesSeleccionados, setSedesSeleccionados] = useState([]);
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([estados.Pendiente.id, estados.Error.id, estados.Proceso.id]);
    const [idEmpleado, setIdEmpleado] = useState();
    const [idActividadDetalle, setIdActividadDetalle] = useState();
    const [nombreActividad, setNombreActividad] = useState('');
    const [idEmpleadoFlujo, setIdEmpleadoFlujo] = useState();
    const [nombreUser, setNombreUser] = useState();
    const [datoItem, setDatoItem] = useState();

    const [state, dispatch] = useReducer(ActividadesPendientesreducer, initialState);

    const [hideDetalleRegistroModal, setHideDetalleRegistroModal] = useState(true);
    const [hideDescargarOfisisModal, setHideDescargarOfisisModal] = useState(true);
    const [hideRegistrarVacacionesModal, setHideRegistrarVacacionesModal] = useState(true);
    const [hideDetalleDePasosDeActividadesModal, setHideDetalleDePasosDeActividadesModal] = useState(true);
    const [hideVerificarERPendienteModal, setHideVerificarERPendienteModal] = useState(true);
    const [hideGenerarLiquidacionModal, setHideGenerarLiquidacionModal] = useState(true);
    const [hideConfirmarPrestamoModal, setHideConfirmarPrestamoModal] = useState(true);
    const [hideCoordinarDevolucionModal, setHideCoordinarDevolucionModal] = useState(true);
    const [hideConfirmarEstadoRecepcionEquipos, setHideConfirmarEstadoRecepcionEquipos] = useState(true);
    const [hideAmpliarLiquidacionModal, setHideAmpliarLiquidacionModal] = useState(true);
    const [hideReasignarActividadModal, setHideReasignarActividadModal] = useState(true);
    const [hideObservarActividadModal, setHideObservarActividadModal] = useState(true);
    const [hideFirmarCartaAutorizacionModal, setHideFirmarCartaAutorizacionModal] = useState(true);
    const [hideFirmaCartaAprobadosLineaCarreraModal, setHideFirmaCartaAprobadosLineaCarreraModal] = useState(true);
    const [hideFirmaDocumentoTeletrabajoModal, setHideFirmaDocumentoTeletrabajoModal] = useState(true);
    const [hideFirmaCompraVacacionesModal, setHideFirmaCompraVacacionesModal] = useState(true);
    const [hideFirmaCartaCambioPuestoModal, setHideFirmaCartaCambioPuestoModal] = useState(true);
    const [hideFirmaCartaIncremento2023Modal, setHideFirmaCartaIncremento2023Modal] = useState(true);
    const [hideFirmaConvenioBonoTrasladoModal, setHideFirmaConvenioBonoTrasladoModal] = useState(true);
    const [hideFirmaCartaIncrementoBO2023Modal, setHideFirmaCartaIncrementoBO2023Modal] = useState(true);
    const [hideFirmaConvenioCambioFuncionesModal, setHideFirmaConvenioCambioFuncionesModal] = useState(true);
    const [hideFirmaCartaBonoModal, setHideFirmaCartaBonoModal] = useState(true);
    const [hideFirmaCartaObjetivoModal, setHideFirmaCartaObjetivoModal] = useState(true);
    const [hideFirmaCartaObjetivo2023y2024Modal, setHideFirmaCartaObjetivo2023y2024Modal] = useState(true);
    const [hideFirmaLineaCarrera2023Modal, setHideFirmaLineaCarrera2023Modal] = useState(true);
    const [hideValidarFirmaCartaAutorizacionModal, setHideValidarFirmaCartaAutorizacionModal] = useState(true);
    const [hideValidarCartaCompromisoVacunacionModal, setHideValidarCartaCompromisoVacunacionModal] = useState(true);
    const [hideValidarPlantillaCartaCompromisoVacunacionModal, setHideValidarPlantillaCartaCompromisoVacunacionModal] = useState(true);
    const [hideFirmarCartaCompromisoVacunacionModal, setHideFirmarCartaCompromisoVacunacionModal] = useState(true);
    const [hideFirmarCompromisoLecturaPoliticaModal, setHideFirmarCompromisoLecturaPoliticaModal] = useState(true);
    const [hideFirmarCartaIncrementoModal, setHideFirmarCartaIncrementonModal] = useState(true);
    const [hideCartaDeVacunacionModal, setHideCartaDeVacunacionModal] = useState(true);
    const [hideTestInduccionModal, setHideTestInduccionModal] = useState(true);
    const [hideEncuestaInduccionModal, setHideEncuestaInduccionModal] = useState(true);
    const [hideCorreoCorporativoModal, setHideCorreoCorporativoModal] = useState(true);
    const [hideInduccionVirtualModal, setHideInduccionVirtualModal] = useState(true);
    const [hideConfirmarAsistenciaModal, setHideConfirmarAsistenciaModal] = useState(true);
    const [hideConfirmarAccesoDMModal, setHideConfirmarAccesoDMModal] = useState(true);
    const [hideConfirmarAccesoJiraModal, setHideConfirmarAccesoJiraModal] = useState(true);
    const [hideConfirmarIngresoColaboradorNuevoModal, setHideConfirmarIngresoColaboradorNuevoModal] = useState(true);
    const [hideFirmaCartaIncrementoSede2024Modal, setHideFirmaCartaIncrementoSede2024Modal] = useState(true);
    const [hideFirmaConvenioMutuoDisensoModal, setHideFirmaConvenioMutuoDisensoModal] = useState(true);
    const [hideFirmaPoliticaBonoTotModal, setHideFirmaPoliticaBonoTotModal] = useState(true);
    const [hideFirmaDocumentoEMOModal, setHideFirmaDocumentoEMOModal] = useState(true);
    const [hideFirmaConvenioCambioPuestoModal, setHideFirmaConvenioCambioPuestoModal] = useState(true);
    const [hideFirmaRecompensaTotalSede2024Modal, setHideFirmaRecompensaTotalSede2024Modal] = useState(true);
    const [hideFirmaRecompensaTotalBO2024Modal, setHideFirmaRecompensaTotalBO2024Modal] = useState(true);
    const [hideFirmaDocumentoProrrogaModal, setHideFirmaDocumentoProrrogaModal] = useState(true);
    const [hideFirmaDocumentoResultadoEvaluacion2024 , setHideFirmaDocumentoResultadoEvaluacion2024] = useState(true);
    const [hideSeleccionarHorarioLactancia, setHideSeleccionarHorarioLactancia] = useState(true);
    const [hideValidarHorarioLactancia, setHideValidarHorarioLactancia] = useState(true);
    const [hideAprobarHorarioLactancia, setHideAprobarHorarioLactancia] = useState(true);
    const [hideFirmarRecepcionHorarioLactancia, setHideFirmarRecepcionHorarioLactancia] = useState(true);
    const [hideFirmarDocumentoCanjeDMEssaludCITT, setHideFirmarDocumentoCanjeDMEssaludCITT] = useState(true);
    const [hideEntregarDocumentoTramiteEssalud, setHideEntregarDocumentoTramiteEssalud] = useState(true);
    const [hideValidarDocumentoTramiteEssalud, setHideValidarDocumentoTramiteEssalud] = useState(true);
    const [hideSubirDocumentoDMCITT, setHideSubirDocumentoDMCITT] = useState(true);
    const [hideValidarDocumentoDMCITT, setHideValidarDocumentoDMCITT] = useState(true);

    const [modalEnable, setModalEnable] = useState({isEnable: false});

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [pasosFiltradosOptions, setPasosFiltradosOptions] = useState([]);
    const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
    const [sedesFiltradosOptions, setSedesFiltradosOptions] = useState([]);

    const [cleanFilterss, setCleanFilters] = useState(false);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [idsEmpleadoFlujoActividadConCheck, setIdsEmpleadoFlujoActividadConCheck] = useState([]);

    const [jsonSelectedFlujoValue, setJsonSelectedFlujoValue] = useState({});

    const [showAprobar, setShowAprobar] = useState(false);

    const [generarYDescargarArchivo] = useGenerarDescargarEliminarArchivo();

    const location = useLocation();
    const [dataUrl, setDataUrl] = useState(location.state?.search ?? location?.search);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        setPage(1);
    //}, [criterioDeBusqueda, estadosSeleccionados, pasosSeleccionados, sedesSeleccionados]);
    }, [estadosSeleccionados, pasosSeleccionados, sedesSeleccionados]);

    useEffect(() => {
        manageSearch();
    //}, [estadosSeleccionados, pasosSeleccionados, page, sedesSeleccionados]);
    }, [estadosSeleccionados, pasosSeleccionados, page, sedesSeleccionados]);

    useDidMountEffect(() => {
        if (jsonSelectedFlujoValue?.value === FLUJOS.validaciónDeDatosDeNacimiento.id) {
            setShowAprobar(true);
        } else {
            setShowAprobar(false);
        }
    }, [jsonSelectedFlujoValue]);

    useDidMountEffect(() => {
        if (dataUrl){
            let actividad = actividadesFiltradas.filter(o => o.Code == ACTIVIDADES_PENDIENTES_MODALES.Modal_Archivos.id)[0];
            let actividadFromUrl = getUrlToObject(dataUrl);

            if (actividad?.Code == actividadFromUrl?.Code && actividad?.IdEmpleadoFlujoActividad == actividadFromUrl?.IdEmpleadoFlujoActividad){
                showInduccionVirtualModal(Number(actividadFromUrl?.IdEmpleadoFlujoActividad))
            }
        }

    }, [actividadesFiltradas]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await getDropdownOptions(executeGetRequestCallback(getMisActividadEstadosAPI()), setEstadosFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getPasosOptionsNuevosIngresosAPI()), setPasosFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setSedesFiltradosOptions);
            setIdsEmpleadoFlujoActividadConCheck([]);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getUrlToObject = (dataUrl) => {
        let search = dataUrl.substring(1);    
        return JSON.parse('{"' + decodeURI(search).replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    }

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const getPayloadBuscar = () => {
        return {
            //"Criteria": criterioDeBusqueda,
            "EstadosIds": estadosSeleccionados,
            "ActividadesIds": pasosSeleccionados,
            "SedesIds": sedesSeleccionados,
            "Page": page,
        }
    }

    const manageSearch = async () => {
        console.debug("manage search");
        const payload = getPayloadBuscar();
        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        console.debug("payload: ", payload);
        const response = await executePostRequest(getNuevosIngresosMisActividadesAPI(), payload);
        console.debug("response body: ", response.data);
        if (responseCode200(response)) {
            let responseBody = response.data;

            setActividadesFiltradas(responseBody.Items);
            setTotalItems(responseBody.TotalItems);
            setFirsItemPage(responseBody.FirsItemPage);
            setLastItemPage(responseBody.LastItemPage);
            setTotalPages(responseBody.TotalPages);

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
       setEstadosSeleccionados([]);
       // setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
        setPasosSeleccionados([]);
        setSedesSeleccionados([]);
    };

    const showDetalleRegistroModal = async (idActivida) => {
        if (idActivida) {
            setIdActividadDetalle(idActivida);
        }

        let currentState = hideDetalleRegistroModal;
        setHideDetalleRegistroModal(!currentState);
    };

    const showDescargarOfisisModal = async () => {
        let currentState = hideDescargarOfisisModal;
        setHideDescargarOfisisModal(!currentState);
        await manageSearch();
    };

    const descargarOfisisOnClick = function () {
        showDescargarOfisisModal();
    };

    const toggleRegistrarVacacionesModal = async () => {
        setHideRegistrarVacacionesModal(!hideRegistrarVacacionesModal);
    };

    const haySolicitudesSelecionadas = () => {
        let hayActividadesSeleccionadas = (idsEmpleadoFlujoActividadConCheck.length > 0) ? true : false;
        if (!hayActividadesSeleccionadas) {
            toast.warn("Seleccionar al menos una actividad");
        }
        return hayActividadesSeleccionadas;
    };

    const showDetallePasosDeActividadesModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        setHideDetalleDePasosDeActividadesModal(!hideDetalleDePasosDeActividadesModal);
    };

    const showVerificarERPendienteModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideVerificarERPendienteModal(!hideVerificarERPendienteModal);
    };

    const showGenerarLiquidacionModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideGenerarLiquidacionModal(!hideGenerarLiquidacionModal);
    };

    const showConfirmarPrestamoModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideConfirmarPrestamoModal(!hideConfirmarPrestamoModal);
    }

    const aprobarActividades = async function () {
        console.debug("aprobarActividades");
        console.debug("idsFichaCamposSolicitudesConCheck ", idsEmpleadoFlujoActividadConCheck);
        if (haySolicitudesSelecionadas()) {
            let payload = {
                "IdsEmpleadoFlujoActividad": idsEmpleadoFlujoActividadConCheck
            };

            console.debug("payload ", payload);
            let response = await executePostRequest(bulkAprobarActividadAPI(), payload);
            if (responseCode200(response)) {
                toast.success((response?.data?.message) ? response?.data?.message : "Actividades aprobadas exitosamente");
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
            refreshManageSearch();
        }
    }

    const refreshManageSearch = async () => {
        setJsonSelectedFlujoValue(jsonSelectedFlujoValue);
        setActividadesFiltradas([]);
        setIdsEmpleadoFlujoActividadConCheck([]);
        await manageSearch();
    }

    const showCoordinarDevolucionModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideCoordinarDevolucionModal(!hideCoordinarDevolucionModal);
    };

    const showConfirmarEstadoRecepcionEquiposModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideConfirmarEstadoRecepcionEquipos(!hideConfirmarEstadoRecepcionEquipos);
    };

    const showAmpliarLiquidacionModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideAmpliarLiquidacionModal(!hideAmpliarLiquidacionModal);
    };

    const toggleReasignarActividadModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideReasignarActividadModal(!hideReasignarActividadModal);
    };

    const toggleObservarActividadModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideObservarActividadModal(!hideObservarActividadModal);
    };

    const showFirmarCartaAutorizacionModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmarCartaAutorizacionModal(!hideFirmarCartaAutorizacionModal);
    };

    const showFirmaCartaAprobadosLineaCarreraModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaAprobadosLineaCarreraModal(!hideFirmaCartaAprobadosLineaCarreraModal);
    };

    const showFirmaDocumentoTeletrabajoModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaDocumentoTeletrabajoModal(!hideFirmaDocumentoTeletrabajoModal);
    };

    const showFirmaCompraVacacionesModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCompraVacacionesModal(!hideFirmaCompraVacacionesModal);
    };

    const showFirmaCartaCambioPuestoModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaCambioPuestoModal(!hideFirmaCartaCambioPuestoModal);
    };

    const showFirmaCartaIncremento2023Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaIncremento2023Modal(!hideFirmaCartaIncremento2023Modal);
    };

    const showFirmaConvenioCambioFuncionesModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaConvenioCambioFuncionesModal(!hideFirmaConvenioCambioFuncionesModal);
    };

    const showFirmaConvenioBonoTrasladoModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaConvenioBonoTrasladoModal(!hideFirmaConvenioBonoTrasladoModal);
    };
    
    const showFirmaCartaIncrementoBO2023Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaIncrementoBO2023Modal(!hideFirmaCartaIncrementoBO2023Modal);
    };

    const showFirmaCartaBonoModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaBonoModal(!hideFirmaCartaBonoModal);
    };

    const showFirmaCartaObjetivoModal = async (IdEmpleado, IdEmpleadoFlujoActividad, NombreActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
            setNombreActividad(NombreActividad);
        }
        setHideFirmaCartaObjetivoModal(!hideFirmaCartaObjetivoModal);
    };

    const showFirmaCartaObjetivo2023y2024Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaObjetivo2023y2024Modal(!hideFirmaCartaObjetivo2023y2024Modal);
    };

    const showFirmaRecompensaTotalSede2024Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaRecompensaTotalSede2024Modal(!hideFirmaRecompensaTotalSede2024Modal);
    };

    const showFirmaRecompensaTotalBO2024Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaRecompensaTotalBO2024Modal(!hideFirmaRecompensaTotalBO2024Modal);
    };

    const showFirmaDocumentoProrrogaModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaDocumentoProrrogaModal(!hideFirmaDocumentoProrrogaModal);
    };


    const showFirmaLineaCarrera2023Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaLineaCarrera2023Modal(!hideFirmaLineaCarrera2023Modal);
    };

    const showValidarFirmaCartaAutorizacionModal = async (IdEmpleadoFlujo, IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad && IdEmpleadoFlujo) {
            setIdEmpleadoFlujo(IdEmpleadoFlujo);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideValidarFirmaCartaAutorizacionModal(!hideValidarFirmaCartaAutorizacionModal);
    };

    const showValidarCartaCompromisoVacunacionModal = async (idActividad) => {
        if (idActividad) {
            setIdActividadDetalle(idActividad);
        }

        let currentState = hideValidarCartaCompromisoVacunacionModal;
        setHideValidarCartaCompromisoVacunacionModal(!currentState);
    };

    const showValidarPlantillaCartaCompromisoVacunacion = async (idActividad) => {
        if (idActividad) {
            setIdActividadDetalle(idActividad);
        }
        setHideValidarPlantillaCartaCompromisoVacunacionModal(!hideValidarPlantillaCartaCompromisoVacunacionModal);
    };


    const showFirmarCartaCompromisoVacunacionModal = async (idActivida) => {
        if (idActivida) {
            setIdActividadDetalle(idActivida);
        }

        let currentState = hideFirmarCartaCompromisoVacunacionModal;
        setHideFirmarCartaCompromisoVacunacionModal(!currentState);
    };

    const showFirmarCompromisoLecturaPoliticaModal = async (idActivida) => {
        if (idActivida) {
            setIdActividadDetalle(idActivida);
        }

        let currentState = hideFirmarCompromisoLecturaPoliticaModal;
        setHideFirmarCompromisoLecturaPoliticaModal(!currentState);
    };

    const showFirmarCartaIncrementoModal = async (idActivida) => {
        if (idActivida) {
            setIdActividadDetalle(idActivida);
        }

        let currentState = hideFirmarCartaIncrementoModal;
        setHideFirmarCartaIncrementonModal(!currentState);
    };


    const showCartaDeVacunacionModal = async (idActivida) => {
        if (idActivida) {
            setIdActividadDetalle(idActivida);
        }

        let currentState = hideCartaDeVacunacionModal;
        setHideCartaDeVacunacionModal(!currentState);
    };

    const showTestInduccionModal = async (IdEmpleadoFlujoActividad, nombre) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        if(nombre) setNombreUser(nombre)

        let currentState = hideTestInduccionModal;
        setHideTestInduccionModal(!currentState);
    };

    const showEncuestaInduccionModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        let currentState = hideEncuestaInduccionModal;
        setHideEncuestaInduccionModal(!currentState);
    };

    const showCorreoCorporativoModal = async (IdEmpleadoFlujoActividad, item) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        if(item) setDatoItem(item);

        let currentState = hideCorreoCorporativoModal;
        setHideCorreoCorporativoModal(!currentState);
    };

    const showInduccionVirtualModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        let currentState = hideInduccionVirtualModal;
        setHideInduccionVirtualModal(!currentState);
    };

    const showConfirmarAsistenciaModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        let currentState = hideConfirmarAsistenciaModal;
        setHideConfirmarAsistenciaModal(!currentState);
    };

    const showConfirmarAccesoDMModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        let currentState = hideConfirmarAccesoDMModal;
        setHideConfirmarAccesoDMModal(!currentState);
    };

    const showConfirmarAccesoJiraModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        let currentState = hideConfirmarAccesoJiraModal;
        setHideConfirmarAccesoJiraModal(!currentState);
    };

    const showConfirmarIngresoColaboradoNuevoModal = async (IdEmpleadoFlujoActividad, row) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        if(row) setDatoItem({nombreEmpleado: row.NombreEmpleado, Sede: row.Sede})

        setHideConfirmarIngresoColaboradorNuevoModal(!hideConfirmarIngresoColaboradorNuevoModal);
    };

    const exportarListado = async () => {
        const payload = getPayloadBuscar();
        await generarYDescargarArchivo(descargarListaActividadesPendientesAPI(), payload);
    }

    const showFirmaCartaIncrementoSede2024Modal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaCartaIncrementoSede2024Modal(!hideFirmaCartaIncrementoSede2024Modal);
    };

    const showFirmaConvenioMutuoDisensoModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaConvenioMutuoDisensoModal(!hideFirmaConvenioMutuoDisensoModal);
    };

    const showFirmaPoliticaBonoTotModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaPoliticaBonoTotModal(!hideFirmaPoliticaBonoTotModal);
    };

    const showFirmaDocumentoEMOModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaDocumentoEMOModal(!hideFirmaDocumentoEMOModal);
    };

    const showFirmaConvenioCambioPuestoModal = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaConvenioCambioPuestoModal(!hideFirmaConvenioCambioPuestoModal);
    };

    const showFirmaDocumentoResultadoEvaluacion2024 = async (IdEmpleado, IdEmpleadoFlujoActividad) => {
        if (IdEmpleado && IdEmpleadoFlujoActividad) {
            setIdEmpleado(IdEmpleado);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideFirmaDocumentoResultadoEvaluacion2024(!hideFirmaDocumentoResultadoEvaluacion2024);
    };

    const showSeleccionarHorarioLactanciaModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        setHideSeleccionarHorarioLactancia(!hideSeleccionarHorarioLactancia);
    };

    const showValidarHorarioLactanciaModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        setHideValidarHorarioLactancia(!hideValidarHorarioLactancia);
    };

    const showAprobarHorarioLactanciaModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        setHideAprobarHorarioLactancia(!hideAprobarHorarioLactancia);
    };

    const showFirmarRecepcionHorarioLactanciaModal = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        setHideFirmarRecepcionHorarioLactancia(!hideFirmarRecepcionHorarioLactancia);
    };

    const showFirmarDocumentoCanjeDMEssaludCITT = async (IdEmpleadoFlujoActividad) => {
        if (IdEmpleadoFlujoActividad) {
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }

        setHideFirmarDocumentoCanjeDMEssaludCITT(!hideFirmarDocumentoCanjeDMEssaludCITT);
    };

    const showEntregarDocumentoTramiteEssalud = async (IdEmpleadoFlujo, IdEmpleadoFlujoActividad) => {
        if(IdEmpleadoFlujo && IdEmpleadoFlujoActividad){
            setIdEmpleadoFlujo(IdEmpleadoFlujo);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideEntregarDocumentoTramiteEssalud(!hideEntregarDocumentoTramiteEssalud);
    }

    const showValidarDocumentoTramiteEssalud = async (IdEmpleadoFlujo, IdEmpleadoFlujoActividad) => {
        if(IdEmpleadoFlujo && IdEmpleadoFlujoActividad){
            setIdEmpleadoFlujo(IdEmpleadoFlujo);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideValidarDocumentoTramiteEssalud(!hideValidarDocumentoTramiteEssalud);
    }

    const showSubirDocumentoDMCITT = async (IdEmpleadoFlujo, IdEmpleadoFlujoActividad) => {
        if(IdEmpleadoFlujo && IdEmpleadoFlujoActividad){
            setIdEmpleadoFlujo(IdEmpleadoFlujo);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideSubirDocumentoDMCITT(!hideSubirDocumentoDMCITT);
    }

    const showValidarDocumentoDMCITT = async (IdEmpleadoFlujo, IdEmpleadoFlujoActividad) => {
        if(IdEmpleadoFlujo && IdEmpleadoFlujoActividad){
            setIdEmpleadoFlujo(IdEmpleadoFlujo);
            setIdActividadDetalle(IdEmpleadoFlujoActividad);
        }
        setHideValidarDocumentoDMCITT(!hideValidarDocumentoDMCITT);
    }
    
    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );
    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Esta página no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                <div className="modal-contactanos modal-response-ficha-background" hidden={hideDescargarOfisisModal}>
                    {hideDescargarOfisisModal === false &&
                    <DescargarOfisisModal showModal={showDescargarOfisisModal}/>
                    }
                </div>
                <div className="modal-contactanos modal-response-ficha-background" hidden={hideDetalleDePasosDeActividadesModal}>
                    {hideDetalleDePasosDeActividadesModal === false &&
                    <DetalleDePasosDeActividades showModal={showDetallePasosDeActividadesModal} idEmpleadoFlujoActividad={idActividadDetalle}
                                                 showDetalleRegistro={showDetalleRegistroModal}
                                                 showVerificarERPendienteModal={showVerificarERPendienteModal}
                                                 showGenerarLiquidacionModal={showGenerarLiquidacionModal}
                                                 showConfirmarPrestamoModal={showConfirmarPrestamoModal}
                                                 showCoordinarDevolucionModal={showCoordinarDevolucionModal}
                                                 showConfirmarEstadoRecepcionEquiposModal={showConfirmarEstadoRecepcionEquiposModal}
                                                 showActualizarLiquidacionModal={showAmpliarLiquidacionModal}
                    />
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideDetalleRegistroModal}>
                    {hideDetalleRegistroModal === false &&
                    <DetalleRegistroModal showModal={showDetalleRegistroModal} idActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>
                <div className="modal-contactanos modal-response-ficha-background" hidden={hideVerificarERPendienteModal}>
                    {hideVerificarERPendienteModal === false &&
                    <VerificarERPendienteModal showModal={showVerificarERPendienteModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideGenerarLiquidacionModal}>
                    {hideGenerarLiquidacionModal === false &&
                    <GenerarLiquidacionModal showModal={showGenerarLiquidacionModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideConfirmarPrestamoModal}>
                    {hideConfirmarPrestamoModal === false &&
                    <ConfirmarPrestamoModal showModal={showConfirmarPrestamoModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideCoordinarDevolucionModal}>
                    {hideCoordinarDevolucionModal === false &&
                    <CoordinarDevolucionModal showModal={showCoordinarDevolucionModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideConfirmarEstadoRecepcionEquipos}>
                    {hideConfirmarEstadoRecepcionEquipos === false &&
                    <ConfirmarEstadoRecepcionEquiposModal showModal={showConfirmarEstadoRecepcionEquiposModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideAmpliarLiquidacionModal}>
                    {hideAmpliarLiquidacionModal === false &&
                    <ActualizarLiquidacionModal showModal={showAmpliarLiquidacionModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                {hideReasignarActividadModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ReasignarActividadModal toggleModal={toggleReasignarActividadModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {hideObservarActividadModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ObservarActividadModal toggleModal={toggleObservarActividadModal} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {hideRegistrarVacacionesModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistrarVacacionesModal toggleModal={toggleRegistrarVacacionesModal} manageSearch={manageSearch}/>
                </div>
                }

                {hideFirmarCartaAutorizacionModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <FirmarCartaAutorizacionModal showModal={showFirmarCartaAutorizacionModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {hideFirmaCartaAprobadosLineaCarreraModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <FirmaCartaAprobadosLineaCarreraModal showModal={showFirmaCartaAprobadosLineaCarreraModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {hideFirmaDocumentoTeletrabajoModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaDocumentoTeletrabajoModal showModal={showFirmaDocumentoTeletrabajoModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaCompraVacacionesModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCompraVacacionesModal showModal={showFirmaCompraVacacionesModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaCartaCambioPuestoModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCartaCambioPuestoModal showModal={showFirmaCartaCambioPuestoModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaCartaIncremento2023Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCartaIncremento2023Modal showModal={showFirmaCartaIncremento2023Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaConvenioBonoTrasladoModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaConvenioBonoTrasladoModal showModal={showFirmaConvenioBonoTrasladoModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaCartaIncrementoBO2023Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCartaIncrementoBO2023Modal showModal={showFirmaCartaIncrementoBO2023Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaConvenioCambioFuncionesModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaConvenioCambioFuncionesModal showModal={showFirmaConvenioCambioFuncionesModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaCartaBonoModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <FirmaCartaBonoModal showModal={showFirmaCartaBonoModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {hideFirmaCartaObjetivoModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCartaObjetivoModal 
                            showModal={showFirmaCartaObjetivoModal} 
                            idEmpleado={idEmpleado} 
                            idEmpleadoFlujoActividad={idActividadDetalle} 
                            nombreActividad={nombreActividad}
                            manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaCartaObjetivo2023y2024Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCartaObjetivo2023y2024Modal showModal={showFirmaCartaObjetivo2023y2024Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaRecompensaTotalSede2024Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaRecompensaTotalSede2024Modal showModal={showFirmaRecompensaTotalSede2024Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaRecompensaTotalBO2024Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaRecompensaTotalBO2024Modal showModal={showFirmaRecompensaTotalBO2024Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaLineaCarrera2023Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaLineaCarrera2023Modal showModal={showFirmaLineaCarrera2023Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideValidarFirmaCartaAutorizacionModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ValidarCartaAutorizacionModal showModal={showValidarFirmaCartaAutorizacionModal} idEmpleadoFlujo={idEmpleadoFlujo} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {hideFirmaCartaIncrementoSede2024Modal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaCartaIncrementoSede2024Modal showModal={showFirmaCartaIncrementoSede2024Modal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaConvenioMutuoDisensoModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaConvenioMutuoDisensoModal showModal={showFirmaConvenioMutuoDisensoModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaPoliticaBonoTotModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaPoliticaBonoTotModal showModal={showFirmaPoliticaBonoTotModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaDocumentoEMOModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaDocumentoEMOModal showModal={showFirmaDocumentoEMOModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaConvenioCambioPuestoModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaConvenioCambioPuestoModal showModal={showFirmaConvenioCambioPuestoModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaDocumentoProrrogaModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmaDocumentoProrrogaModal showModal={showFirmaDocumentoProrrogaModal} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>
                }

                {hideFirmaDocumentoResultadoEvaluacion2024 == false &&
                    <div className='modal-contactanos modal-response-ficha-background'>
                        <FirmaDocumentoResultadoEvaluacion2024 showModal={showFirmaDocumentoResultadoEvaluacion2024} idEmpleado={idEmpleado} idEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} />
                    </div>

                }

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideFirmarCartaCompromisoVacunacionModal}>
                    {hideFirmarCartaCompromisoVacunacionModal === false &&
                    <FirmarCartaCompromisoVacunacionModal showModal={showFirmarCartaCompromisoVacunacionModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideFirmarCompromisoLecturaPoliticaModal}>
                    {hideFirmarCompromisoLecturaPoliticaModal === false &&
                    <FirmarCompromisoLecturaPoliticas showModal={showFirmarCompromisoLecturaPoliticaModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideFirmarCartaIncrementoModal}>
                    {hideFirmarCartaIncrementoModal === false &&
                    <FirmarCartaIncrementoModal showModal={showFirmarCartaIncrementoModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideCartaDeVacunacionModal}>
                    {hideCartaDeVacunacionModal === false &&
                    <CartaDeVacunacion showModal={showCartaDeVacunacionModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideValidarCartaCompromisoVacunacionModal}>
                    {hideValidarCartaCompromisoVacunacionModal === false &&
                    <ValidarCartaCompromisoVacunacionFirmadaModal showModal={showValidarCartaCompromisoVacunacionModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    }
                </div>

                {hideValidarPlantillaCartaCompromisoVacunacionModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ValidarPlantillaCartaCompromisoVacunacionModal showModal={showValidarPlantillaCartaCompromisoVacunacion} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                </div>
                }

                {state.isModalEnabled &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <state.component showModal={() => dispatch({ type: ACTIONS.IS_MODAL_ENABLED })} IdEmpleadoFlujoActividad={state.idEmpleadoFlujoActividad} manageSearch={manageSearch}/>
                    </div>
                }
                
                {hideTestInduccionModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <TestInduccionModal showModal={showTestInduccionModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} nombreUser={nombreUser}/>
                    </div>
                }

                {hideEncuestaInduccionModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <EncuestaInduccionModal showModal={showEncuestaInduccionModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    </div>
                }

                {hideCorreoCorporativoModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <CrearCorreoCorporativoModal showModal={showCorreoCorporativoModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch} datoItem={datoItem}/>
                    </div>
                }

                {hideInduccionVirtualModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <InduccionVirtualModal showModal={showInduccionVirtualModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    </div>
                }

                {hideConfirmarAsistenciaModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <ConfirmarAsistenciaModal showModal={showConfirmarAsistenciaModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    </div>
                }

                {hideConfirmarAccesoDMModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <ConfirmarAccesoDM showModal={showConfirmarAccesoDMModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    </div>
                }

                {hideConfirmarAccesoJiraModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <ConfirmarAccesoJira showModal={showConfirmarAccesoJiraModal} IdEmpleadoFlujoActividad={idActividadDetalle} manageSearch={manageSearch}/>
                    </div>
                }

                {hideConfirmarIngresoColaboradorNuevoModal === false && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <ConfirmarIngresoColaboradorNuevo showModal={showConfirmarIngresoColaboradoNuevoModal} IdEmpleadoFlujoActividad={idActividadDetalle} datoItem={datoItem} manageSearch={manageSearch}/>
                    </div>
                }

                {modalEnable.isEnable && 
                    <div className="modal-contactanos modal-response-ficha-background">
                        <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} manageSearch={manageSearch} {...modalEnable.data}/>
                    </div>
                }
                
                {hideSeleccionarHorarioLactancia === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <SeleccionarHorarioLactancia
                            showModal={showSeleccionarHorarioLactanciaModal} 
                            IdEmpleadoFlujoActividad={idActividadDetalle} 
                            manageSearch={manageSearch}/>
                    </div>
                }
                
                {hideValidarHorarioLactancia === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <ValidarHorarioLactancia
                            showModal={showValidarHorarioLactanciaModal} 
                            IdEmpleadoFlujoActividad={idActividadDetalle} 
                            manageSearch={manageSearch}/>
                    </div>
                }
                
                {hideAprobarHorarioLactancia === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <AprobarHorarioLactancia
                            showModal={showAprobarHorarioLactanciaModal} 
                            IdEmpleadoFlujoActividad={idActividadDetalle} 
                            manageSearch={manageSearch}/>
                    </div>
                }
                
                {hideFirmarRecepcionHorarioLactancia === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmarRecepcionHorarioLactancia
                            showModal={showFirmarRecepcionHorarioLactanciaModal} 
                            IdEmpleadoFlujoActividad={idActividadDetalle} 
                            manageSearch={manageSearch}/>
                    </div>
                }
                
                {hideFirmarDocumentoCanjeDMEssaludCITT === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <FirmarDocumentoCanjeDMEssaludCITT 
                            showModal={showFirmarDocumentoCanjeDMEssaludCITT} 
                            IdEmpleadoFlujoActividad={idActividadDetalle} 
                            manageSearch={manageSearch}/>
                    </div>
                }

                {hideEntregarDocumentoTramiteEssalud === false &&
                    <div className='modal-contactanos modal-response-ficha-background'>
                        <EntregarDocumentoTramiteEssalud
                            showModal={showEntregarDocumentoTramiteEssalud}
                            IdEmpleadoFlujo={idEmpleadoFlujo}
                            IdEmpleadoFlujoActividad={idActividadDetalle}
                            manageSearch={manageSearch}/>
                    </div>
                }

                {hideValidarDocumentoTramiteEssalud === false &&
                    <div className='modal-contactanos modal-response-ficha-background'>
                        <ValidarDocumentoTramiteEssalud
                            showModal={showValidarDocumentoTramiteEssalud}
                            IdEmpleadoFlujo={idEmpleadoFlujo}
                            IdEmpleadoFlujoActividad={idActividadDetalle}
                            manageSearch={manageSearch}/>
                    </div>
                }

                {hideSubirDocumentoDMCITT === false &&
                    <div className='modal-contactanos modal-response-ficha-background'>
                        <SubirDocumentoDMCITT
                            showModal={showSubirDocumentoDMCITT}
                            IdEmpleadoFlujo={idEmpleadoFlujo}
                            IdEmpleadoFlujoActividad={idActividadDetalle}
                            manageSearch={manageSearch}/>
                    </div>
                }

                {hideValidarDocumentoDMCITT === false &&
                    <div className='modal-contactanos modal-response-ficha-background'>
                        <ValidarDocumentoDMCITT
                            showModal={showValidarDocumentoDMCITT}
                            IdEmpleadoFlujo={idEmpleadoFlujo}
                            IdEmpleadoFlujoActividad={idActividadDetalle}
                            manageSearch={manageSearch}/>
                    </div>
                }

                <Wrapper className='App'>
                    <PageHeader title={"Mis Actividades Pendientes"}/>

                    {showAprobar &&
                    <div className="generic-buscador-container">
                        <div className="row">
                            <div className="col">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                                        Acciones
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={aprobarActividades}>Aprobar</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                    </div>
                    }
                    <div  className='pt-2'>
                        <div className="table-scroll">
                            <table className='table table-hover'>
                                <thead className="listar-thead">
                                <tr>
                                    {showAprobar && <th></th>}
                                    <th>
                                        <div className='flex font-gothan-medium'>Fecha de Registro</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Empleado</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Est. Empl.</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Fecha de Ingreso Real</div>
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={sedesFiltradosOptions}
                                            description={'Sede'}
                                            setSelectedOptions={setSedesSeleccionados}
                                            selectedOptions={sedesSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Flujo</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Cargo</div>
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={pasosFiltradosOptions}
                                            description={'Paso'}
                                            setSelectedOptions={setPasosSeleccionados}
                                            selectedOptions={pasosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <FormSelect  options={estadosFiltradosOptions}
                                           description={'Estado'}
                                           setSelectedOptions={setEstadosSeleccionados}
                                           selectedOptions={estadosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Mensaje</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Acciones</div>
                                    </th>
                                </tr>
                                </thead>
                                <TableWebBody actividadesFiltradas={actividadesFiltradas} showDetalleRegistro={showDetalleRegistroModal} idsEmpleadoFlujoActividadConCheck={idsEmpleadoFlujoActividadConCheck}
                                              showDetallePasosDeActividadesModal={showDetallePasosDeActividadesModal}
                                              showVerificarERPendienteModal={showVerificarERPendienteModal}
                                              showGenerarLiquidacionModal={showGenerarLiquidacionModal}
                                              setIdsEmpleadoFlujoActividadConCheck={setIdsEmpleadoFlujoActividadConCheck}
                                              showAprobar={showAprobar}
                                              showConfirmarPrestamoModal={showConfirmarPrestamoModal}
                                              showCoordinarDevolucionModal={showCoordinarDevolucionModal}
                                              showConfirmarEstadoRecepcionEquiposModal={showConfirmarEstadoRecepcionEquiposModal}
                                              showActualizarLiquidacionModal={showAmpliarLiquidacionModal}
                                              manageSearch={manageSearch}
                                              toggleObservarActividadModal={toggleObservarActividadModal}
                                              toggleReasignarActividadModal={toggleReasignarActividadModal}
                                              showFirmarCartaAutorizacionModal={showFirmarCartaAutorizacionModal}
                                              showValidarCartaAutorizacionModal={showValidarFirmaCartaAutorizacionModal}
                                              showFirmarCartaCompromisoVacunacion={showFirmarCartaCompromisoVacunacionModal}
                                              showFirmarCompromisoLecturaPoliticaModal={showFirmarCompromisoLecturaPoliticaModal}
                                              showValidarCartaCompromisoVacunacion={showValidarCartaCompromisoVacunacionModal}
                                              showValidarPlantillaCartaCompromisoVacunacion={showValidarPlantillaCartaCompromisoVacunacion}
                                              showFirmarCartaIncrementoModal={showFirmarCartaIncrementoModal}
                                              showCartaDeVacunacionModal={showCartaDeVacunacionModal}
                                              showFirmaCartaAprobadosLineaCarreraModal={showFirmaCartaAprobadosLineaCarreraModal}
                                              showFirmaCartaBonoModal={showFirmaCartaBonoModal}
                                              showFirmaDocumentoTeletrabajoModal={showFirmaDocumentoTeletrabajoModal}
                                              showFirmaCompraVacacionesModal={showFirmaCompraVacacionesModal}
                                              showFirmaCartaCambioPuestoModal={showFirmaCartaCambioPuestoModal}
                                              showFirmaCartaIncremento2023Modal={showFirmaCartaIncremento2023Modal} 
                                              showFirmaConvenioBonoTrasladoModal={showFirmaConvenioBonoTrasladoModal}
                                              showFirmaCartaIncrementoBO2023Modal={showFirmaCartaIncrementoBO2023Modal}
                                              dispatch={dispatch}
                                              setModalEnable={setModalEnable}
                                              showTestInduccionModal={showTestInduccionModal}
                                              showEncuestaInduccionModal={showEncuestaInduccionModal}
                                              showCorreoCorporativoModal={showCorreoCorporativoModal}
                                              showInduccionVirtualModal={showInduccionVirtualModal}
                                              showConfirmarAsistenciaModal={showConfirmarAsistenciaModal}
                                              showConfirmarAccesoDMModal={showConfirmarAccesoDMModal}
                                              showConfirmarAccesoJiraModal={showConfirmarAccesoJiraModal}
                                              showConfirmarIngresoColaboradoNuevoModal={showConfirmarIngresoColaboradoNuevoModal}
                                              showFirmaConvenioCambioFuncionesModal={showFirmaConvenioCambioFuncionesModal}
                                              showFirmaCartaObjetivoModal={showFirmaCartaObjetivoModal}
                                              showFirmaCartaObjetivo2023y2024Modal={showFirmaCartaObjetivo2023y2024Modal}
                                              showFirmaLineaCarrera2023Modal={showFirmaLineaCarrera2023Modal}
                                              showFirmaCartaIncrementoSede2024Modal={showFirmaCartaIncrementoSede2024Modal}
                                              showFirmaConvenioMutuoDisensoModal={showFirmaConvenioMutuoDisensoModal}
                                              showFirmaPoliticaBonoTotModal={showFirmaPoliticaBonoTotModal}
                                              showFirmaDocumentoEMOModal={showFirmaDocumentoEMOModal}
                                              showFirmaConvenioCambioPuestoModal={showFirmaConvenioCambioPuestoModal}
                                              showFirmaRecompensaTotalSede2024Modal={showFirmaRecompensaTotalSede2024Modal}
                                              showFirmaRecompensaTotalBO2024Modal={showFirmaRecompensaTotalBO2024Modal}
                                              showFirmaDocumentoProrrogaModal={showFirmaDocumentoProrrogaModal}
                                              showFirmaDocumentoResultadoEvaluacion2024 =
                                              {showFirmaDocumentoResultadoEvaluacion2024}
                                              showSeleccionarHorarioLactanciaModal = {showSeleccionarHorarioLactanciaModal}
                                              showValidarHorarioLactanciaModal = {showValidarHorarioLactanciaModal}
                                              showAprobarHorarioLactanciaModal = {showAprobarHorarioLactanciaModal}
                                              showFirmarRecepcionHorarioLactanciaModal = {showFirmarRecepcionHorarioLactanciaModal}
                                              showFirmarDocumentoCanjeDMEssaludCITT={showFirmarDocumentoCanjeDMEssaludCITT}
                                              showEntregarDocumentoTramiteEssalud={showEntregarDocumentoTramiteEssalud}
                                              showValidarDocumentoTramiteEssalud={showValidarDocumentoTramiteEssalud}
                                              showSubirDocumentoDMCITT={showSubirDocumentoDMCITT}
                                              showValidarDocumentoDMCITT={showValidarDocumentoDMCITT}
                                />
                            </table>
                        </div>
                            {totalPages > 1 && (
                                <Paginator totalItems={totalItems}
                                           firsItemPage={firsItemPage}
                                           lastItemPage={lastItemPage}
                                           totalPages={totalPages}
                                           page={page}
                                           onLeftClic={onLeftClic}
                                           onRightClic={onRightClic}/>
                            )}

                    </div>
                </Wrapper>
            </div>
        </>
    );
};

export default MisActividadesPendientes;
