import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap';
import PageHeader from '../../../components/pageHeader/PageHeader';
import FormSelect from '../../../components/atoms/Select';
import PaginatorV2 from '../../../components/pageComponents/PaginatorV2';
import PageWrapper from '../../../components/pageComponents/PageWrapper';
import PageButtons from '../../../components/pageComponents/PageButtons';
import TableWebBody from './TableWebBody';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../../components/pageComponents/SearchContainer';
import ImportFileGenericModal from '../../../components/modal/ImportFileGenericModal';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useIndexValues } from '../../../hooks/useIndexValues/useIndexValues';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils';
import { calificarConvocatoriaTrasladoEmpleadoAPI, calificarConvocatoriasTrasladoEmpleadoAPI, cargarSuspensionesTrasladosAPI, downloadPlantillaSuspensionesTrasladosAPI, generarActividadPendienteDeAmonestacionesAPI, getAllAreasAPI, getAllDepartamentosAPI, getAllEstadosAPI, getAllSedesAPI, getDivisionesAPI, getGradoDesarrolloAPI, postulantesTrasladoIndexAPI } from '../../../consumers/backendApisUrls';

const GestionPostulantes = () => {

  const { id } = useParams();
  const EXTENSIONES_VALIDAS = ["xls","xlsx"];
  const ID_IMPORTAR = "importar-traslados";
  const KEYS_FILTERS = {Sedes: "IdsSedes", Unidades: "IdsUnidades", Gerencias: "IdsDepartamentos", Areas: "IdsAreas", GradosDesarrollo: "IdsGradoDesarrollo", SedesPrimeraOpcion: "IdsSedesPrimeraOpcion", SedesSegundaOpcion: "IdsSedesSegundaOpcion", SedeSeleccionada: "IdsSedeSeleccionada", TieneAmonestaciones: "IdsTieneAmonestaciones", TieneSuspensiones: "IdsTieneSuspensiones"};

  const [formValues, setFormValues] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [allFilters, setAllFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  
  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);
  const [isOrdenEsDescendente, setIsOrdenEsDescendente] = useState(true);

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [searchIndex] = useIndexValues(setFormValues, setPaginator, setExtraData);
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const allFiltersPromises = () => {
    return [
      executeGet(getAllSedesAPI()),
      executeGet(getDivisionesAPI()),
      executeGet(getAllDepartamentosAPI()),
      executeGet(getAllAreasAPI()),
      executeGet(getGradoDesarrolloAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(allFiltersPromises, Object.values(KEYS_FILTERS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally{
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = postulantesTrasladoIndexAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, searchIndex);
  }

  const getPayload = () => {
    return {
      IdConvocatoriaTraslado: id,
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      OrdenEsDescendente: isOrdenEsDescendente,
      ...selectedFilters
    };
  }

  const handleSelectMulticheck = async (api) => {
    if (rowsSelected.length > 0){
      for(let rowId of rowsSelected){
        await executeManagePost(api(rowId), {}, () => {});
      }
      manageSearch();
    } else {
      toast.error("Debe seleccionar un postulante");
    }
  }

  const handleCalificar = async () => {
    if (rowsSelected.length > 0){
      const payload = {idConvocatoriasTrasladoEmpleado: rowsSelected}
      await executeManagePost(calificarConvocatoriasTrasladoEmpleadoAPI(), payload, () => manageSearch());
    } else {
      toast.error("Debe seleccionar un postulante");
    }
  }

  const handleDownloadTemplate = async () => {
    const api = downloadPlantillaSuspensionesTrasladosAPI(id);
    await downloadAndDelete(api, {});
  }

  const handleFilterSelected = (res, key) => {
    setSelectedFilters(lastData => ({...lastData, [key]: res }));
  }

  useDidMountEffect(() => {
    if(jsonNoEstaVacio(selectedFilters)) manageSearch();
  }, [selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allFilters, extraData, setFilters);
  }, [extraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Gestión de Postulantes"/>
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={() => setSelectedFilters({})}>
       <PageButtons hasBackButton>
        <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Acciones</span>}>
          <NavDropdown.Item onClick={() => handleCalificar()}>Calificar Postulanes</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleSelectMulticheck(generarActividadPendienteDeAmonestacionesAPI)}>Generar Act.Pend Amonestaciones</NavDropdown.Item>
          <NavDropdown.Item onClick={handleDownloadTemplate}>Descargar plantilla suspensiones</NavDropdown.Item>
          <NavDropdown.Item onClick={() => setModalEnabled({isEnable: true, component: ImportFileGenericModal, data:{api: cargarSuspensionesTrasladosAPI(), id: ID_IMPORTAR, validExtensions: EXTENSIONES_VALIDAS}})}>Cargar plantilla suspensiones</NavDropdown.Item>
        </NavDropdown>
       </PageButtons>
      </SearchContainer>
      <div className="container-fluid body-content">
      <div className="table-scroll pt-3">
        <table className="table table-hover">
          <thead className="listar-thead">
            <tr className="font-gothan-medium">
              <th rowSpan="2" colSpan="1" className="text-center">Selc.</th>
              <th rowSpan="2" colSpan="1" className="text-center">Fecha Postulación</th>
              <th rowSpan="2" colSpan="1" className="text-center">Nombre Completo</th>
              <th rowSpan="2" colSpan="1" className='col-sm-1'>
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.Sedes)}
                    description={<label className='text-wrap mb-0'>Sede</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Sedes)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.Sedes] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className='col-sm-1'>
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.Unidades)}
                    description={<label className='text-wrap mb-0'>Unidad</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Unidades)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.Unidades] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className='col-sm-2'>
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.Gerencias)}
                    description={<label className='text-wrap mb-0'>Gerencia</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Gerencias)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.Gerencias] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className='col-sm-2'>
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.Areas)}
                    description={<label className='text-wrap mb-0'>Area</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Areas)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.Areas] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className="text-center">
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.GradosDesarrollo)}
                    description={<label className='text-wrap mb-0'>Grado de Desarrollo</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.GradosDesarrollo)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.GradosDesarrollo] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className="text-center">Fecha Postulación</th>
              <th rowSpan="2" colSpan="1" className="text-center col-sm-1">Antiguedad Innova</th>
              <th rowSpan="2" colSpan="1" className="text-center">
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.SedesPrimeraOpcion)}
                    description={<label className='text-wrap mb-0'>1ra Opción</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.SedesPrimeraOpcion)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.SedesPrimeraOpcion] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className="text-center">
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.SedesSegundaOpcion)}
                    description={<label className='text-wrap mb-0'>2da Opción</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.SedesSegundaOpcion)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.SedesSegundaOpcion] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className="text-center">
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.SedeSeleccionada)}
                    description={<label className='text-wrap mb-0'>Sede Seleccionada</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.SedeSeleccionada)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.SedeSeleccionada] ?? []}
                  />
                </DCenter>
              </th>
              <th rowSpan="2" colSpan="1" className="text-center col-sm-1">Estado</th>
              <th rowSpan="1" colSpan="4" className="text-center">Info Postulación</th>
              <th rowSpan="2" colSpan="1" className="text-center">Acciones</th>
            </tr>
            <tr className="">
              <th className="text-center font-gothan-medium">Ver Ficha</th>
              <th className="text-center font-gothan-medium">Nota</th>
              <th className="text-center">
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.TieneAmonestaciones)}
                    description={<label className='text-wrap mb-0'>Tiene amo.</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.TieneAmonestaciones)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.TieneAmonestaciones] ?? []}
                  />
                </DCenter>
              </th>
              <th className="text-center">
                <DCenter>
                  <FormSelect
                    options={handleFind(filters, KEYS_FILTERS.TieneSuspensiones)}
                    description={<label className='text-wrap mb-0'>Tiene susp.</label>}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.TieneSuspensiones)}
                    selectedOptions={selectedFilters[KEYS_FILTERS.TieneSuspensiones] ?? []}
                  />
                </DCenter>
              </th>
            </tr>
          </thead>
          <TableWebBody filas={formValues} setModalEnabled={setModalEnabled} setRowsSelected={setRowsSelected}/>
        </table>
        <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
      </div>
    </div>
  </PageWrapper>
  )
}

export default GestionPostulantes;

const DCenter = ({ children }) => (
  <div className='d-flex justify-content-center'>
    {children}
  </div>
)